import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Card, Table, Spinner, Dropdown, Button } from "react-bootstrap";
import { getReq, getTable, postReq } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import DownloadExcel from '../../parts/DownloadExcel';
import MoreDetails from '../../parts/MoreDetails';
import Frame from "../../components/frame";
export default function PackageList() {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const [searchBy, setSearchBy] = useState('');
    const [searchByText, setSearchByText] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [table, setTable] = useState('');
    const [refrashPage, setRefrashPage] = useState(false);
    const [packageListData, setPackageListData] = useState('');
    const [expendTb, setExpendTb] = useState(null);
    const { setCurrentSidebarClick, setCollapseId, hideBinary } = useContext(SidebarClick);
    const [loader, setLoader] = useState('');
    const [price, setPrice] = useState('');
    const [is_roi, setIs_roi] = useState(false);
    const [is_unilevel, setIs_unilevel] = useState(false);
    const [is_binary, setIs_binary] = useState(false);
    const [is_referral, setIs_referral] = useState(false);
    const [id, setId] = useState(null);
    const [packageName,setPackageName] = useState('');
    const [updateLoader, setUpdateLoader] = useState(false);
    const [binaryLoader, setBinaryLoader] = useState(false);
    const [roiLoader, setRoiLoader] = useState(false);
    const [referralLoader, setReferralLoader] = useState(false);
    const [blocking, setBlocking] = useState(false);
    const [updateData, setUpdateData] = useState({
        "name": "",
        "type": "",
        "price": 0,
        "minimum_price": 0,
        "maximum_price": 0,
        "duration": 0,
        "description": "",
        "non_working_percentage":"",
        "is_roi": 0,
        "is_unilevel": 0,
        "is_binary": 0,
        "is_referral": 0,
        "calculate_binary_field": "",
        "binary_volume": 0,
        "roi_percentage": 0,
        "roi_days": 0,
        "is_roi_unilevel": 0,
        "is_binary_unilevel": 0,
        "is_referral_unilevel": 0,
        "roi_level": 0,
        "referral_level": 0,
        //"unilevel_level": 0,
        "binary_level": 0,
        "binary_type": "",
        "binary_percentage": 0,
        "status": 0,
        "is_cancellation":0,
        // "non_working_percentage":0,
        // "total_income_percentage":0,
    });
    const [showUpdateModel, setShowUpdateModel] = useState(false);
    const [moreDetails, setMoreDetails] = useState(null);
    let countTable = 0;
    const [filterShow, setFilterShow] = useState(false);
    const list = [
        { 'label': 'Type', 'data': 'type', 'type': 'text' },
        { 'label': 'Minimum Price', 'data': 'minimum_price', 'type': 'number' },
        { 'label': 'Maximum Price', 'data': 'maximum_price', 'type': 'number' },
        { 'label': 'Duration', 'data': 'duration', 'type': 'number' },
        { 'label': 'Status', 'data': 'status', 'type': 'option' }
    ]
    const [modalLoader, setModalLoader] = useState(false);
    const [binaryPackageData, setBinaryPackageData] = useState('');
    const [referralPackageData, setReferralPackageData] = useState('');
    const [roiPackageData, setRoiPackageData] = useState('');
    const [sendBinaryData, setSendBinaryData] = useState({ 'type': 'binary_unilevel' });
    const [sendReferrallevelData, setSendReferrallevelData] = useState({ 'type': 'referral_unilevel' });
    const [sendRoilevelData, setSendRoilevelData] = useState({ 'type': 'roi_unilevel' });
    const [levelLoader, setLevelLoader] = useState(false);
    const [showRoilevellModel, setShowRoilevellModel] = useState(false);
    const [showBinaryModel, setShowBinaryModel] = useState(false);
    const [showReferrallevellModel, setShowReferrallevellModel] = useState(false);
    const [exportData,setExportData] = useState('');
    let durationanim = 0.15;
    let AmountSymbol = '$';
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/packageList');
        setCollapseId(9);
        (async () => {
            try {
                //setSpinner(true);
                let r = await getReq('package/index?export=true', sessionStorage.getItem('token'));
                if (r.status === "success" || r.status === true) {
                    setPackageListData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setMoreDetails(r.more_details);
                    //setSpinner(false);
                }
                if (r.errors) {
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                //if(err.response.status && err.response.status===401||err.response.status===404){
                if (err.message) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                //setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    }, [refrashPage]);
    async function searchTable(e) {
        try {
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/package/index?export=true&page=${e.target.value}`, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                setPackageListData(r.data);
                setExportData(r.export_data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if (r.errors) {
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function nextTable(url) {
        try {
            if (url !== null) {
                setSpinner(true);
                let r = await getTable(url, sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setPackageListData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    // async function searchByFun(e){
    //     try{
    //         setSearchByText(e.target.value);
    //         setSpinner(true);
    //         let r = await getTable(`${process.env.REACT_APP_BASE_URL}/package/index?${searchBy}=${e.target.value}`,sessionStorage.getItem('token'))
    //         if(r.status==="success" || r.status===true){
    //             setPackageListData(r.data);
    //             setTable(r.data.data);
    //             setSpinner(false);
    //         }
    //         if(r.errors){
    //             setSpinner(false);
    //             let err = Object.values(r.errors);
    //             //err.map((data)=>notifyError(data[0]));
    //             notifyError(err[0][0]);
    //         }
    //     }
    //     catch(err){
    //         setSpinner(false);
    //         notifyError("Request failed please login again");
    //     }
    // }
    function changeRoi(state) {
        let temp = { ...updateData };
        if (state === false) {
            temp.is_roi = 0;
            temp.roi_percentage = 0;
            temp.roi_days = 0;
            temp.roi_level = 0;
        }
        else {
            temp.is_roi = 1;
        }
        setUpdateData(temp);
    }
    function changeUnilevel(state) {
        let temp = { ...updateData };
        if (state === false) {
            temp.is_unilevel = 0;
            //temp.unilevel_level = 0;
            temp.is_roi_unilevel = 0
            temp.is_binary_unilevel = 0;
            temp.is_referral_unilevel = 0;
        }
        else {
            temp.is_unilevel = 1;
        }
        setUpdateData(temp);
    }
    function changeBinary(state) {
        let temp = { ...updateData };
        if (state === false) {
            temp.is_binary = 0;
            temp.binary_type = "";
            setUpdateData(temp);
        }
        else {
            temp.is_binary = 1;
        }
        setUpdateData(temp);
    }
    function changeReferral(state) {
        let temp = { ...updateData };
        if (state === false) {
            temp.referral_level = 0;
        }
        setUpdateData(temp);
    }
    async function clickUpdate(id) {
        setUpdateLoader(true);
        let r = await getTable(`${process.env.REACT_APP_BASE_URL}/package/index?id=${id}`, sessionStorage.getItem('token'));
        let temp = r.data.data[0];
        delete temp.id;
        delete temp.code;
        delete temp.deleted_at;
        delete temp.created_at;
        delete temp.updated_at;
        setUpdateData(temp);
        setUpdateLoader(false);
        setShowUpdateModel(true);
    }
    async function updatePackageData() {
        setLoader(true);
        let r = await postReq(`package/${id}/update`, updateData, sessionStorage.getItem('token'));
        if (r.status === "success" || r.status === true) {
            setLoader(false);
            notifySuccess(r.message || r.msg);
            setRefrashPage(true);
            setShowUpdateModel(false);
            setUpdateData({
                "name": "",
                "type": "",
                "price": 0,
                "minimum_price": 0,
                "maximum_price": 0,
                "duration": 0,
                "description": "",
                "non_working_percentage":"",
                "is_roi": 0,
                "is_unilevel": 0,
                "is_binary": 0,
                "is_referral": 0,
                "calculate_binary_field": "",
                "binary_volume": 0,
                "roi_percentage": 0,
                "roi_days": 0,
                "is_roi_unilevel": 0,
                "is_binary_unilevel": 0,
                "is_referral_unilevel": 0,
                "roi_level": 0,
                "referral_level": 0,
                //"unilevel_level": 0,
                "binary_level": 0,
                "binary_type": "",
                "binary_percentage": 0,
                "status": 0,
                "is_cancellation":0,
                // "non_working_percentage":0,
                // "total_income_percentage":0,
            });
        }
        if (r.errors) {
            setLoader(false);
            let err = Object.values(r.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
    }
    function closeModel() {
        setShowUpdateModel(false);
        setUpdateData({
            "name": "",
            "type": "",
            "price": 0,
            "minimum_price": 0,
            "maximum_price": 0,
            "duration": 0,
            "description": "",
            "non_working_percentage":"",
            "is_roi": 0,
            "is_unilevel": 0,
            "is_binary": 0,
            "is_referral": 0,
            "calculate_binary_field": "",
            "binary_volume": 0,
            "roi_percentage": 0,
            "roi_days": 0,
            "is_roi_unilevel": 0,
            "is_binary_unilevel": 0,
            "is_referral_unilevel": 0,
            "roi_level": 0,
            "referral_level": 0,
            //"unilevel_level": 0,
            "binary_level": 0,
            "binary_type": "",
            "binary_percentage": 0,
            "status": 0,
            "is_cancellation":0,
            // "non_working_percentage":0,
            // "total_income_percentage":0,
        });
    }

    async function getBPack(id) {
        setBlocking(true);
        setBinaryLoader(true);
        let r = await getReq(`package/index?id=${id}`, sessionStorage.getItem('token'));
        let binaryLevels = await getReq(`unilevel/${id}/binary/setting`, sessionStorage.getItem('token'));
        if ((r.status === "success" || r.status === true) && binaryLevels.status === true) {
            setBinaryPackageData(r.data.data[0].binary_level);
            let temp = { ...sendBinaryData };
            for (let i = 1; i <= r.data.data[0].binary_level; i++) {
                temp[`level${i}`] = binaryLevels.data[i - 1]?.level || i;
            }
            for (let i = 1; i <= r.data.data[0].binary_level; i++) {
                temp[`percentage${i}`] = binaryLevels.data[i - 1]?.percentage || 0;
            }
            for (let i = 1; i <= r.data.data[0].binary_level; i++) {
                temp[`direct${i}`] = binaryLevels.data[i - 1]?.direct || 0;
            }
            setSendBinaryData(temp);
            setShowBinaryModel(true);
        }
        if (r.errors) {
            //setSpinner(false);
            let err = Object.values(r.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        setBinaryLoader(false);
        setBlocking(false);
    }
    async function getRPack(id) {
        //setReferrallevelClickId(id);
        setBlocking(true);
        setReferralLoader(true);
        let r = await getReq(`package/index?id=${id}`, sessionStorage.getItem('token'));
        let referalLevels = await getReq(`unilevel/${id}/referral/setting`, sessionStorage.getItem('token'));
        if (r.status === "success" || r.status === true) {
            setReferralPackageData(r.data.data[0].referral_level);
            let temp = { ...sendReferrallevelData };
            for (let i = 1; i <= r.data.data[0].referral_level; i++) {
                temp[`level${i}`] = referalLevels.data[i - 1]?.level || i;
            }
            for (let i = 1; i <= r.data.data[0].referral_level; i++) {
                temp[`percentage${i}`] = referalLevels.data[i - 1]?.percentage || 0;
            }
            for (let i = 1; i <= r.data.data[0].referral_level; i++) {
                temp[`direct${i}`] = referalLevels.data[i - 1]?.direct || 0;
            }
            for (let i = 1; i <= r.data.data[0].referral_level; i++) {
                temp[`business_amount${i}`] = referalLevels.data[i - 1]?.business_amount || 0;
            }
            setSendReferrallevelData(temp);
            setShowReferrallevellModel(true);
            //setReferrallevelClickId(null);
        }
        if (r.errors) {
            //setSpinner(false);
            let err = Object.values(r.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        setReferralLoader(false);
        setBlocking(false);
    }
    async function getRoiPack(id) {
        //setUnilevelClickId(id);
        setBlocking(true);
        setRoiLoader(true);
        let r = await getReq(`package/index?id=${id}`, sessionStorage.getItem('token'));
        let roiLevels = await getReq(`unilevel/${id}/roi/setting`, sessionStorage.getItem('token'));
        if (r.status === "success" || r.status === true) {
            setRoiPackageData(r.data.data[0].roi_level);
            let temp = { ...sendRoilevelData };
            for (let i = 1; i <= r.data.data[0].roi_level; i++) {
                temp[`level${i}`] = roiLevels.data[i - 1]?.level || i;
            }
            for (let i = 1; i <= r.data.data[0].roi_level; i++) {
                temp[`percentage${i}`] = roiLevels.data[i - 1]?.percentage || 0;
            }
            for (let i = 1; i <= r.data.data[0].roi_level; i++) {
                temp[`direct${i}`] = roiLevels.data[i - 1]?.direct || 0;
            }
            for (let i = 1; i <= r.data.data[0].roi_level; i++) {
                temp[`business_amount${i}`] = roiLevels.data[i - 1]?.business_amount || 0;
            }
            setSendRoilevelData(temp);
            setShowRoilevellModel(true);
            //setRoilevelClickId(null);
        }
        if (r.errors) {
            //setSpinner(false);
            let err = Object.values(r.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        setRoiLoader(false);
        setBlocking(false);
    }

    function setBinaryData(type, data) {
        let temp = { ...sendBinaryData };
        temp[type] = data;
        setSendBinaryData(temp);
    }
    function setReferrallevelData(type, data) {
        let temp = { ...sendReferrallevelData };
        temp[type] = data;
        setSendReferrallevelData(temp);
    }
    function setRoilevelData(type, data) {
        let temp = { ...sendRoilevelData };
        temp[type] = data;
        setSendRoilevelData(temp);
    }

    async function SubmitBinary() {
        setModalLoader(true);
        try {
            let r = await postReq(`unilevel/${id}/setting`, sendBinaryData, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                notifySuccess(r.message || r.msg)
                setModalLoader(false);
                setShowBinaryModel(false);
                setRefrashPage(true);
            }
            if (r.errors) {
                setModalLoader(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch (err) {
            setModalLoader(false);
            notifyError("Request failed please login again");
        }
        setModalLoader(false);
    }
    async function SubmitRefferrallevel() {
        setModalLoader(true);
        try {
            let r = await postReq(`unilevel/${id}/setting`, sendReferrallevelData, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                notifySuccess(r.message || r.msg)
                setModalLoader(false);
                setShowReferrallevellModel(false);
                setRefrashPage(true);
            }
            if (r.errors) {
                setModalLoader(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch (err) {
            setModalLoader(false);
            notifyError("Request failed please login again");
        }
        setModalLoader(false);
    }
    async function SubmitRoilevel() {
        setModalLoader(true);
        try {
            let r = await postReq(`unilevel/${id}/setting`, sendRoilevelData, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                notifySuccess(r.message || r.msg);
                setModalLoader(false);
                setShowRoilevellModel(false);
                setRefrashPage(true);
            }
            if (r.errors) {
                setModalLoader(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch (err) {
            setModalLoader(false);
            notifyError("Request failed please login again");
        }
        setModalLoader(false);
    }

    function filterFunData(r) {
        setPackageListData(r.data);
        setExportData(r.export_data);
        setTable(r.data.data);
    }

    const numberInputOnWheelPreventChange = (e) => {
        // Prevent the input value change
        e.target.blur()
    
        // Prevent the page/container scrolling
        e.stopPropagation()
    
        // Refocus immediately, on the next tick (after the current function is done)
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }

    const numberInputOnWheelPreventChangeKey = (e) => {
        e.preventDefault();
    }

    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                    Package List
                    </h1>
                </div>
                <div className="ms-auto">
                    {table && <DownloadExcel props={{ exportData: exportData, name: 'packagelist.xlsx' }} />}
                </div>
            </div>
            {moreDetails && <MoreDetails props={moreDetails} />}
            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}
            {table&&<div className="card">
                <div className="card-header align-items-center fw-bold small d-flex">
                    <span className="flex-grow-1">Package List</span>
                    {/* <span className="btn btn-outline-light btn-sm" onClick={() => setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span> */}
                </div>
                <div className="card-body">
                <div className="mb-4">
                    <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                </div>
                {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                    <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                </div>}
                <Table responsive style={{ textAlign: 'center' }} className='' ref={tableRef}>
                    <thead>
                        <tr>
                            <th>
                                <strong>#</strong>
                            </th>
                            <th>
                                <strong>Name</strong>
                            </th>
                            {/* <th>
                                <strong>Type</strong>
                            </th> */}
                            <th>
                                <strong>Price</strong>
                            </th>
                            {/* <th>
                                <strong>Minimum Price</strong>
                            </th>
                            <th>
                                <strong>Maximum Price</strong>
                            </th> */}
                            <th>
                                <strong>Duration (Month)</strong>
                            </th>
                            <th>
                                <strong>Status</strong>
                            </th>
                            <th>
                                <strong>Roi</strong>
                            </th>
                            {hideBinary == 1 &&<th>
                                <strong>Binary</strong>
                            </th>}
                            {/* <th>
                                <strong>Unilevel</strong>
                            </th> */}
                            <th>
                                <strong>Referral</strong>
                            </th>
                            <th>
                                <strong>Action</strong>
                            </th>
                        </tr>
                    </thead>
                    {table && <tbody>
                        {
                            table.map((data, idx) => {
                                countTable += 1;
                                return (
                                    <>
                                        <motion.tr initial={{ opacity: 0, x: -150 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: durationanim += 0.06 }} key={idx}>
                                            <td onClick={() => expendTb === data.id ? setExpendTb(null) : setExpendTb(data.id)} style={{ cursor: 'pointer', color: 'var(--primary)' }}>{expendTb==data.id?<strong>&#8722;</strong>:<strong>&#x271B;</strong>}</td>
                                            <td>{data.name}</td>
                                            <td>{data.type=='fixed'?<>{process.env.REACT_APP_Amount_Symbol||'$'} {parseFloat(data.price)}</>:<>{process.env.REACT_APP_Amount_Symbol||'$'} {parseFloat(data.minimum_price)} - {process.env.REACT_APP_Amount_Symbol||'$'} {parseFloat(data.maximum_price)}</>}</td>
                                            {/* <td>{process.env.REACT_APP_Amount_Symbol||'$'} {data.price}</td>
                                            <td>{process.env.REACT_APP_Amount_Symbol||'$'} {data.minimum_price}</td>
                                            <td>{process.env.REACT_APP_Amount_Symbol||'$'} {data.maximum_price}</td> */}
                                            <td>{data.duration}</td>
                                            <td>
                                                {data.status === 0 && <span className="badge light btn btn-danger">Inactive</span>}
                                                {data.status === 1 && <span className="badge light btn btn-success">Active</span>}
                                            </td>
                                            <td>
                                                {data.is_roi === 0 && <span className='text-danger'>Inactive</span>}
                                                {data.is_roi === 1 && <span className='text-green'>Active</span>}
                                            </td>
                                            {hideBinary == 1 &&<td>
                                                {data.is_binary === 0 && <span className='text-danger'>Inactive</span>}
                                                {data.is_binary === 1 && <span className='text-green'>Active</span>}
                                            </td>}
                                            <td>
                                                {data.is_referral === 0 && <span className='text-danger'>Inactive</span>}
                                                {data.is_referral === 1 && <span className='text-green'>Active</span>}
                                            </td>
                                            <td style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                                                <span className='btn btn-outline-theme btn-sm' onClick={() => { clickUpdate(data.id); setId(data.id);setPackageName(data.name) }}>
                                                    Edit
                                                    {updateLoader && data.id === id && <div className="spinner-border" style={{ width: '1rem', marginLeft: '10px', height: '1rem' }} role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>}
                                                </span>
                                                {data.is_binary_unilevel != 0 && data.is_binary &&
                                                <span className='btn btn-outline-theme btn-sm' onClick={() => { getBPack(data.id); setId(data.id);setPackageName(data.name) }}>
                                                    Set Binary
                                                    {binaryLoader && data.id === id && <div className="spinner-border" style={{ width: '1rem', marginLeft: '10px', height: '1rem' }} role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>}
                                                </span>}
                                                {data.is_referral_unilevel != 0 && data.is_referral && 
                                                <span className='btn btn-outline-theme btn-sm' onClick={() => { getRPack(data.id); setId(data.id);setPackageName(data.name) }}>
                                                    Set Referral
                                                    {referralLoader && data.id === id && <div className="spinner-border" style={{ width: '1rem', marginLeft: '10px', height: '1rem' }} role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>}
                                                </span>}
                                                {data.is_roi_unilevel != 0 && data.is_roi != 0 &&
                                                <span className='btn btn-outline-theme btn-sm' onClick={() => { getRoiPack(data.id); setId(data.id);setPackageName(data.name) }} >
                                                    Set Roi
                                                    {roiLoader && data.id === id && <div className="spinner-border" style={{ width: '1rem', marginLeft: '10px', height: '1rem' }} role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>}
                                                </span>}
                                            </td>
                                        </motion.tr>
                                        <motion.tr initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.8 }} key={`tb1${idx}`} className={`expendtb collapse ${expendTb == data.id ? 'show' : ''} cell-1 row-child`}>
                                            <td>
                                                <strong>Roi Percentage</strong>
                                            </td>
                                            <td>
                                                <strong>Roi Months</strong>
                                            </td>
                                            <td>
                                                <strong>Referral Level</strong>
                                            </td>
                                            {hideBinary == 1 &&<td>
                                                <strong>Matching Type</strong>
                                            </td>}
                                            {hideBinary == 1 &&<td>
                                                <strong>Matching Percentage</strong>
                                            </td>}
                                            {hideBinary == 1 &&<td>
                                                <strong>Calculate Binary Field</strong>
                                            </td>}
                                            {hideBinary == 1 &&<td>
                                                <strong>Binary Volume</strong>
                                            </td>}
                                            <td>
                                                <strong>Description</strong>
                                            </td>
                                            <td>
                                                <strong>Non Working Percentage</strong>
                                            </td>
                                            <td>
                                                <strong>Roi Unilevel</strong>
                                            </td>
                                            {hideBinary == 1 &&<td>
                                                <strong>Binary Unilevel</strong>
                                            </td>}
                                            <td>
                                                <strong> Referral Unilevel</strong>
                                            </td>
                                            {/* <td>
                                                <strong>Non Working(%)</strong>
                                            </td>
                                            <td>
                                                <strong>Total Income(%)</strong>
                                            </td> */}
                                        </motion.tr>
                                        <motion.tr initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} transition={{ duration: 0.8 }} key={`tb2${idx}`} className={`expendtb collapse ${expendTb == data.id ? 'show' : ''} cell-1 row-child`}>
                                            <td>{data.roi_percentage}</td>
                                            <td>{data.roi_days}</td>
                                            <td>{data.referral_level||'-'}</td>
                                            {hideBinary == 1 &&<td>{data.binary_type}</td>}
                                            {hideBinary == 1 &&<td>{data.binary_percentage}</td>}
                                            {hideBinary == 1 &&<td>{data.calculate_binary_field}</td>}
                                            {hideBinary == 1 &&<td>{data.binary_volume || '-'}</td>}
                                            <td>{data.description || '-'}</td>
                                            <td>{parseFloat(data.non_working_percentage)||0}</td>
                                            <td>
                                                {data.is_roi_unilevel === 0 && <span className='text-danger'>Inactive</span>}
                                                {data.is_roi_unilevel === 1 && <span className='text-green'>Active</span>}
                                            </td>
                                            {hideBinary == 1 &&<td>
                                                {data.is_binary_unilevel === 0 && <span className='text-danger'>Inactive</span>}
                                                {data.is_binary_unilevel === 1 && <span className='text-green'>Active</span>}
                                            </td>}
                                            <td>
                                                {data.is_referral_unilevel === 0 && <span className='text-danger'>Inactive</span>}
                                                {data.is_referral_unilevel === 1 && <span className='text-green'>Active</span>}
                                            </td>
                                            {/* <td>
                                                <span>{parseFloat(data.non_working_percentage)}</span>
                                            </td>
                                            <td>
                                                <span>{parseFloat(data.total_income_percentage)}</span>
                                            </td> */}
                                        </motion.tr>
                                    </>
                                )
                            })
                        }
                    </tbody>}
                    {
                        countTable === 0 && <div style={{ display: 'table-caption', marginTop: '1rem', textAlign: 'center' }}>No Data For Action!!!</div>
                    }
                </Table>
                {table && 
                <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                    <p className="h6">
                        Showing <span className="font-medium">{packageListData.from||'-'}</span> to <span className="font-medium">{packageListData.to||'-'}</span> of{' '}
                        <span className="font-medium">{packageListData.total}</span> results
                    </p>
                    <div className="d-flex">
                        <nav aria-label="Page navigation">
                            <ul className="pagination mb-0">
                                <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(packageListData.prev_page_url)}>
                                    <a className="page-link" aria-label="Previous">
                                        <span className="btn-link cursor-pointer">&laquo;</span>
                                        
                                    </a>
                                </li>
                                <li className="page-item" style={{ cursor: 'pointer' }}>
                                    <a className="page-link">{packageListData.current_page}</a>
                                </li>
                                <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(packageListData.next_page_url)}>
                                    <a className="page-link" aria-label="Next">
                                        <span className="btn-link">&raquo;</span>
                                        
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>}
                </div>
                
            </div>}




            {/* {filterShow&&<Filter props={{setFilterShow,url:`${process.env.REACT_APP_BASE_URL}/approval/transfer`,list,filterFunData}}/>} */}
            {blocking && <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.09)', backdropFilter: 'blur(1px)' }} />}
           
            {
                showUpdateModel &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header closeButton onClick={() => closeModel()}>
                        <Modal.Title>Update {packageName} Package</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form>
                            <div className="row">
                                <div className="col col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Name <span className="required">*</span></label>
                                        <input type="text" name="Package Name" className="form-control" value={updateData.name} onChange={(e) => setUpdateData({ ...updateData, 'name': e.target.value })} required />
                                    </div>
                                </div>
                                <div className="col col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Type <span className="required">*</span></label>
                                        <select className="form-control" value={updateData.type} onChange={(e) => { setPrice(e.target.value); setUpdateData({ ...updateData, 'type': e.target.value }) }} required>
                                            <option value="">Select Type</option>
                                            <option value="fixed">Fixed</option>
                                            <option value="variable">Variable</option>
                                        </select>
                                    </div>
                                </div>
                                {(updateData.type === 'fixed') && <div className="col col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Price <span className="required">*</span></label>
                                        <input type="number" className="form-control" id="inputGroupPrepend2" aria-describedby="inputGroupPrepend2" value={parseFloat(updateData.price)} onChange={(e) => setUpdateData({ ...updateData, 'price': e.target.value })} required />
                                    </div>
                                </div>}
                                {(updateData.type === 'variable') && <div className="col col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Minimum Price <span className="required">*</span></label>
                                        <input type="number" name="phoneNumber" className="form-control" value={parseFloat(updateData.minimum_price)} onChange={(e) => setUpdateData({ ...updateData, 'minimum_price': e.target.value })} required />
                                    </div>
                                </div>}
                                {(updateData.type === 'variable') && <div className="col col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Maximum Price <span className="required">*</span></label>
                                        <input type="number" name="phoneNumber" className="form-control" value={parseFloat(updateData.maximum_price)} onChange={(e) => setUpdateData({ ...updateData, 'maximum_price': e.target.value })} required />
                                    </div>
                                </div>}
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Duration (Month)<span className="required">*</span></label>
                                        <input type="number" name="duration" className="form-control" value={updateData.duration} onChange={(e) => setUpdateData({ ...updateData, 'duration': e.target.value })} required />
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-1">
                                    <div className="form-group">
                                        <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                                            <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked2">Unilevel :</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked2" checked={updateData.is_unilevel ? true : false} onChange={(e) => { setIs_unilevel(e.target.checked); changeUnilevel(e.target.checked); setUpdateData({ ...updateData, 'is_unilevel': e.target.checked ? 1 : 0 }) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(updateData.is_unilevel || is_unilevel) && <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                        <label className="text-label">Roi Unilevel <span className="required">*</span></label>
                                        <div className="form-group mb-3">
                                            <select className="form-control" value={updateData.is_roi_unilevel} onChange={(e) => setUpdateData({ ...updateData, 'is_roi_unilevel': e.target.value })}>
                                                <option value={''}>Select Type</option>
                                                <option value={1}>Active</option>
                                                <option value={0}>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>}
                                {(updateData.is_unilevel || is_unilevel) && hideBinary == 1 && <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                        <label className="text-label">Binary Unilevel <span className="required">*</span></label>
                                        <div className="form-group mb-3">
                                            <select className="form-control" value={updateData.is_binary_unilevel} onChange={(e) => setUpdateData({ ...updateData, 'is_binary_unilevel': e.target.value })}>
                                                <option value={''}>Select Type</option>
                                                <option value={1}>Active</option>
                                                <option value={0}>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>}
                                {(updateData.is_unilevel || is_unilevel) && <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                        <label className="text-label">Referral Unilevel <span className="required">*</span></label>
                                        <div className="form-group mb-3">
                                            <select className="form-control" value={updateData.is_referral_unilevel} onChange={(e) => setUpdateData({ ...updateData, 'is_referral_unilevel': e.target.value })}>
                                                <option value={''}>Select Type</option>
                                                <option value={1}>Active</option>
                                                <option value={0}>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>}
                                <div className="col-lg-12 mb-1">
                                    <div className="form-group">
                                        <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                                            <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked1">Roi :</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked1" checked={updateData.is_roi ? true : false} onChange={(e) => { setIs_roi(e.target.checked); changeRoi(e.target.checked) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(updateData.is_roi || is_roi) && updateData.is_roi_unilevel != 0 && <div className="col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Roi Level <span className="required">*</span></label>
                                        <input type="number" name="RoiLevel" className="form-control" value={updateData.roi_level} onChange={(e) => setUpdateData({ ...updateData, 'roi_level': e.target.value })} required />
                                    </div>
                                </div>}
                                {(updateData.is_roi || is_roi) && <div className="col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Roi Percentage <span className="required">*</span></label>
                                        <input type="number" name="RoiPercentage" className="form-control" value={parseFloat(updateData.roi_percentage)} onChange={(e) => setUpdateData({ ...updateData, 'roi_percentage': e.target.value })} required />
                                    </div>
                                </div>}
                                {(updateData.is_roi || is_roi) && <div className="col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Roi Manths <span className="required">*</span></label>
                                        <input type="number" name="RoiDays" className="form-control" value={updateData.roi_days} onChange={(e) => setUpdateData({ ...updateData, 'roi_days': e.target.value })} required />
                                    </div>
                                </div>}
                                {hideBinary == 1 && <div className="col-lg-12 mb-1">
                                    <div className="form-group">
                                        <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                                            <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked3">Binary :</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked3" checked={updateData.is_binary ? true : false} onChange={(e) => { setIs_binary(e.target.checked); changeBinary(e.target.checked); setUpdateData({ ...updateData, 'is_binary': e.target.checked ? 1 : 0 }) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {(updateData.is_binary || is_binary) && updateData.is_binary_unilevel != 0 && <div className="col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Binary Level <span className="required">*</span></label>
                                        <input type="text" name="calculate_binary_field" className="form-control" value={updateData.binary_level} onChange={(e) => setUpdateData({ ...updateData, 'binary_level': e.target.value })} required />
                                    </div>
                                </div>}
                                {(updateData.is_binary || is_binary) && <div className="col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Calculate Binary Field <span className="required">*</span></label>
                                        <select className="form-control" value={updateData.calculate_binary_field} onChange={(e) => { setUpdateData({ ...updateData, 'calculate_binary_field': e.target.value }) }} required>
                                            <option value={''}>Select Matching Type</option>
                                            <option value={'price'}>Price</option>
                                            <option value={'binary_volume'}>Volume</option>
                                        </select>
                                    </div>
                                </div>}
                                {updateData.calculate_binary_field === "binary_volume" && <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                        <label className="text-label">Binary Volume <span className="required">*</span></label>
                                        <input type="text" name="Package Name" className="form-control" value={updateData.binary_volume} onChange={(e) => setUpdateData({ ...updateData, 'binary_volume': e.target.value })} required />
                                    </div>
                                </div>}
                                {(updateData.is_binary || is_binary) && <div className="col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Matching Percentage <span className="required">*</span></label>
                                        <input type="number" name="binary_percentage" className="form-control" value={parseFloat(updateData.binary_percentage)} onChange={(e) => setUpdateData({ ...updateData, 'binary_percentage': e.target.value })} required />
                                    </div>
                                </div>}
                                {(updateData.is_binary || is_binary) && <div className="col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Matching Type <span className="required">*</span></label>
                                        <select className="form-control" value={updateData.binary_type} onChange={(e) => setUpdateData({ ...updateData, 'binary_type': e.target.value })}>
                                            <option value={''}>Select Type</option>
                                            <option value={'fixed_percentage'}>Fixed Percentage</option>
                                            <option value={'hybrid_percentage'}>Hybrid Percentage</option>
                                        </select>
                                    </div>
                                </div>}
                                <div className="col-lg-12 mb-1">
                                    <div className="form-group">
                                        <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                                            <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked4">Referral :</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked4" checked={updateData.is_referral ? true : false} onChange={(e) => { setIs_referral(e.target.checked); changeReferral(e.target.checked); setUpdateData({ ...updateData, 'is_referral': e.target.checked ? 1 : 0 }) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {(updateData.is_referral || is_referral) && updateData.is_referral_unilevel != 0 && <div className="col-lg-6 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Referral level <span className="required">*</span></label>
                                        <input type="number" name="phoneNumber" className="form-control" value={updateData.referral_level} onChange={(e) => setUpdateData({ ...updateData, 'referral_level': e.target.value })} required />
                                    </div>
                                </div>}
                                {/* <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                        <label className="text-label">Non Working Percentage <span className="required">*</span></label>
                                        <input type="number" name="non_working_percentage" className="form-control" value={parseFloat(updateData.non_working_percentage)} onChange={(e) => setUpdateData({ ...updateData, 'non_working_percentage': e.target.value })} required />
                                    </div>
                                </div> */}
                                {/* <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                        <label className="text-label">Total Income Percentage <span className="required">*</span></label>
                                        <input type="number" name="total_income_percentage" className="form-control" value={parseFloat(updateData.total_income_percentage)} onChange={(e) => setUpdateData({ ...updateData, 'total_income_percentage': e.target.value })} required />
                                    </div>
                                </div> */}
                                <div className="col-lg-6 mb-2">
                                    <div className="form-group mb-3">
                                        <label className="text-label">Status <span className="required">*</span></label>
                                        <div className="form-group mb-3">
                                            <select className="form-control" value={updateData.status} onChange={(e) => setUpdateData({ ...updateData, 'status': e.target.value })}>
                                                <option value={''}>Select Status</option>
                                                <option value={1}>Active</option>
                                                <option value={0}>Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-1">
                                    <div className="form-group">
                                        <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                                            <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked4">Cancelable :</label>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked4" checked={updateData.is_cancellation ? true : false} onChange={(e) => {setUpdateData({ ...updateData, 'is_cancellation': e.target.checked ? 1 : 0 }) }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Description</label>
                                        <input type="text" name="place" className="form-control" value={updateData.description} onChange={(e) => setUpdateData({ ...updateData, 'description': e.target.value })} required />
                                    </div>
                                </div>
                                {/* <div className="col-lg-12 mb-1">
                                    <div className="form-group mb-1">
                                        <label className="text-label">Non Working Percentage</label>
                                        <input type="text" name="non_working_percentage" className="form-control" value={parseFloat(updateData.non_working_percentage)} onChange={(e) => setUpdateData({ ...updateData, 'non_working_percentage': e.target.value })} required />
                                    </div>
                                </div> */}
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => closeModel()}>Close</Button>
                        <Button onClick={updatePackageData} className='d-flex justify-content-center align-items-center gap-2' variant="primary">
                            Update
                            {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
            {showBinaryModel &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header closeButton onClick={() => setShowBinaryModel(false)}>
                        <Modal.Title>Edit {packageName} Matching Percentage</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row" style={{justifyContent:'center'}}>
                            {
                                (
                                    () => {
                                        let ls = [];
                                        for (let i = 1; i <= binaryPackageData; i++) {
                                            ls.push(
                                                <div key={i} className="col-lg-5" style={{border:'1px solid white',padding:'10px',borderRadius:10,margin:'5px'}}>
                                                    <label className="text-label">{`Level ${i}`}</label>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem',}}>
                                                        <div className="form-group">
                                                            <label className="text-label">{`Percentage`}</label>
                                                            <input type="number" onWheel={numberInputOnWheelPreventChange} className="form-control" value={parseFloat(sendBinaryData[`percentage${i}`])||0} onChange={(e) => setBinaryData(`percentage${i}`, e.target.value)} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-label">{`Direct`}</label>
                                                            <input type="number" onWheel={numberInputOnWheelPreventChange} className="form-control" value={parseFloat(sendBinaryData[`direct${i}`])||0} onChange={(e) => setBinaryData(`direct${i}`, e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return ls;
                                    }
                                )()
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowBinaryModel(false)}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={SubmitBinary}>
                            Update
                            {modalLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
            {showReferrallevellModel &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header closeButton onClick={() => setShowReferrallevellModel(false)}>
                        <Modal.Title>Edit {packageName} Referral Percentage</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row" style={{justifyContent:'center'}}>
                            {
                                (
                                    () => {
                                        let ls = [];
                                        for (let i = 1; i <= referralPackageData; i++) {
                                            ls.push(
                                                <div key={i} className="col-lg-5" style={{border:'1px solid white',padding:'10px',borderRadius:10,margin:'5px'}}>
                                                    <label className="text-label">{`Level ${i}`}</label>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem',}}>
                                                        <div className="form-group">
                                                            <label className="text-label">{`Amount`}</label>
                                                            <input type="number" onWheel={numberInputOnWheelPreventChange} className="form-control" value={parseFloat(sendReferrallevelData[`percentage${i}`])||0} onChange={(e) => setReferrallevelData(`percentage${i}`, e.target.value)} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-label">{`Direct`}</label>
                                                            <input type="number" onWheel={numberInputOnWheelPreventChange} className="form-control" value={parseFloat(sendReferrallevelData[`direct${i}`])||0} onChange={(e) => setReferrallevelData(`direct${i}`, e.target.value)} />
                                                        </div>
                                                        {/* <div className="form-group">
                                                            <label className="text-label">{`Business Amount`}</label>
                                                            <input type="text" className="form-control" value={parseFloat(sendReferrallevelData[`business_amount${i}`])} onChange={(e) => setReferrallevelData(`business_amount${i}`, e.target.value.replace(/[^0-9.]/ig, ''))} />
                                                        </div> */}
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return ls;
                                    }
                                )()
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowReferrallevellModel(false)}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={SubmitRefferrallevel}>
                            Update
                            {modalLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
            {showRoilevellModel &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header closeButton onClick={() => setShowRoilevellModel(false)}>
                        <Modal.Title>Edit {packageName} Roi Percentage</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row" style={{justifyContent:'center'}}>
                            {
                                (
                                    () => {
                                        let ls = [];
                                        for (let i = 1; i <= roiPackageData; i++) {
                                            ls.push(
                                                <div key={i} className="col-lg-5" style={{border:'1px solid white',padding:'10px',borderRadius:10,margin:'5px'}}>
                                                    <label className="text-label">{`Level ${i}`}</label>
                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', }}>
                                                        <div className="form-group">
                                                            <label className="text-label">{`Percentage`}</label>
                                                            <input type="number" onWheel={numberInputOnWheelPreventChange} className="form-control" value={parseFloat(sendRoilevelData[`percentage${i}`])||0} onChange={(e) => setRoilevelData(`percentage${i}`, e.target.value)} />
                                                        </div>
                                                        <div className="form-group">
                                                            <label className="text-label">{`Direct`}</label>
                                                            <input type="number" onWheel={numberInputOnWheelPreventChange} className="form-control" value={parseFloat(sendRoilevelData[`direct${i}`])||0} onChange={(e) => setRoilevelData(`direct${i}`, e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        return ls;
                                    }
                                )()
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowRoilevellModel(false)}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={SubmitRoilevel}>
                            Update
                            {modalLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
        </Frame>
    )
}
