import React, { useContext, useEffect, useRef, useState } from "react";
import { notifyError, notifySuccess } from "../../parts/Toast";
import { getReq, postForm, postReq, sendOtp } from "../../requests/request";
import { SidebarClick } from "../../routes/route";
import { Spinner, Table, Card, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import MoreDetails from "../../parts/MoreDetails";
import CheckFormError from "../../parts/FormError";
import { useNavigate } from "react-router-dom";
import Frame from "../../components/frame";

export default function ManualDeposit() {
  const navigate = useNavigate();
  const [data, setData] = useState({
    "username": "",
    'amount':'',
    'address':'',
    'tHash':'',
  });
  const [loader, setLoader] = useState(false);
  let count = 0;
  async function sendData(){
    setLoader(true);
    let result = await postForm('deposit-by-admin',data,sessionStorage.getItem('token'))
    if(result.errors){
      let err = Object.values(result.errors);
      //err.map((data)=>notifyError(data[0]));
      notifyError(err[0][0]);
      setLoader(false);
    }
    if((result.message||result.msg||result.data)&&result.status){
        notifySuccess(result.message||result.msg||result.data);
        setData({"username": "",'amount':'','address':'','tHash':''});
        setLoader(false);
        navigate('/admin/manualDepositList');
    }
    if(result.status==false && result.msg==401){
        sessionStorage.clear();
        window.history.replaceState(null, null, "/");
        navigate('/',{ replace: true });
        notifyError('Authentication Failed. Please Login Again....');
    }
    if((result.message||result.msg)&&result.status==false){
        notifyError(result.message||result.msg);
        setData({"username": "",'amount':'','address':'','tHash':'',});
        setLoader(false);
    }
    setLoader(false);
  }
  function bankListCheck(id) {
    let temp = { ...data };
    temp["bank_id"] = id;
    setData(temp);
  }
  function handleStrNum(e, key) {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z0-9]/gi, "");
    let temp = { ...data };
    temp[key] = value;
    setData(temp);
  }

  return (
    <Frame withHeader={true} withLeftpart={true} withFooter={true} style={{height: "calc(100vh - 120px)"}}>
      <div className="d-flex align-items-center mb-md-3 mb-2">
        <h1 className="page-header mb-0">Deposit</h1>
      </div>

      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-sm-6 m-b30">
              <div className="form-group mb-3"><label className="mb-1">Username</label>
                <input type="text" name="username" className="form-control" placeholder='username' value={data.username} onChange={(e)=>setData({...data,username:e.target.value})} required/>
              </div>
            </div>
            <div className="col-sm-6 m-b30 mb-3">
              <label className="mb-1">
                Amount({process.env.REACT_APP_Amount_Symbol||'$'})<span className="required">*</span>
              </label>
              <input
                type="text"
                name="amount"
                className="form-control"
                placeholder="enter amount"
                value={data.amount}
                onChange={(e) =>
                  setData({
                    ...data,
                    amount: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="col-sm-6 m-b30">
              <div className="form-group mb-3"><label className="mb-1">Address</label>
                <input type="text" name="address" className="form-control" placeholder='address' value={data.address} onChange={(e)=>setData({...data,address:e.target.value})} required/>
              </div>
            </div>
            <div className="col-sm-6 m-b30">
                <div className="form-group mb-3"><label className="mb-1">Transaction Hash <span className="required">*</span></label>
                  <input type="text" name="transaction" className="form-control" placeholder='enter transaction hash' value={data.tHash} onChange={(e)=>setData({...data,tHash:e.target.value})} required/>
                </div>
            </div>
          </div>
          <button className="btn btn-primary" style={{display: "flex", gap: "0.5rem", alignItems: "center" }} onClick={()=>sendData()} disabled={loader}>
            Deposit
            {loader && (
              <div
                className="spinner-border"
                style={{ width: "1rem", height: "1rem" }}
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </Frame>
  );
}
