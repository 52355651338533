import { Navigate } from "react-router-dom";
//import { currentPageShow } from "../App";
//import { useContext } from "react";

export default function ProtectedRoute({ Children }) {
    //const {mainToken} = useContext(currentPageShow);
    const status = sessionStorage.getItem('status');
    const token = sessionStorage.getItem('token');
    if (status && token) {
        return <Children />;
    }
    return <Navigate to={'/login'} />;
};