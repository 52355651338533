import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Card, Table, Spinner } from "react-bootstrap";
import { getReq, getTable } from '../../requests/request';
import { notifyError } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import { IMAGES, SVGICON } from "../../constant/theme";
import openImg from '../../parts/ImageNewTab';
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import DownloadExcel from '../../parts/DownloadExcel';
import MoreDetails from '../../parts/MoreDetails';
import Frame from "../../components/frame";

export default function KycList() {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [spinner, setSpinner] = useState(false);
    const [table, setTable] = useState();
    const [kycData, setKycData] = useState();
    const [refrashPage, setRefrashPage] = useState(false);
    const [searchBy, setSearchBy] = useState('');
    const [searchByText, setSearchByText] = useState('');
    const [filterShow, setFilterShow] = useState(false);
    const [moreDetails, setMoreDetails] = useState('');
    let countTable = 0;
    const list = [
        { 'label': 'Username', 'data': 'username', 'type': 'text' },
        { 'label': 'Document Name', 'data': 'document_name', 'type': 'text' },
        { 'label': 'Document Type', 'data': 'document_type', 'type': 'text' },
        { 'label': 'Status', 'data': 'status', 'type': 'option', 'value': [{ 'key': 'Approved', 'value': '1' }, { 'key': 'Rejected', 'value': '2' }, { 'key': 'Pending', 'value': '0' }] }
    ]
    let durationanim = 0.15;
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/kycList');
        setCollapseId(2);
        (async () => {
            try {
                //setSpinner(true);
                let r = await getReq('kyc-list-all', sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setKycData(r.data);
                    setTable(r.data.data);
                    setMoreDetails(r.more_details);
                    //setSpinner(false);
                }
                if (r.errors) {
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                if (err.message) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                //setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    }, [refrashPage]);
    async function searchTable(e) {
        try {
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/kyc-list-all?page=${e.target.value}`, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                setKycData(r.data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if (r.errors) {
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function nextTable(url) {
        try {
            if (url !== null) {
                setSpinner(true);
                let r = await getTable(url, sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setKycData(r.data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    function filterFunData(r) {
        setKycData(r.data);
        setTable(r.data.data);
        setMoreDetails(r.more_details);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                        Kyc List
                    </h1>
                </div>
                <div className="ms-auto">
                    {table && <DownloadExcel props={{ tableRef: tableRef, name: 'kyclist' }} />}
                </div>
            </div>
             {moreDetails && <MoreDetails props={moreDetails} />}
             {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}
            {table&&<div className="card">
                <div className="card-header align-items-center fw-bold small d-flex">
                    <span className="flex-grow-1">KYC List</span>
                    <span className="btn btn-outline-light btn-sm" onClick={() => setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div>
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                    <Table responsive style={{ textAlign: 'center' }} ref={tableRef}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>username</strong>
                                </th>
                                <th>
                                    <strong>Document Name</strong>
                                </th>
                                <th>
                                    <strong>Document Number</strong>
                                </th>
                                <th>
                                    <strong>Document Type</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                                <th>
                                    <strong>Front Document Path</strong>
                                </th>
                                <th>
                                    <strong>Back Document Path</strong>
                                </th>
                                <th>
                                    <strong>Updated At</strong>
                                </th>
                            </tr>
                        </thead>
                        {table && <tbody>
                            {
                                table.map((data, idx) => {
                                    data.status != 0 ? countTable += 1 : countTable += 0;
                                    return (data.status != 0 && <motion.tr initial={{ opacity: 0, x: -150 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: durationanim += 0.06 }} key={idx}>
                                        <td>{data.username}</td>
                                        <td style={{textTransform: 'capitalize'}}>{data.document_name&&data.document_name.replaceAll('_',' ') || '-'}</td>
                                        <td>{data.document_number}</td>
                                        <td style={{textTransform: 'capitalize'}}>{data.document_type&&data.document_type.replaceAll('_',' ') || '-'}</td>
                                        <td>
                                            {data.status === 0 && <span className="badge light btn btn-warning">Pending</span>}
                                            {data.status === 1 && <span className="badge light btn btn-success">Approved</span>}
                                            {data.status === 2 && <span className="badge light btn btn-danger">Rejected</span>}
                                        </td>
                                        <td style={{ textAlign: "-webkit-center" }}>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                                {/* <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "10rem" }}>
                                                    {data.front}
                                                </div> */}
                                                <div className="menu-icon" style={{ margin: '2px', cursor: 'pointer' }} onClick={() => openImg(`${process.env.REACT_APP_IMG_URL}/${data.front}`)}>View {SVGICON.newTab}</div>
                                            </div>
                                        </td>
                                        <td style={{ textAlign: "-webkit-center" }}>
                                            {data.back ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center" }}>
                                                {/* <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "10rem" }}>
                                                    {data.back}
                                                </div> */}
                                                <div className="menu-icon" style={{ margin: '2px', cursor: 'pointer' }} onClick={() => openImg(`${process.env.REACT_APP_IMG_URL}/${data.back}`)}>View {SVGICON.newTab}</div>
                                            </div> : '-'}
                                        </td>
                                        <td>{data.kyc_updated_at || '-'}</td>
                                    </motion.tr>)
                                })
                            }
                        </tbody>}
                        {
                            countTable == 0 && <div style={{ display: 'table-caption', marginTop: '1rem', textAlign: 'center' }}>No Data For Action!!!</div>
                        }
                    </Table>
                    {table && 
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className="h6">
                            Showing <span className="font-medium">{kycData.from||'-'}</span> to <span className="font-medium">{kycData.to||'-'}</span> of{' '}
                            <span className="font-medium">{kycData.total}</span> results
                        </p>
                        <div className="d-flex">
                           <nav aria-label="Page navigation">
                                <ul className="pagination mb-0">
                                    <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(kycData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link cursor-pointer">&laquo;</span>
                                            
                                        </a>
                                    </li>
                                    <li className="page-item" style={{ cursor: 'pointer' }}>
                                        <a className="page-link">{kycData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(kycData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
                </div>
                
            </div>}
            {filterShow && <Filter props={{ setFilterShow, url: `${process.env.REACT_APP_BASE_URL}/kyc-list-all`, list, filterFunData }} />}
           
        </Frame>
    )
}

