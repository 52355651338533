import React, { useContext, useEffect, useState } from 'react';
import {Col,Card,Table, Spinner} from "react-bootstrap";
import { getReq, postReq } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { useNavigate } from 'react-router-dom';
import DocTypeAdd from '../../parts/DocTypeAdd';
import { SidebarClick } from '../../routes/route';
import { motion } from "framer-motion";
import Frame from "../../components/frame";

export default function KycDocList() {
    const navigate = useNavigate();
    const [reload,setReload] = useState(false);
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [showModel,setShowModel] = useState(false);
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    let durationanim = 0.15;
    useEffect(()=>{
        setReload(false);
        setCurrentSidebarClick('/admin/kycDocList');
        setCollapseId(2);
        (async()=>{
            try{
                setSpinner(true);
                let r = await getReq('doc-type',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setTable(r.data);
                    setSpinner(false);
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                if(err.message){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                }
                setSpinner(false);
                notifyError(`Request failed ${err.message}`);
        }
        })()
    },[reload]);
    async function changeKycList(id){
        let r = await postReq('update/doc-type',{'id':id},sessionStorage.getItem('token'))
        if(r.status==="success" || r.status===true){
            notifySuccess(r.message);
            setReload(true);
        }
        if(r.errors){
            let err = Object.values(r.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        if(r.message&&r.status===false){
            notifyError(r.message);
        }
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                    Kyc Doc List
                    </h1>
                </div>
                <div className="ms-auto">
                    <button className="btn btn-outline-theme btn btn-outline-info" onClick={()=>setShowModel(true)}>Add Doc Type</button>
                </div>
            </div>

            <div className="card">
                <div className="card-header fw-bold small">Document Type List</div>
                <div className="card-body">
                <Table responsive style={{textAlign:'center'}}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Name</strong>
                                </th>
                                <th>
                                    <strong>Document Type</strong>
                                </th>
                                <th>
                                    <strong>Type</strong>
                                </th>
                                <th>
                                    <strong>Enable/Disable</strong>
                                </th>
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>
                                <motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                                    <td>{data.country}</td>
                                    <td>{data.document_type}</td>
                                    <td>
                                        {
                                            data.type.map((data,idx)=>
                                                <div><span key={idx} style={{textTransform:'capitalize'}}>{`${data}`}</span><br/></div>
                                            )
                                        }
                                    </td>
                                    <td>
                                        <div className="form-check form-switch" style={{display:'flex',justifyContent:'center',alignContent:'center'}}>
                                            <input className="form-check-input" type="checkbox" role="switch" defaultChecked={data.status?true:false} id="flexSwitchCheckChecked" onClick={()=>changeKycList(data.id)}/>
                                        </div>
                                    </td>
                                </motion.tr>)
                            }
                        </tbody>}
                    </Table>
                </div>
                
            </div>




        {showModel&&<DocTypeAdd props={{setShowModel,setReload}}/>}
        </Frame>
    )
}