import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Nav, Tab, Spinner } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { getReq, postForm, postReq } from '../../requests/request';
import { SidebarClick } from '../../routes/route';
import { IMAGES } from '../../constant/theme';
import CheckFormError from '../../parts/FormError';
import Frame from "../../components/frame";

export default function ProfileSetting() {
    const navigate = useNavigate();
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [username, setUsername] = useState('');
    const [spinner, setSpinner] = useState(false);
    const [refrashPage, setRefrashPage] = useState(false);
    const [sendSettingData, setSendSettingData] = useState({});
    const [password, setPassword] = useState({ 'currentpassword': '', 'newpassword': '', 'confpassword': '' });
    const [sPassword, setSpassword] = useState({ "old_password": "", "spassword": "", "spassword_confirmation": "" });
    const [passwordError, setPasswordError] = useState({});
    const [sPasswordError, setSpasswordError] = useState({});
    const [authType, setAuthType] = useState({ 'type': '' });
    const [passwordLoader, setPasswordLoader] = useState(false);
    const [twofa, setTwofa] = useState({ 'code': '' });
    const [twofaError, setTwofaError] = useState({});
    const [loader, setLoader] = useState(false);
    const [status_2fa, setStatus_2fa] = useState();
    const [image_2fa,setImage_2fa] = useState();
    const [profile, setProfile] = useState('');
    const [tempPhoto, setTempPhoto] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [profileLoader, setProfileLoader] = useState(false);
    const [profileError, setProfileError] = useState({});
    const [sPasswordLoader, setSpasswordLoader] = useState(false);
    const [showPass, setShowPass] = useState(true);
    const [twofaLoader, setTwofaLoader] = useState(false);
    const [more,setMore] = useState({});
    const [activeTab,setActiveTab] = useState("Profile");
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/setting');
        setCollapseId(1);
        (async () => {
            try {
                let r = await getReq('country', sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setCountryCode(r.data);
                }
                if (r.errors) {
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                if (err.response.status && err.response.status === 401 || err.response.status === 404) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                notifyError("Request failed please login again");
            }
        })()
    }, [refrashPage])
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/setting');
        setCollapseId(1);
        (async () => {
            try {
                setSpinner(true);
                let r = await getReq('authentication', sessionStorage.getItem('token'));
                if (r.status === "success" || r.status === true) {
                    setAuthType({ ...authType, 'type': r.data });
                    setMore(r.more_details);
                    setSpinner(false);
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                if (err.message) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                setSpinner(false);
                //notifyError(`Request failed ${err.message}`);
            }
        })()
    }, [refrashPage]);
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/setting');
        setCollapseId(1);
        (async () => {
            try {
                setSpinner(true);
                let r = await getReq('profile', sessionStorage.getItem('token'));
                console.log(r)
                if (r.status === "success" || r.status === true) {
                    let temp = r.data;
                    setImage_2fa(temp.image_2fa);
                    setStatus_2fa(temp.status_2fa);
                    setUsername(temp.username);
                    delete temp.id;
                    delete temp.admin_id;
                    delete temp.last_login_at;
                    delete temp.status;
                    delete temp.created_at;
                    delete temp.updated_at;
                    delete temp.image_2fa;
                    delete temp.status_2fa;
                    delete temp.phone_verified_at;
                    setProfile(temp);
                    setSpinner(false);
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                if (err.message) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                setSpinner(false);
                //notifyError(`Request failed ${err.message}`);
            }
        })()
    }, [refrashPage]);
    async function changeAuthentication(e){
        e.preventDefault();
        if(authType.type!=""){
            setPasswordLoader(true);
            if(more.authenticator&&authType.type=='authenticator'){
                let r = await postReq('authentication',authType,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status){
                    notifySuccess(r.message||r.msg);
                    setPasswordLoader(false);
                }
                if(r.msg && !r.status){
                    notifyError(r.msg||r.message);
                    setPasswordLoader(false);
                }
                if(r.errors){
                    setPasswordLoader(false);
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                }
            }
            else if(more.mpin&&authType.type=='mpin'){
                let r = await postReq('authentication',authType,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status){
                    notifySuccess(r.message||r.msg);
                    setPasswordLoader(false);
                }
                if(r.msg && !r.status){
                    notifyError(r.msg||r.message);
                    setPasswordLoader(false);
                }
                if(r.errors){
                    setPasswordLoader(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            else if(authType.type=='otp'){
                let r = await postReq('authentication',authType,sessionStorage.getItem('token'));
                if(r.status==="success" || r.status){
                    notifySuccess(r.message||r.msg);
                    setPasswordLoader(false);
                }
                if(r.msg && !r.status){
                    notifyError(r.msg||r.message);
                    setPasswordLoader(false);
                }
                if(r.errors){
                    setPasswordLoader(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            else{
                notifyError(`Please First Set Your ${authType.type=='mpin'?'MPIN':'2FA Authenticator'}`);
                if(authType.type=='mpin'){
                    setActiveTab('Sec Change Password'.toLowerCase());
                }
                if(authType.type=='2fa'){
                    setActiveTab('2fa'.toLowerCase());
                }
                setPasswordLoader(false);
            }
        }
        else{
            notifyError(`Please Select Correct Option.`);
            setPasswordLoader(false);
        }
    }
    async function ed2fa() {
        let temp2fa = status_2fa;
        let { errList, countError } = CheckFormError({ ...twofa });
        setTwofaError(errList);
        if (countError <= 0) {
            setTwofaLoader(true);
            let result = await postForm(`profile/change-2fa`, twofa, sessionStorage.getItem('token'))
            if (result.errors) {
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
                setStatus_2fa(temp2fa);
            }
            if ((result.message || result.msg) && result.status) {
                notifySuccess(result.message || result.msg);
                setStatus_2fa(!temp2fa);
                setTwofa({ 'code': '' });
            }
            if ((result.message || result.msg) && result.status == false) {
                notifyError(result.message || result.msg);
                setStatus_2fa(temp2fa);
            }
            setTwofaLoader(false);
            setRefrashPage(true);
        }
    }
    async function updateProfileFun(e) {
        e.preventDefault();
        let temp = { ...profile };
        delete temp.address_line2;
        delete temp.tax_id;
        let { errList, countError } = CheckFormError({ ...temp });
        setProfileError(errList);
        if (countError <= 0) {
            setProfileLoader(true);
            let result = await postForm(`update-profile`, profile, sessionStorage.getItem('token'))
            if (result.errors) {
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            if (result.message || result.msg && result.status) {
                notifySuccess(result.message || result.msg);
                setRefrashPage(true);
            }
        }
        setProfileLoader(false);
    }
    async function updateImage(img) {
        let temp = { 'photo': img }
        if (img) {
            let result = await postForm(`profile/change-picture`, temp, sessionStorage.getItem('token'));
            console.log('>>',result)
            if (result.errors) {
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
                setRefrashPage(true);
            }
            if (result.message || result.msg && result.status) {
                notifySuccess(result.message || result.msg);
                setRefrashPage(true);
                //setTimeout(function(){window.location.reload();},100);
            }
            if (result.message || result.msg && result.status == false) {
                notifyError(result.message || result.msg);
                setRefrashPage(true);
            }
        }
    }
    async function changePassword(e) {
        e.preventDefault();
        setPasswordLoader(true);
        let { errList, countError } = CheckFormError({ ...password });
        setPasswordError(errList);
        if (password.newpassword == password.confpassword && countError <= 0) {
            let temp = password;
            if (temp.newpassword == temp.confpassword) {
                setShowPass(false);
            }
            delete temp.confpassword;
            let result = await postForm(`change-password`, temp, sessionStorage.getItem('token'))
            if (result.errors) {
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            if (result.message || result.msg && result.status) {
                notifySuccess(result.message || result.msg);
                setRefrashPage(true);
                setPassword({ 'currentpassword': '', 'newpassword': '', 'confpassword': '' });
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/', { replace: true });
            }
            if (result.message && result.status === false) {
                notifyError(result.message);
            }
            setPasswordLoader(false);
        }
        else {
            setPasswordLoader(false);
        }
    }
    async function changeSPassword(e) {
        e.preventDefault();
        let { errList, countError } = CheckFormError({ ...sPassword });
        setSpasswordError(errList);
        if (countError <= 0) {
            setSpasswordLoader(true);
            let result = await postReq('change-secondary-password', sPassword, sessionStorage.getItem('token'));
            if (result.errors) {
                let err = Object.values(result.errors);
                err.map((data) => notifyError(data[0]));
            }
            if (result.msg || result.message && result.status) {
                notifySuccess(result.message || result.msg);
                setRefrashPage(true);
                setSpassword({ "old_password": "", "spassword": "", "spassword_confirmation": "" });
                // sessionStorage.clear();
                // window.history.replaceState(null, null, "/");
                // navigate('/', { replace: true });
            }
            if (result.message || result.msg && result.status === false) {
                notifyError(result.message);
            }
        }
        setSpasswordLoader(false);
    }
    function handleProfileString(e, key) {
        let value = e.target.value
        value = value.replace(/[^A-Za-z\s]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfileStrNum(e, key) {
        let value = e.target.value
        value = value.replace(/[^A-Za-z0-9]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfileEmail(e, key) {
        let value = e.target.value
        value = value.replace(/[^A-Za-z0-9@.]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfileNum(e, key) {
        let value = e.target.value
        value = value.replace(/[^0-9]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfilePhone(e, key) {
        let value = e.target.value
        value = value.replace(/[^0-9+]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfileAddress(e, key) {
        let value = e.target.value
        value = value.replace(/[^A-Za-z0-9/\-,]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    }
    function handle2faNum(e, key) {
        let value = e.target.value
        value = value.replace(/[^0-9]/ig, '');
        let temp = { ...twofa }
        temp[key] = value
        setTwofa(temp);
    };
    function handleSecNum(e, key) {
        let value = e.target.value
        value = value.replace(/[^0-9]/ig, '');
        let temp = { ...sPassword }
        temp[key] = value
        setSpassword(temp);
    };
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">Profile Setting</h1>
            </div>

            {!profile&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {profile&&<div className="card card-body mb-4">

                <div className="d-flex align-items-center flex-row flex-wrap">
                    
                    <img className="img-5x rounded-circle" src={tempPhoto || (profile.avatar && `${process.env.REACT_APP_IMG_URL}/${profile.avatar}`) || IMAGES.Avatar} alt="" />
            
                    <div className="ms-3 text-white">
                        <h5 className="mb-1">{username}</h5>
                        <h6 className="m-0 fw-light">{profile.email}</h6>
                    </div>
                    <div className="ms-auto text-white">
                        <p className="mb-1">
                            Email : {profile.email_verified_at?'Verified':'Not Verified'}
                        </p>
                        <p className="mb-1">
                            Phone : {profile.phone||'-'}
                        </p>
                    </div>
                  
                </div>

            </div>}



            {profile&&<div className="card">
                <div className="card-body">
                    <div className="profile">
                        <div className="profile-container">
                           
                            <div className="profile-content">
                            <Tab.Container activeKey={activeTab.toLowerCase()} defaultActiveKey={"Profile".toLowerCase()}>
                                <Nav as="ul" className="nav nav-pills mb-3">
                                    <Nav.Item as="li" onClick={()=>setActiveTab('Profile'.toLowerCase())}>
                                        <Nav.Link eventKey={"Profile".toLowerCase()}>
                                            {"Profile"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" onClick={()=>setActiveTab('2fa'.toLowerCase())}>
                                        <Nav.Link eventKey={"2fa".toLowerCase()}>
                                            {"2FA Setting"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" onClick={()=>setActiveTab('Change Password'.toLowerCase())}>
                                        <Nav.Link eventKey={"Change Password".toLowerCase()}>
                                            {"Password"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" onClick={()=>setActiveTab('Sec Change Password'.toLowerCase())}>
                                        <Nav.Link eventKey={"Sec Change Password".toLowerCase()}>
                                            {"MPIN"}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item as="li" onClick={()=>setActiveTab('Change Authentication'.toLowerCase())}>
                                        <Nav.Link eventKey={"Change Authentication".toLowerCase()}>
                                            {"Authentication"}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"Profile".toLowerCase()}>
                                        {profile && <div className="profile-content-container">
                                            
                                            <div className="">
                                                <div className="card profile-card card-bx m-b30">
                                                    <div className="card-header">
                                                        <h6 className="card-title">Profile Setup</h6>
                                                    </div>
                                                    <form className="profile-form">
                                                        <div className="card-body">
                                                        <div className="form-group mb-3">
                                                            <input type="file" accept="image/png,image/jpeg" className="update-flie form-control" onChange={(e) => { updateImage(e.target.files[0]); setTempPhoto(e.target.files[0] && URL.createObjectURL(e.target.files[0])) }} />
                                                        </div>
                                                            <div className="row">
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group">
                                                                        <label className="mb-1">Username <span className="required">*</span></label>
                                                                        <input type="text" name="address_line1" className="form-control" value={profile.username} onChange={(e) => handleProfileStrNum(e, 'username')} required={true} />
                                                                    </div>
                                                                    {(profile.username == '' && profileError.username) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">Name <span className="required">*</span></label>
                                                                        <input type="text" name="address_line1" className="form-control" value={profile.name} onChange={(e) => handleProfileString(e, 'name')} required={true} />
                                                                    </div>
                                                                    {(profile.name == '' && profileError.name) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">Email <span className="required">*</span></label>
                                                                        <input type="email" name="address_line1" className="form-control" value={profile.email} onChange={(e) => handleProfileEmail(e, 'email')} required={true} />
                                                                    </div>
                                                                    {(profile.email == '' && profileError.email) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <label className="mb-1">Gender <span className="required">*</span></label>
                                                                    <select className="form-control" name='gender' value={profile.gender} onChange={(e) => setProfile({ ...profile, 'gender': e.target.value })} required={true}>
                                                                        <option value={'female'}>Female</option>
                                                                        <option value={'male'}>Male</option>
                                                                    </select>
                                                                    {(profile.gender == '' && profileError.gender) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <label className="mb-1">Date of birth <span className="required">*</span></label>
                                                                    <input type="date" name="dob" className="form-control" value={profile.dob !== null && profile.dob?.split(' ')[0]} onChange={(e) => setProfile({ ...profile, 'dob': e.target.value })} placeholder="dob" required={true} />
                                                                    {(profile.dob == '' && profileError.dob) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">Address Line 1 <span className="required">*</span></label>
                                                                        <input type="text" name="address_line1" className="form-control" value={profile.address_line1} onChange={(e) => handleProfileAddress(e, 'address_line1')} required={true} />
                                                                    </div>
                                                                    {(profile.address_line1 == '' && profileError.address_line1) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">Address Line 2</label>
                                                                        <input type="text" name="address_line2" className="form-control" value={profile.address_line2} onChange={(e) => handleProfileAddress(e, 'address_line2')} required={true} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">Landmark <span className="required">*</span></label>
                                                                        <input type="text" name="landmark" className="form-control" value={profile.landmark} onChange={(e) => handleProfileString(e, 'landmark')} required={true} />
                                                                    </div>
                                                                    {(profile.landmark == '' && profileError.landmark) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">City <span className="required">*</span></label>
                                                                        <input type="text" name="city" className="form-control" value={profile.city} onChange={(e) => handleProfileString(e, 'city')} required={true} />
                                                                    </div>
                                                                    {(profile.city == '' && profileError.city) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">State <span className="required">*</span></label>
                                                                        <input type="text" name="state" className="form-control" value={profile.state} onChange={(e) => handleProfileString(e, 'state')} required={true} />
                                                                    </div>
                                                                    {(profile.state == '' && profileError.state) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">Country <span className="required">*</span></label>
                                                                        <div className="form-group">
                                                                            <select name="select_box" className="form-select form-control" value={countryCode && profile.country && countryCode[parseInt(profile.country) - 1].id} onChange={(e) => setProfile({ ...profile, 'country': e.target.value, 'phone': e.target[e.target.selectedIndex].getAttribute('data-code') })} required={true}>
                                                                                <option value="">Select Country</option>
                                                                                {
                                                                                    countryCode && countryCode.map((data, idx) =>
                                                                                        <option value={data.id} data-code={data.calling_code} key={idx}>{data.name}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {(profile.country == '' && profileError.country) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">Phone <span className="required">*</span></label>
                                                                        <input type="phone" name="phone" className="form-control" value={profile.phone} onChange={(e) => { handleProfilePhone(e, 'phone') }} required={true} />
                                                                    </div>
                                                                    {(profile.phone == '' && profileError.phone) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">Pincode <span className="required">*</span></label>
                                                                        <input type="text" name="pincode" className="form-control" value={profile.pincode} onChange={(e) => handleProfileNum(e, 'pincode')} required={true} />
                                                                    </div>
                                                                    {(profile.pincode == '' && profileError.pincode) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">Tax Id</label>
                                                                        <input type="text" name="tax_id" className="form-control" value={profile.tax_id} onChange={(e) => handleProfileStrNum(e, 'tax_id')} required={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button className="btn btn-primary" onClick={updateProfileFun} disabled={profileLoader}>
                                                                UPDATE
                                                                {profileLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>}
                                                            </button>
                                                        </div>
                                                        
                                                    </form>
                                                    <div className="card-arrow">
                                                        <div className="card-arrow-top-left"></div>
                                                        <div className="card-arrow-top-right"></div>
                                                        <div className="card-arrow-bottom-left"></div>
                                                        <div className="card-arrow-bottom-right"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"2fa".toLowerCase()}>
                                        <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title">Enable/Disable 2fa</h6>
                                                </div>
                                                <div className='d-flex justify-content-around'>
                                                    <div className="card-body">
                                                        <div className="mb-4">
                                                            <label className="mb-1">2fa Code <span className="required">*</span></label>
                                                            <input type="text" name="code" className="form-control" value={twofa.code} onChange={(e) => handle2faNum(e,'code')} placeholder="First Enter Your Code" required />
                                                            {(twofa.code == '' && twofaError.code) && <span className="required">This Field Is Required.</span>}
                                                        </div>
                                                        <div className="">
                                                            <button className="btn btn-primary" onClick={ed2fa} disabled={twofaLoader}>
                                                                {status_2fa ? "Disable" : "Enable"} 2FA
                                                                {twofaLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    {!status_2fa && <img src={process.env.REACT_APP_2FA+image_2fa} alt='' />}
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                            </div>
                                            </div>
                                           
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"Change Password".toLowerCase()}>
                                        {profile && <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title">Change Password</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-3">
                                                        <label className="mb-1">Current Password</label>
                                                        <div>
                                                            <input type="password" className="form-control" placeholder="Enter Current password" value={password.currentpassword} onChange={(e) => setPassword({ ...password, 'currentpassword': e.target.value })} />
                                                            {(password.currentpassword == '' && passwordError.currentpassword) && <span className="required">This Field Is Required.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mb-1">New Password</label>
                                                        <div>
                                                            <input type="password" className="form-control" placeholder="Enter new password" value={password.newpassword} onChange={(e) => setPassword({ ...password, 'newpassword': e.target.value })} />
                                                            {(password.newpassword == '' && passwordError.newpassword) && <span className="required">This Field Is Required.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mb-1">Confirm Password</label>
                                                        <div>
                                                            <input type="text" className="form-control" placeholder="Enter confirm password" value={password.confpassword} onChange={(e) => setPassword({ ...password, 'confpassword': e.target.value })} />
                                                            {showPass && password.newpassword != password.confpassword && <div className="required text-danger fs-12">Password not matching with new password</div>}
                                                            {(password.confpassword == '' && passwordError.confpassword) && <span className="required">This Field Is Required.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-primary" onClick={changePassword} disabled={passwordLoader}>
                                                            UPDATE PASSWORD
                                                            {passwordLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                            </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"Sec Change Password".toLowerCase()}>
                                        {profile && <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title">Change MPIN</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="mb-3">
                                                        <label className="mb-1">Enter Current Password</label>
                                                        <div>
                                                            <input type="password" className="form-control" placeholder="Enter Current Password" value={sPassword.old_password} onChange={(e) => setSpassword({ ...sPassword, 'old_password': e.target.value })} />
                                                            {(sPassword.old_password == '' && sPasswordError.old_password) && <span className="required">This Field Is Required.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mb-1">New MPIN</label>
                                                        <div>
                                                            <input type="text" className="form-control" maxLength={4} placeholder="Enter New 4 digit MPIN" value={sPassword.spassword} onChange={(e) => handleSecNum(e, 'spassword')} />
                                                            {(sPassword.spassword == '' && sPasswordError.spassword) && <span className="required">This Field Is Required.</span>}
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="mb-1">Confirm MPIN</label>
                                                        <div>
                                                            <input type="text" className="form-control" maxLength={4} placeholder="Confirm New MPIN" value={sPassword.spassword_confirmation} onChange={(e) => handleSecNum(e, 'spassword_confirmation')} />
                                                            {(sPassword.spassword_confirmation == '' && sPasswordError.spassword_confirmation) && <span className="required">This Field Is Required.</span>}
                                                            {sPassword.spassword !== sPassword.spassword_confirmation && <div className="text-danger fs-12">Password not matching with new password</div>}
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-primary" onClick={changeSPassword} disabled={sPasswordLoader}>
                                                            UPDATE SECONDARY PASSWORD
                                                            {sPasswordLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                            </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                                <Tab.Content>
                                    <Tab.Pane eventKey={"Change Authentication".toLowerCase()}>
                                        {profile && <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title">Change Authentication</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="">
                                                        <div className="form-group mb-3">
                                                            <label className="mb-1">Authentication <span className="required">*</span></label>
                                                            <select className="form-control" value={authType.type} onChange={(e) => setAuthType({ ...authType, 'type': e.target.value })}>
                                                                <option value={''}>Select Authentication Type</option>
                                                                <option value={'mpin'}>MPIN</option>
                                                                <option value={'authenticator'}>Authenticator</option>
                                                                <option value={'otp'}>Otp</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-primary" onClick={changeAuthentication} disabled={passwordLoader}>
                                                            UPDATE AUTHENTICATION
                                                            {passwordLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                            </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                               
                            </div>
                        </div>
                    </div>
                </div>

                
            </div>}



          
        </Frame>
    )
}
