import React, { useContext, useEffect, useRef, useState } from 'react';
import {Col,Card,Table, Spinner,Dropdown} from "react-bootstrap";
import { getReq,getTable, postReq } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import DownloadExcel from '../../parts/DownloadExcel';
import MoreDetails from '../../parts/MoreDetails';
import moment from 'moment/moment';
import { IMAGES } from '../../constant/theme';
import Frame from "../../components/frame";

export default function ManualWithdrawList() {
    const navigate = useNavigate();
    const tableRef =  useRef(null);
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [withdrawData,setWithdrawData] = useState();
    const [refrashPage,setRefrashPage] = useState(false);
    const [searchBy,setSearchBy] = useState('');
    const [searchByText,setSearchByText] = useState('');
    const [loader,setLoader] = useState(false);
    const [showApproveModel,setShowApproveModel] = useState(false);
    const [showRejectModel,setShowRejectModel] = useState(false);
    const [modelApproveData,setModelApproveData] = useState({'code':''});
    const [modelRejectData,setModelRejectData] = useState({'code':'','reason':''});
    const [moreDetails,setMoreDetails] = useState(null);
    let countTable = 0;
    const [filterShow,setFilterShow] = useState(false);
    let durationanim = 0.15;
    let AmountSymbol = '$';
    const list=[
        {'label':'Username','data':'username','type':'text'},
        {'label':'Bank Ac No','data':'bank_ac_no','type':'number'},
        {'label':'Status','data':'status','type':'option','value':[{'key':'Approved','value':'1'},{'key':'Rejected','value':'2'},{'key':'Pending','value':'0'}]}
    ]
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/manualWithdrawList');
        setCollapseId(4);
        (async()=>{
            try{
                //setSpinner(true);
                let r = await getReq('approval/withdraw',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setWithdrawData(r.data);
                    setTable(r.data.data);
                    setMoreDetails(r.more_details);
                    //setSpinner(false);
                }
                if(r.errors){
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                if(err.message){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                }
                //setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    },[refrashPage]);
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    );
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/approval/withdraw?page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setWithdrawData(r.data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setWithdrawData(r.data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    function depositApprove(code){
        let temp = {...modelApproveData};
        temp.code = code;
        // temp.transactionHash = tran;
        setModelApproveData(temp);
        setShowApproveModel(true);
    }
    function depositReject(code){
        let temp = {...modelRejectData};
        temp.code = code;
        setModelRejectData(temp);
        setShowRejectModel(true);
    }
    async function funApprove(){
        try{
            setLoader(true);
            let r = await postReq('approval/withdraw/approve',modelApproveData,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                notifySuccess(r.message||r.msg);
                setLoader(false);
                setShowApproveModel(false);
                setRefrashPage(true);
            }
            if((r.message||r.msg) && r.status===false){
                notifyError(r.message||r.msg);
                setLoader(false);
            }
            if(r.errors){
                setLoader(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setLoader(false);
            notifyError("Request failed please login again");
        }
    }
    async function funReject(){
        try{
            setLoader(true);
            let r = await postReq('approval/withdraw/reject',modelRejectData,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                notifySuccess(r.message||r.msg);
                setLoader(false);
                setShowRejectModel(false);
                setRefrashPage(true);
            }
            if((r.msg || r.message) && r.status===false){
                notifyError(r.msg||r.message);
                setLoader(false);
            }
            if(r.errors){
                setLoader(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setLoader(false);
            notifyError("Request failed please login again");
        }
    }
    function filterFunData(r){
        setWithdrawData(r.data);
        setTable(r.data.data);
        setMoreDetails(r.more_details);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                    Withdraw List
                    </h1>
                </div>
                <div className="ms-auto">
                    {table&&<DownloadExcel props={{tableRef:tableRef,name:'manualwithdrawlist'}}/>}
                </div>
            </div>

            {moreDetails&&<MoreDetails props={moreDetails}/>}

            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {table&&<div className="card">
                <div className="card-header align-items-center fw-bold small d-flex">
                    <span className="flex-grow-1">Manual Withdraw List</span>
                    <span className="btn btn-outline-light btn-sm" onClick={()=>setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div>
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                    <Table responsive style={{textAlign:'center'}} ref={tableRef}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>username</strong>
                                </th>
                                <th>
                                    <strong>Code</strong>
                                </th>
                                <th>
                                    <strong>Method</strong>
                                </th>
                                <th>
                                    <strong>Account Details</strong>
                                </th>
                                <th>
                                    <strong>Amount From</strong>
                                </th>
                                <th>
                                    <strong>Amount To</strong>
                                </th>
                                <th>
                                    <strong>Net Amount</strong>
                                </th>
                                <th>
                                    <strong>Process At</strong>
                                </th>
                                <th>
                                    <strong>Action</strong>
                                </th>
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>{
                                data.status===0?countTable +=1:countTable +=0;
                                return data.status===0 &&
                                <motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                                    <td>{data.username}</td>
                                    <td>
                                        <div style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",width:"15rem"}}>
                                            {data.code}
                                        </div>
                                    </td>
                                    <td>{data.coin_type}</td>
                                    <td>
                                        {/* {data.coin_type=='bank'&&<div style={{display:'flex',flexDirection:'column'}}>
                                            <div><div><div style={{fontWeight:'600'}}>{'Bank Name - '}</div>{data.bank_name}</div></div>
                                            <div><div><div style={{fontWeight:'600'}}>{'Account No. - '}</div>{data.bank_ac_no}</div></div>
                                            <div><div><div style={{fontWeight:'600'}}>{'Account Holder Name - '}</div>{data.bank_holder_name}</div></div>
                                            <div><div><div style={{fontWeight:'600'}}>{'IFSC - '}</div>{data.bank_ifsc_code}</div></div>
                                        </div>}
                                        {data.coin_type=='crypto'&&<div style={{display:'flex',flexDirection:'column'}}>
                                            <div><div><div style={{fontWeight:'600'}}>{'Network - '}</div>{data.network}</div></div>
                                            <div><div><div style={{fontWeight:'600'}}>{'Account - '}</div>{data.receiving_account}</div></div>
                                        </div>} */}
                                        <div style={{display:'flex',flexDirection:'column'}}>
                                            <div><div><div style={{fontWeight:'600'}}>{'Network - '}</div>{data.network}</div></div>
                                            <div><div><div style={{fontWeight:'600'}}>{'Account - '}</div>{data.address}</div></div>
                                        </div>
                                    </td>
                                    <td>{AmountSymbol} {parseFloat(data.amount_from)}</td>
                                    <td>{AmountSymbol} {parseFloat(data.amount_to)}</td>
                                    <td>{AmountSymbol} {parseFloat(data.net_amount)}</td>
                                    <td>{data.process_at&&moment(data.process_at).format('YYYY-MM-DD')||'-'}</td>
                                    <td>
                                        <div className='d-flex justify-content-between align-items-center gap-2'>
                                            <span className="badge light btn btn-danger" style={{cursor:data.is_admin_approve==0?'pointer':'not-allowed'}} onClick={()=>{data.is_admin_approve==0&&depositReject(data.code)}}>Reject</span>
                                            <span className="badge light btn btn-success" style={{cursor:data.is_admin_approve==0?'pointer':'not-allowed'}} onClick={()=>{data.is_admin_approve==0&&depositApprove(data.code)}}>Approve</span>
                                        </div>
                                    </td>
                                </motion.tr>})
                            }
                        </tbody>}
                        {
                            countTable===0&& <div style={{display:'table-caption',marginTop:'1rem',textAlign:'center'}}>No Data For Action!!!</div>
                        }
                    </Table>
                    {table&&
                    <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                        <p className="h6">
                            Showing <span className="font-medium">{withdrawData.from||'-'}</span> to <span className="font-medium">{withdrawData.to||'-'}</span> of{' '}
                            <span className="font-medium">{withdrawData.total}</span> results
                        </p>
                        <div className="d-flex">
                           <nav aria-label="Page navigation">
                                <ul className="pagination mb-0">
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(withdrawData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link cursor-pointer">&laquo;</span>
                                            
                                        </a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}}>
                                        <a className="page-link">{withdrawData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(withdrawData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
                </div>
                
            </div>}
        
        
        
        {filterShow&&<Filter props={{setFilterShow,url:`${process.env.REACT_APP_BASE_URL}/approval/withdraw`,list,filterFunData}}/>}
       
        {showApproveModel&&
            // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{background:'rgba(255,255,255,0.15)',backdropFilter:'blur(1px)'}}>
                    <Modal.Header closeButton onClick={()=>setShowApproveModel(false)}>
                        <Modal.Title>Approve Withdraw</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div>
                        <div className="mb-2">
                            <label className="mb-1">Code</label>
                            <input type="text" className="form-control" value={modelApproveData.code} onChange={(e)=>setModelApproveData({...modelApproveData,'code':e.target.value})} disabled={true}/>
                        </div>
                        {/* <div className="mb-2">
                            <label className="mb-1">Transaction Hash</label>
                            <input type="text" className="form-control" value={modelApproveData.transactionHash} onChange={(e)=>setModelApproveData({...modelApproveData,'transactionHash':e.target.value})}/>
                        </div> */}
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>setShowApproveModel(false)}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={funApprove}>
                            Approve
                            {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            // </div>
        }
        {showRejectModel&&
            // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{background:'rgba(255,255,255,0.15)',backdropFilter:'blur(1px)'}}>
                    <Modal.Header closeButton onClick={()=>setShowRejectModel(false)}>
                        <Modal.Title>Reject Withdraw</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div>
                        <div className="mb-2">
                            <label className="mb-1">Reason</label>
                            <input type="text" className="form-control" value={modelRejectData.reason} onChange={(e)=>setModelRejectData({...modelRejectData,'reason':e.target.value})}/>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>setShowRejectModel(false)}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={funReject}>
                            Reject
                            {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            // </div>
        }
    </Frame>
    )
}

