import axios from "axios";

export async function login(data,token) {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
    let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/login`,data,{headers});
    return await result.data;
}

export async function prelogin(data) {
    const headers = {
        'Content-Type': 'application/json',
    };
    let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/pre-login`,data,{headers});
    return await result.data;
}

export async function register(data) {
    const headers = {
        'Content-Type': 'application/json',
    };
    let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/register`,data,{headers});
    return await result.data;
}

export async function verifyEmail(data){
    const headers = {
        'Content-Type': 'application/json',
    };
    let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/verify-email`,data,{headers});
    return await result.data;
}

export async function verifyOtp(data){
    const headers = {
        'Content-Type': 'application/json',
    };
    let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/verify-otp`,data,{headers});
    return await result.data;
}

export async function sendOtp(data){
    const headers = {
        'Content-Type': 'application/json',
    };
    let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/requestotp`,data,{headers});
    return await result.data;
}

export async function forgetPassword(data){
    const headers = {
        'Content-Type': 'application/json',
    };
    let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/forget-password`,JSON.stringify(data),{headers});
    return await result.data;
}

export async function logout(token){
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
    let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/logout`,{},{headers});
    return await result.data;
}

export async function logoutall(token){
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
    };
    let result = await axios.post(`${process.env.REACT_APP_BASE_URL}/logout-all`,{},{headers});
    return await result.data;
}

export async function postReq(url,data,token){
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept':'application/json',
    };
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${url}`, data, {headers});
    return await response.data;
}

export async function getReq(url,token){
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept':'application/json',
    };
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/${url}`, {headers});
    return await response.data;
}

export async function postTable(url,data,token){
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept':'application/json',
    };
    const response = await axios.post(url, data, {headers});
    return await response.data;
}

export async function getTable(url,token){
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'Accept':'application/json',
    };
    const response = await axios.get(url, {headers});
    return await response.data;
}

export async function postForm(url,data,token){
    const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
        'Accept':'application/json',
    };
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/${url}`, data, {headers});
    return await response.data;
}
