import React, { useContext, useEffect, useRef, useState } from 'react';
import { SidebarClick } from '../../routes/route';
import {Col,Card,Table, Spinner,Button} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { getReq, getTable, postReq } from '../../requests/request';
import DownloadExcel from '../../parts/DownloadExcel';
import { motion } from "framer-motion";
import { notifyError, notifySuccess } from '../../parts/Toast';
import Modal from 'react-bootstrap/Modal';
import CheckFormError from '../../parts/FormError';
import Frame from "../../components/frame";
import moment from 'moment';

export default function RankSetting() {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [rankData,setRankData] = useState('');
    const [table,setTable] = useState();
    const [refrashPage,setRefrashPage] = useState(false);
    const [showUpdate,setShowUpdate] = useState(false);
    const [updateData,setUpdateData] = useState({
        name: "",
        team_business:"",
        salary_amount:"",
        salary_duration:"",
        salary_duration_type:'',
        //direct_count:"",
        rank_count:"",
        rewards:"",
        //rank_id_required:""
    });
    const [updateError,setUpdateError] = useState({});
    const [showAdd,setShowAdd] = useState(false);
    const [addData,setAddData] = useState({
        name: "",
        team_business:"",
        salary_amount:"",
        salary_duration:"",
        salary_duration_type:'',
        //direct_count:"",
        rank_count:"",
        rewards:"",
        //rank_id_required:""
    });
    const [addError,setAddError] = useState({});
    const [loader,setLoader] = useState(false);
    const [updateId,setUpdateId] = useState(null);
    const [rankLoader,setRankLoader] = useState(false);
    const [rankId,setRankId] = useState(null);
    const [exportData,setExportData] = useState('');
    const [moreDetails,setMoreDetails] = useState(null);
    const [showWarnnig,setShowWarnnig] = useState(false);
    const [removeRankLoader,setRemoveRankLoader] = useState(false);
    let countTable = 0;
    let durationanim = 0.15;
    let AmountSymbol = '$';
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/rankSetting');
        setCollapseId(13);
        (async()=>{
            try{
                setSpinner(true);
                let r = await getReq('rank/index',sessionStorage.getItem('token'))
                if(r.status=="success" || r.status==true){
                    setRankData(r.data);
                    setExportData(r.export_data);
                    setMoreDetails(r.more_details);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                if(err.message){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                }
                setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    },[refrashPage]);
    async function getRank(id){
        setSpinner(true);
        setUpdateId(id);
        setRankLoader(true);
        setRankId(id);
        let r = await getReq(`rank/index?id=${id}`,sessionStorage.getItem('token'))
        if(r.status=="success" || r.status==true){
            setUpdateData(r.data.data[0]);
            setShowUpdate(true);
        }
        if(r.errors){
            let err = Object.values(r.errors);
            notifyError(err[0][0]);
        }
        setSpinner(false);
        setRankLoader(false);
        setRankId(null);
    }
    async function updatePool(){
        let temp = {...updateData};
        delete temp.description;
        delete temp.rewards;
        delete temp.rank_id_required;
        let { errList, countError } = CheckFormError({ ...temp });
        setUpdateError(errList);
        if (countError <= 0) {
            setLoader(true);
            let r = await postReq(`rank/${updateId}/update`,updateData, sessionStorage.getItem('token'))
            if(r.status=="success" || r.status==true){
                notifySuccess(r.message||r.msg);
                setShowUpdate(false);
                setRefrashPage(true);
            }
            if(r.status==false&&r.msg){
                notifyError(r.msg);
            }
            if(r.errors){
                let err = Object.values(r.errors);
                notifyError(err[0][0]);
            }
            setLoader(false);
        }
    }
    async function addPool(){
        let temp = {...addData};
        delete temp.description;
        delete temp.rewards;
        let { errList, countError } = CheckFormError({ ...temp });
        setAddError(errList);
        if (countError <= 0) {
            setLoader(true);
            let r = await postReq(`rank/create`,addData, sessionStorage.getItem('token'))
            if(r.status=="success" || r.status==true){
                notifySuccess(r.message||r.msg);
                setShowAdd(false);
                setAddData({ name: "",team_business:"",salary_amount:"",salary_duration:"",salary_duration_type:'',rank_count:"" });
                setRefrashPage(true);
            }
            if(r.status==false&&r.msg){
                notifyError(r.msg);
            }
            if(r.errors){
                let err = Object.values(r.errors);
                notifyError(err[0][0]);
            }
            setLoader(false);
        }
    }
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/approval/deposit?export=true&page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setRankData(r.data);
                setExportData(r.export_data);
                setMoreDetails(r.more_details);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setRankData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setMoreDetails(r.more_details);
                    setSpinner(false);
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function removeRank(){
        setSpinner(true);
        let r = await getReq(`rank/${rankId}/destroy`,sessionStorage.getItem('token'))
        if(r.status=="success" || r.status==true){
            notifySuccess(r.message||r.msg);
            setSpinner(false);
            setRefrashPage(true);
            setShowWarnnig(false);
        }
        if(r.status==false){
            notifyError(r.message||r.msg);
            setSpinner(false);
            setShowWarnnig(false);
        }
        if(r.errors){
            setSpinner(false);
            let err = Object.values(r.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
            setShowWarnnig(false);
        }
    }
    async function remRank(id){
        setRankId(id);
        setShowWarnnig(true);
    }
    function filterFunData(r){
        setRankData(r.data);
        setExportData(r.export_data);
        setTable(r.data.data);
        setMoreDetails(r.more_details);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                        Rank Setting
                    </h1>
                </div>
                {/* <div className="ms-auto">
                    {table&&<DownloadExcel props={{exportData: exportData,name:'ranklist.xlsx'}}/>}
                </div> */}
            </div>

            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {table&&<div className="card">
                <div className="card-header fw-bold small">Rank List</div>
                <div className="card-body">
                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <span className='btn btn-outline-warning mb-3' onClick={()=>setShowAdd(true)}>Add Rank</span>
                    </div>
                    <Table responsive style={{textAlign:'center'}} ref={tableRef}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Name</strong>
                                </th>
                                {/* <th>
                                    <strong>Business Volume</strong>
                                </th> */}
                                {/* <th>
                                    <strong>Percentage(%)</strong>
                                </th> */}
                                <th>
                                    <strong>Team Business({process.env.REACT_APP_Amount_Symbol})</strong>
                                </th>
                                {/* <th>
                                    <strong>Rank Id Required</strong>
                                </th> */}
                                <th>
                                    <strong>Rank Count</strong>
                                </th>
                                {/* <th>
                                    <strong>Direct Count</strong>
                                </th> */}
                                <th>
                                    <strong>Salary Amount({process.env.REACT_APP_Amount_Symbol})</strong>
                                </th>
                                <th>
                                    <strong>Salary Duration(month)</strong>
                                </th>
                                <th>
                                    <strong>Rewards</strong>
                                </th>
                                <th>
                                    <strong>Created At</strong>
                                </th> 
                                <th>
                                    <strong>Action</strong>
                                </th>
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>{
                                    countTable += 1
                                    return(<motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                                        <td>{data.name}</td>
                                        {/* <td>{process.env.REACT_APP_Amount_Symbol} {data.cp_required}</td> */}
                                        {/* <td>{parseFloat(data.percentage)}</td> */}
                                        <td>{parseFloat(data.team_business)||'-'}</td>
                                        {/* <td>{data.rank_id_required||'0'}</td> */}
                                        <td>{data.rank_count||'0'}</td>
                                        {/* <td>{data.direct_count||'0'}</td> */}
                                        <td>{parseFloat(data.salary_amount)||'0'}</td>
                                        <td>{data.salary_duration||'0'}</td>
                                        <td>{data.rewards||'-'}</td>
                                        <td>{moment(data.created_at).format('DD/MM/yyyy')}</td>
                                        <td>
                                            <div style={{display:'flex',gap:'6px',justifyContent:'center'}}>
                                                <button type="button" className="btn btn-primary btn-sm" style={{cursor:`${data.status==1?'pointer':'not-allowed'}`}} onClick={() =>data.status==1&&getRank(data.id)}>
                                                    Update
                                                    {rankLoader && data.id === rankId && <div className="spinner-border" style={{ width: '1rem', marginLeft: '10px', height: '1rem' }} role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>}
                                                </button>
                                                <button type="button" className="btn btn-danger btn-sm" style={{cursor:`${data.status==1?'pointer':'not-allowed'}`}} onClick={() =>data.status==1&&remRank(data.id)}>
                                                    Remove
                                                    {removeRankLoader && data.id === rankId && <div className="spinner-border" style={{ width: '1rem', marginLeft: '10px', height: '1rem' }} role="status">
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>}
                                                </button>
                                            </div>
                                        </td>
                                    </motion.tr>)
                                })
                            }
                        </tbody>}
                        {
                            countTable==0&& <div style={{display:'table-caption',marginTop:'1rem',textAlign:'center'}}>No Data For Action!!!</div>
                        }
                    </Table>
                    {table&&
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className="h6">
                            Showing <span className="font-medium">{rankData.from||'-'}</span> to <span className="font-medium">{rankData.to||'-'}</span> of{' '}
                            <span className="font-medium">{rankData.total}</span> results
                        </p>
                        <div className="d-flex">
                           
                            <nav aria-label="Page navigation">
                                <ul className="pagination p-0">
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(rankData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link cursor-pointer">&laquo;</span>
                                        </a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}}>
                                        <a className="page-link">{rankData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(rankData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
                </div>
                
            </div>}

        {
            showUpdate &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header closeButton onClick={() => { setShowUpdate(false); setUpdateData({ name: "",team_business:"",salary_amount:"",salary_duration:"",salary_duration_type:'',rank_count:""}); setUpdateError({}) }}>
                        <Modal.Title>Update Rank</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Name</label>
                                <input type="text" className="form-control" value={updateData.name} onChange={(e) => setUpdateData({ ...updateData, 'name': e.target.value })} />
                                {(updateData.name == "" && updateError.name) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        {/* <div>
                            <div className="mb-2">
                                <label className="mb-1">Business Volume({process.env.REACT_APP_Amount_Symbol})</label>
                                <input type="text" className="form-control" value={updateData.cp_required||0} onChange={(e) => setUpdateData({ ...updateData, 'cp_required': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(updateData.cp_required == "" && updateError.cp_required) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div> */}
                        {/* <div>
                            <div className="mb-2">
                                <label className="mb-1">Percentage(%)</label>
                                <input type="text" className="form-control" value={parseFloat(updateData.percentage||0)} onChange={(e) => setUpdateData({ ...updateData, 'percentage': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(updateData.percentage == "" && updateError.percentage) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div> */}
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Team Business</label>
                                <input type="number" className="form-control" value={updateData.team_business?parseFloat(updateData.team_business):updateData.team_business} onChange={(e) => setUpdateData({ ...updateData, 'team_business': e.target.value })} />
                                {(updateData.team_business == "" && updateError.team_business) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        {/* <div>
                            <div className="mb-2">
                                <label className="mb-1">Rank Id Required</label>
                                <input type="number" className="form-control" value={updateData.rank_id_required} onChange={(e) => setUpdateData({ ...updateData, 'rank_id_required': e.target.value })} />
                                {(updateData.rank_id_required == "" && updateError.rank_id_required) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div> */}
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Rank Count</label>
                                <input type="number" className="form-control" value={updateData.rank_count} onChange={(e) => setUpdateData({ ...updateData, 'rank_count': e.target.value })} />
                                {(updateData.rank_count == "" && updateError.rank_count) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>

                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Salary Amount</label>
                                <input type="text" className="form-control" value={parseFloat(updateData.salary_amount)} onChange={(e) => setUpdateData({ ...updateData, 'salary_amount': e.target.value })} />
                                {(updateData.salary_amount == "" && updateError.salary_amount) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Salary Duration</label>
                                <input type="text" className="form-control" value={parseFloat(updateData.salary_duration)} onChange={(e) => setUpdateData({ ...updateData, 'salary_duration': e.target.value })} />
                                {(updateData.salary_duration == "" && updateError.salary_duration) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Salary Duration Type</label>
                                <select className="form-select form-select-lg" value={updateData.salary_duration_type} onChange={(e) => setUpdateData({ ...updateData, 'salary_duration_type': e.target.value })}>
                                    <option value=''>Select Type</option>
                                    <option value="month">Month</option>
                                    {/* <option value="days">Day</option> */}
                                </select>
                                {(updateData.salary_duration_type == "" && updateError.salary_duration_type) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Rewards</label>
                                <input type="text" className="form-control" value={updateData.rewards} onChange={(e) => setUpdateData({ ...updateData, 'rewards': e.target.value })} />
                                {(updateData.rewards == "" && updateError.rewards) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowUpdate(false); setUpdateData({ name: "",team_business:"",salary_amount:"",salary_duration:"",salary_duration_type:'',rank_count:"" }); setUpdateError({}) }}>Close</Button>
                        <Button onClick={updatePool} className='d-flex justify-content-center align-items-center gap-2' variant="primary">
                                Update Rank
                            {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
            {
            showAdd &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header closeButton onClick={() => { setShowAdd(false); setAddData({ name: "",team_business:"",salary_amount:"",salary_duration:"",salary_duration_type:'',rank_count:"" }); setAddError({}) }}>
                        <Modal.Title>Add Rank</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Name</label>
                                <input type="text" className="form-control" value={addData.name} onChange={(e) => setAddData({ ...addData, 'name': e.target.value })} />
                                {(addData.name == "" && addError.name) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        {/* <div>
                            <div className="mb-2">
                                <label className="mb-1">Business Volume({process.env.REACT_APP_Amount_Symbol})</label>
                                <input type="text" className="form-control" value={addData.cp_required} onChange={(e) => setAddData({ ...addData, 'cp_required': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(addData.cp_required == "" && addError.cp_required) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div> */}
                        {/* <div>
                            <div className="mb-2">
                                <label className="mb-1">Percentage(%)</label>
                                <input type="text" className="form-control" value={addData.percentage} onChange={(e) => setAddData({ ...addData, 'percentage': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(addData.percentage == "" && addError.percentage) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div> */}
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Team Business</label>
                                <input type="text" className="form-control" value={addData.team_business?parseFloat(addData.team_business):addData.team_business} onChange={(e) => setAddData({ ...addData, 'team_business': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(addData.team_business == "" && addError.team_business) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        {/* <div>
                            <div className="mb-2">
                                <label className="mb-1">Rank Id Required</label>
                                <input type="text" className="form-control" value={addData.rank_id_required?parseFloat(addData.rank_id_required):addData.rank_id_required} onChange={(e) => setAddData({ ...addData, 'rank_id_required': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(addData.rank_id_required == "" && addError.rank_id_required) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div> */}
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Rank Count</label>
                                <input type="text" className="form-control" value={addData.rank_count} onChange={(e) => setAddData({ ...addData, 'rank_count': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(addData.rank_count == "" && addError.rank_count) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        {/* <div>
                            <div className="mb-2">
                                <label className="mb-1">Direct Count</label>
                                <input type="text" className="form-control" value={addData.direct_count} onChange={(e) => setAddData({ ...addData, 'direct_count': e.target.value })} />
                                {(addData.direct_count == "" && addError.direct_count) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div> */}
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Salary Amount</label>
                                <input type="text" className="form-control" value={addData.salary_amount} onChange={(e) => setAddData({ ...addData, 'salary_amount': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(addData.salary_amount == "" && addError.salary_amount) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Salary Duration</label>
                                <input type="text" className="form-control" value={addData.salary_duration} onChange={(e) => setAddData({ ...addData, 'salary_duration': e.target.value })} />
                                {(addData.salary_duration == "" && addError.salary_duration) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        {/* <div>
                            <div className="mb-2">
                                <label className="mb-1">Months</label>
                                <input type="text" className="form-control" value={addData.month_count} onChange={(e) => setAddData({ ...addData, 'month_count': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(addData.month_count == "" && addError.month_count) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Monthly Bonus({process.env.REACT_APP_Amount_Symbol})</label>
                                <input type="text" className="form-control" value={addData.per_month_amount} onChange={(e) => setAddData({ ...addData, 'per_month_amount': e.target.value })} />
                                {(addData.per_month_amount == "" && addError.per_month_amount) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div> */}
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Salary Duration Type</label>
                                <select className="form-select form-select-lg" value={addData.salary_duration_type} onChange={(e) => setAddData({ ...addData, 'salary_duration_type': e.target.value })}>
                                    <option value=''>Select Type</option>
                                    <option value="month">Month</option>
                                    {/* <option value="days">Day</option> */}
                                </select>
                                {(addData.salary_duration_type == "" && addError.salary_duration_type) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Rewards</label>
                                <input type="text" className="form-control" value={addData.rewards} onChange={(e) => setAddData({ ...addData, 'rewards': e.target.value })} />
                                {(addData.rewards == "" && addError.rewards) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowAdd(false); setAddData({ name: "",team_business:"",salary_amount:"",salary_duration:"",salary_duration_type:'',rank_count:""}); setAddError({}) }}>Close</Button>
                        <Button onClick={addPool} className='d-flex justify-content-center align-items-center gap-2' variant="primary">
                            Add Rank
                            {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
            {
                showWarnnig&&<Modal className="fade" show={true}>
                <Modal.Header closeButton onClick={()=>setShowWarnnig(false)}>
                    <Modal.Title>Alert</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                    <span style={{fontFamily:'inherit'}}>
                        Are You Sure To Destroy Rank ?
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setShowWarnnig(false)}>Close</Button>
                    <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={()=>{removeRank()}}>
                        Destroy
                    </Button>
                </Modal.Footer>
            </Modal>
            }
        </Frame>
    )
}
