import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Card, Table, Spinner, Dropdown, Button } from "react-bootstrap";
import { getReq, getTable, postReq } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
//import Button from 'react-bootstrap/Button';
import moment from 'moment/moment';
import { IMAGES, SVGICON } from '../../constant/theme';
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import DownloadExcel from '../../parts/DownloadExcel';
import MoreDetails from '../../parts/MoreDetails';
import CheckFormError from '../../parts/FormError';
import Frame from "../../components/frame";
export default function BalanceReportList() {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [spinner, setSpinner] = useState(false);
    const [table, setTable] = useState();
    const [reportListData, setReportListData] = useState();
    const [refrashPage, setRefrashPage] = useState(false);
    const [showTranModel, setShowTranModel] = useState(false);
    const [searchBy, setSearchBy] = useState('');
    const [searchByText, setSearchByText] = useState('');
    const [loader, setLoader] = useState(false);
    const [showCreditModel, setShowCreditModel] = useState(false);
    const [showDebitModel, setShowDebitModel] = useState(false);
    const [modelCreditData, setModelCreditData] = useState({ 'username': '', 'wallet': '', 'amount': '' });
    const [modelCreditDataError, setModelCreditDataError] = useState({});
    const [modelDebitData, setModelDebitData] = useState({ 'username': '', 'wallet': '', 'amount': '' });
    const [modelDebitDataError, setModelDebitDataError] = useState({});
    const [username, setUsername] = useState(null);
    const [tran, setTran] = useState();
    const [moreDetails, setMoreDetails] = useState(null);
    let countTable = 0;
    const [exportData,setExportData] = useState('');
    const [filterShow, setFilterShow] = useState(false);
    const list = [
        { 'label': 'Username', 'data': 'username', 'type': 'text' }
    ]
    let durationanim = 0.15;
    let AmountSymbol = '$';
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/balanceReportList');
        setCollapseId(5);
        (async () => {
            try {
                //setSpinner(true);
                let r = await getReq('balance-report?export=true', sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setReportListData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setMoreDetails(r.more_details);
                    //setSpinner(false);
                }
                if (r.errors) {
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                //if(err.response.status && err.response.status===401||err.response.status===404){
                if (err.message) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                //setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    }, [refrashPage]);
    async function searchTable(e) {
        try {
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/balance-report?export=true&page=${e.target.value}`, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                setReportListData(r.data);
                setExportData(r.export_data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if (r.errors) {
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function nextTable(url) {
        try {
            if (url !== null) {
                setSpinner(true);
                let r = await getTable(url, sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setReportListData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch (err) {
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function funTran(data) {
        setTran(data);
        setShowTranModel(true);
    }
    async function creditFun() {
        setLoader(true);
        let temp = { ...modelCreditData };
        temp['username'] = username;
        let { errList, countError } = CheckFormError({ ...temp });
        setModelCreditDataError(errList);
        if (countError <= 0) {
            setLoader(true);
            try {
                let result = await postReq('balance/credit', temp, sessionStorage.getItem('token'))
                if (result.status == "success" || result.status == true) {
                    notifySuccess(result.message || result.msg);
                    setShowCreditModel(false);
                    setModelCreditData({ 'username': '', 'amount': '' });
                    setLoader(false);
                    setRefrashPage(true);
                }
                if (result.message || result.msg && result.status === false) {
                    notifyError(result.message || result.msg);
                    setLoader(false);
                }
                if (result.errors) {
                    setLoader(false);
                    let err = Object.values(result.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                setLoader(false);
                notifyError(err.message);
            }
        }
        setLoader(false);
    }
    async function debitFun() {
        let temp = { ...modelDebitData };
        temp['username'] = username;
        let { errList, countError } = CheckFormError({ ...temp });
        setModelDebitDataError(errList);
        if (countError <= 0) {
            setLoader(true);
            try {
                let result = await postReq('balance/debit', temp, sessionStorage.getItem('token'))
                if (result.status === "success" || result.status === true) {
                    notifySuccess(result.message || result.msg);
                    setShowDebitModel(false);
                    setModelDebitData({ 'username': '', 'amount': '' });
                    setLoader(false);
                    setRefrashPage(true);
                }
                if (result.message || result.msg && result.status === false) {
                    notifyError(result.message || result.msg);
                    setLoader(false);
                }
                if (result.errors) {
                    setLoader(false);
                    let err = Object.values(result.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                setLoader(false);
                notifyError(err.message);
            }
        }
        setLoader(false);
    }
    function filterFunData(r) {
        setReportListData(r.data);
        setExportData(r.export_data);
        setTable(r.data.data);
        setMoreDetails(r.more_details);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                    Balance Report
                    </h1>
                </div>
                <div className="ms-auto">
                    {table && <DownloadExcel props={{ exportData: exportData, name: 'balanceReport.xlsx' }} />}
                </div>
            </div>
            {moreDetails && <MoreDetails props={moreDetails} />}
            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}
            {table&&<div className="card">
                <div className="card-header align-items-center fw-bold small d-flex">
                    <span className="flex-grow-1">Balance Report</span>
                    <span className="btn btn-outline-light btn-sm" onClick={() => setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div>
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                    <Table responsive style={{ textAlign: 'center' }} ref={tableRef}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>username</strong>
                                </th>
                                <th>
                                    <strong>Pay In</strong>
                                </th>
                                <th>
                                    <strong>Pay Out</strong>
                                </th>
                                <th>
                                    <strong>Credit</strong>
                                </th>
                                <th>
                                    <strong>Action</strong>
                                </th>
                            </tr>
                        </thead>
                        {table && <tbody>
                            {
                                table.map((data, idx) => {
                                    countTable += 1;
                                    return (<motion.tr initial={{ opacity: 0, x: -150 }} whileInView={{ opacity: 1, x: 0 }} transition={{ duration: durationanim += 0.06 }} key={idx}>
                                        <td>{data.username}</td>
                                        <td>{process.env.REACT_APP_Amount_Symbol||'$'} {data.payinbalance && parseFloat(data.payinbalance) || 0}</td>
                                        <td>{process.env.REACT_APP_Amount_Symbol||'$'} {data.payoutbalance && parseFloat(data.payoutbalance) || 0}</td>
                                        <td>{process.env.REACT_APP_Amount_Symbol||'$'} {data.creditbalance && parseFloat(data.creditbalance) || 0}</td>
                                        <td className="d-flex gap-3 justify-content-center align-items-center">
                                            <span style={{ color: '#ffbf31', fontSize: '14px' }} type="button" onClick={() => { setShowCreditModel(true); setUsername(data.username) }}>Credit</span>
                                            <span style={{ color: '#3398fb', fontSize: '14px' }} type="button" onClick={() => { setShowDebitModel(true); setUsername(data.username) }}>Debit</span>
                                        </td>
                                    </motion.tr>)
                                })
                            }
                        </tbody>}
                        {
                            countTable == 0 && <div style={{ display: 'table-caption', marginTop: '1rem', textAlign: 'center' }}>No Data For Action!!!</div>
                        }
                    </Table>
                    {table && <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                        <p className="h6">
                            Showing <span className="font-medium">{reportListData.from||'-'}</span> to <span className="font-medium">{reportListData.to||'-'}</span> of{' '}
                            <span className="font-medium">{reportListData.total}</span> results
                        </p>
                        <div className="d-flex">
                            <nav aria-label="Page navigation">
                                <ul className="pagination mb-0">
                                    <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(reportListData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link  cursor-pointer">&laquo;</span>
                                            
                                        </a>
                                    </li>
                                    <li className="page-item" style={{ cursor: 'pointer' }}>
                                        <a className="page-link">{reportListData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{ cursor: 'pointer' }} onClick={() => nextTable(reportListData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
                </div>
                
            </div>}





            {filterShow && <Filter props={{ setFilterShow, url: `${process.env.REACT_APP_BASE_URL}/balance-report?export=true`, list, filterFunData, showDate: false }} />}
           
            {
                showCreditModel &&
                <div className="modal show" style={{ display: 'block', position: 'absolute', background: 'rgba(255,255,255,0.2)', backdropFilter: 'blur(1px)' }}>
                    <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                        <Modal.Header closeButton onClick={() => { setShowCreditModel(false); setModelCreditData({ 'username': '', 'wallet': '', 'amount': '' }); setModelCreditDataError({}) }}>
                            <Modal.Title>Credit Balance</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div className="mb-2">
                                    <label className="mb-1">Username</label>
                                    <input type="text" className="form-control" value={username} onChange={(e) => setModelCreditData({ ...modelCreditData, 'username': e.target.value })} />
                                    {(modelCreditData.username == '' && modelCreditDataError.username) && <span className="required">This Field Is Required.</span>}
                                </div>
                            </div>
                            <div>
                                <div className="mb-2">
                                    <label className="mb-1">Select Wallet</label>
                                    <select className="form-control" value={modelCreditData.wallet} onChange={(e) => setModelCreditData({ ...modelCreditData, 'wallet': e.target.value })}>
                                        <option defaultValue={''}>Select Wallet</option>
                                        <option value={'pay_in'}>Pay In</option>
                                        <option value={'pay_out'}>Pay Out</option>
                                        <option value={'credits'}>Credits</option>
                                    </select>
                                    {(modelCreditData.wallet == '' && modelCreditDataError.wallet) && <span className="required">This Field Is Required.</span>}
                                </div>
                            </div>
                            <div>
                                <div className="mb-2">
                                    <label className="mb-1">Amount ({process.env.REACT_APP_Amount_Symbol||'$'})</label>
                                    <input type="text" className="form-control" value={modelCreditData.amount} onChange={(e) => setModelCreditData({ ...modelCreditData, 'amount': e.target.value.replace(/[^0-9]/ig, '') })} />
                                    {(modelCreditData.amount == '' && modelCreditDataError.amount) && <span className="required">This Field Is Required.</span>}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => { setShowCreditModel(false); setModelCreditData({ 'username': '', 'wallet': '', 'amount': '' }); setModelCreditDataError({}) }}>Close</Button>
                            <Button onClick={creditFun} className='d-flex justify-content-center align-items-center gap-2' variant="primary">
                                Credit
                                {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
            {
                showDebitModel &&
                // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{ background: 'rgba(255,255,255,0.15)', backdropFilter: 'blur(1px)' }}>
                    <Modal.Header closeButton onClick={() => { setShowDebitModel(false); setModelDebitData({ 'username': '', 'wallet': '', 'amount': '' }); setModelDebitDataError({}) }}>
                        <Modal.Title>Debit Balance</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Username</label>
                                <input type="text" className="form-control" value={username} onChange={(e) => setModelDebitData({ ...modelDebitData, 'username': e.target.value })} />
                                {(modelDebitData.username == '' && modelDebitDataError.username) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Select Wallet</label>
                                <select className="form-control" value={modelDebitData.wallet} onChange={(e) => setModelDebitData({ ...modelDebitData, 'wallet': e.target.value })}>
                                    <option defaultValue={''}>Select Wallet</option>
                                    <option value={'pay_in'}>Pay In</option>
                                    <option value={'pay_out'}>Pay Out</option>
                                    <option value={'credits'}>Credits</option>
                                </select>
                                {(modelDebitData.wallet == '' && modelDebitDataError.wallet) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                        <div>
                            <div className="mb-2">
                                <label className="mb-1">Amount ({process.env.REACT_APP_Amount_Symbol||'$'})</label>
                                <input type="text" className="form-control" value={modelDebitData.amount} onChange={(e) => setModelDebitData({ ...modelDebitData, 'amount': e.target.value.replace(/[^0-9]/ig, '') })} />
                                {(modelDebitData.amount == '' && modelDebitDataError.amount) && <span className="required">This Field Is Required.</span>}
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { setShowDebitModel(false); setModelDebitData({ 'username': '', 'wallet': '', 'amount': '' }); setModelDebitDataError({}) }}>Close</Button>
                        <Button onClick={debitFun} className='d-flex justify-content-center align-items-center gap-2' variant="primary">
                            Debit
                            {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
                // </div>
            }
        </Frame>
    )
}

