import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { notifyError, notifySuccess } from '../parts/Toast';
import { forgetPassword, sendOtp } from '../requests/request';

function ForgetPassword({props}) {
    const {changeShowForgetPassword} = props;
    const [data,setData] = useState({"username":'','otp':'','newpassword':''});
    const [blockOtp,setBlockOtp] = useState(true);
    const [counter,setCounter] = useState(60);
    const [showTimmer,setShowTimmer] = useState(false);
    const [showOption,setShowOption] = useState(false);
    const [loader,setLoader] = useState(false);
	var globalCount = 60;
    async function sendOTP(){
        setBlockOtp(false);
        let result = await sendOtp({'username':data.username,'from':''});
        if((result.message||result.msg) && result.status){
            notifySuccess(result.msg);
            setShowOption(true);
            var OtpInterval = setInterval(function () {
                setCounter((prev)=>prev-1);
                globalCount-=1;
                if(globalCount<=0){
                    setCounter(60);
                    globalCount=60;
                    setShowTimmer(false);
                    setBlockOtp(true);
                    clearInterval(OtpInterval);
                }
            }, 1000);
            setShowTimmer(true);
        }
        if((result.message||result.msg) && result.status==false){
            notifyError(result.message||result.msg);
            setBlockOtp(true);
        }
        if(result.errors){
            let err = Object.values(result.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
            setBlockOtp(true);
        }
    }
    async function ForgetPasswordData(){
        setLoader(true);
        let result = await forgetPassword(data);
        if((result.message||result.msg) && result.status){
            notifySuccess(result.message||result.msg);
            changeShowForgetPassword();
            setData({"username":'','otp':'','newpassword':''});
        }
        if((result.message||result.msg) && result.status===false){
            notifyError(result.message||result.msg);
        }
        if(result.errors){
            let err = Object.values(result.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        setLoader(false);
    }
    function handleUsername(e){
		let value = e.target.value
  		value = value.replace(/[^A-Za-z0-9]/ig, '');
		setData({...data,'username':value});
	};
	function handleOTP(e){
		let value = e.target.value
  		value = value.replace(/[^0-9]/ig, '');
		setData({...data,'otp':value});
	};
    return (
        <div className="modal show" style={{ display: 'block',background:'rgba(0,0,0,0.5)'}}>
            <Modal.Dialog>
                <Modal.Header closeButton onClick={changeShowForgetPassword}>
                <Modal.Title>Forget Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="mb-4">
                    <label className="form-label">Username</label>
                    <input type="text" className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.username} onChange={(e)=>handleUsername(e)} onBlur={()=>sendOTP()} disabled={showOption}/>
                </div>
                {showOption&&<div className="mb-2">
                    <label className="form-label">OTP</label>
                    <input type="text" className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.otp} onChange={(e)=>handleOTP(e)} />
                </div>}
                {showOption&&<div className="mb-4">
                    {!showTimmer&&<span role="button" className='text-primary mb-1' onClick={blockOtp?sendOTP:null}>Click To Send OTP </span>}
                    {showTimmer&&<div className="mb-1"><span style={{color:'var(--primary)'}}>Resend request after {counter} sec</span></div>}
                </div>}
                {showOption&&<div className="mb-4">
                    <label className="form-label">New Password</label>
                    <input type="text" className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.newpassword} onChange={(e)=>setData({...data,'newpassword':e.target.value})} />
                </div>}
                <Button className='btn btn-primary d-block w-100' onClick={ForgetPasswordData} disabled={!showOption}>
                    Save changes{' '}
                    {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
						<span className="visually-hidden">Loading...</span>
					</div>}
                </Button>
                </Modal.Body>
              
            </Modal.Dialog>
        </div>
    );
}

export default ForgetPassword;