import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { sendOtp, verifyEmail } from '../requests/request';
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../parts/Toast';

export default function VerifyEmail() {
    const navigate = useNavigate();
    const [data,setData] = useState({'username':'','otp':''});
    const [otp1,setOtp1] = useState();
    const [otp2,setOtp2] = useState();
    const [otp3,setOtp3] = useState();
    const [otp4,setOtp4] = useState();
    useState(()=>{
        (
            async ()=>{
                let data = {"username":sessionStorage.getItem('username')}
                let result = await sendOtp(data);
                if(result.message && result.status){
                    notifySuccess(result.message);
                }
                else{
                    notifyError(result.message);
                }
            }
        )()
    },[]);
    async function verifyOTP(){
        let temp = data;
        temp.otp = `${otp1}${otp2}${otp3}${otp4}`;
        temp.username = sessionStorage.getItem('username');
        let result = await verifyEmail(temp);
        if(result.status&&result.username===data.username){
            navigate('/login',{ replace: true });
        }
        if(result.message && result.status){
            notifySuccess(result.message);
        }
        if(result.message && result.status===false){
            notifySuccess(result.message);
        }
        if(result.errors && !result.status){
            let err = Object.values(result.errors);
            notifyError(err[0][0]);
        }
    }
    async function resendOtp(){
        let data = {"username":sessionStorage.getItem('username')}
        let result = await sendOtp(data);
        if(result.message && result.status){
            notifySuccess(result.message);
        }
        else{
            notifyError(result.message);
        }
    }
    function otpChange(e,idx,d){
        console.log(idx,d)
        if(!isNaN(+d)){
            if(idx===1){
                setOtp1(d);
                document.getElementById("inpOtp2").focus();
            }
            if(idx===2){
                setOtp2(d);
                document.getElementById("inpOtp3").focus();
            }
            if(idx===3){
                setOtp3(d);
                document.getElementById("inpOtp4").focus();
            }
            if(idx===4){
                setOtp4(d);
            }
        }
        else{
            if(idx===1){
                if(e.key == 'Enter' && otp1=="")
                {
                    document.getElementById("inpOtp1").focus();
                }
                else{
                    setOtp1("");
                }
            }
            if(idx===2){
                if(e.key == 'Enter' && otp2=="")
                {
                    document.getElementById("inpOtp1").focus();
                }
                else{
                    setOtp2("");
                }
            }
            if(idx===3){
                if(e.key == 'Enter' && otp3=="")
                {
                    document.getElementById("inpOtp2").focus();
                }
                else{
                    setOtp3("");
                }
            }
            if(idx===4){
                if(e.key == 'Enter' && otp4=="")
                {
                    document.getElementById("inpOtp3").focus();
                }
                else{
                    setOtp4("");
                }
            }
        }
    }
    return (
        <>
        <div className="d-flex flex-column justify-content-center align-items-center verify-container">
            <h2>Verify email to login</h2>
            <div className="verify-card py-5 px-3">
                <h5 className="m-0">Email verification</h5>
                <span className="verify-mobile-text">Enter the code we just send on your email</span>
                <div className="d-flex flex-row mt-5 d-flex justify-content-center">
                    <input type="text" id='inpOtp1' value={otp1} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(1,e.target.value)}} className="verify-form-control" autoFocus/>
                    <input type="text" id='inpOtp2' value={otp2} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(2,e.target.value)}} className="verify-form-control"/>
                    <input type="text" id='inpOtp3' value={otp3} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(3,e.target.value)}} className="verify-form-control"/>
                    <input type="text" id='inpOtp4' value={otp4} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(4,e.target.value)}} className="verify-form-control"/>
                </div>
                <div className="text-center mt-5">
                    <span className="d-block verify-mobile-text">Don't receive the code?</span>
                    <span onClick={resendOtp} className="font-weight-bold text-danger verify-cursor">Resend</span>
                </div>
                <div className='d-flex justify-content-center mt-2'>
                    <Button variant="primary" onClick={verifyOTP}>Submit</Button>
                </div>
            </div>
        </div>
        </>
    )
}
