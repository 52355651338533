import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import loginImg from "../images/login.png";
import logofullImg from "../images/logo-full.png";
import { login, prelogin, sendOtp } from '../requests/request';
import { notifyError, notifySuccess } from '../parts/Toast';
import ForgetPassword from './ForgetPassword';

function Login () {
	const navigate = useNavigate();
	const [data,setData] = useState({ username: '', password: '',code: ''});
    let errorsObj = { username:'',password:'',code:''};
    const [errors, setErrors] = useState(errorsObj);
	const [showPassword, setShowPassword] = useState(false);
	const [showForgetPassword,setShowForgetPassword] = useState(false);
	const [loader,setLoader] = useState(false);
	const [logType,setLogType] = useState('pre');
	const [typeData,setTypeData] = useState('');
	const [authType,setAuthType] = useState('');
	const [counter,setCounter] = useState(60);
    const [showTimmer,setShowTimmer] = useState(false);
	const [blockInput,setBlockInput] = useState(false);
	const [blockOtp,setBlockOtp] = useState(true);
	var globalCount = 60;

	async function onLogin(e) {
        e.preventDefault();
		setLoader(true);
        let error = false;
        const errorObj = { ...errorsObj };
        if (data.username === '') {
            errorObj.username = 'Username is Required';
            error = true;
			setLoader(false);
        }
        if (data.password === '') {
            errorObj.password = 'Password is Required';
            error = true;
			setLoader(false);
        }
        setErrors(errorObj);
		if(error===false){
			try{
				if(logType==='pre'){
					let result = await prelogin(data);
					if(result.errors){
						let err = Object.values(result.errors);
						notifyError(err[0][0]);
						setLoader(false);
						setBlockInput(false);
					}
					if(result.message||result.msg&&result.status===false){
						setLoader(false);
						notifyError(result.message||result.msg);
						setBlockInput(false);
					}
					if(result.status===false&&result.otp_send===true){
						sessionStorage.setItem('username',data.username);
						navigate('/verifyEmail');
						setLoader(false);
						setBlockInput(false);
					}
					if(result.status===true){
						sessionStorage.setItem('token',result.token);
						sessionStorage.setItem('auth_type',result.auth_type);
						setAuthType(result.auth_type);
						if(result.auth_type=='otp'){
							sendOTP();
						}
						setLoader(false);
						setLogType('now');
						setBlockInput(true);
					}
				}
				else{
					let temp = {...data}
					temp[authType] = typeData;
					let result = await login(temp,sessionStorage.getItem('token'));
					if(result.errors){
						let err = Object.values(result.errors);
						notifyError(err[0][0]);
						setLoader(false);
					}
					if(result.message||result.msg&&result.status==false){
						setLoader(false);
						notifyError(result.message||result.msg);
					}
					if(result.status===false&&result.otp_send==true){
						sessionStorage.setItem('username',data.username);
						navigate('/verifyEmail');
						setLoader(false);
					}
					if(result.status===true){
						sessionStorage.setItem('username',data.username);
						sessionStorage.setItem('status',result.status);
						sessionStorage.setItem('token',result.token);
						setLoader(false);
						navigate('/admin/dashboard',{ replace: true});
					}
				}
			}
			catch(err){
				console.log(err);
                setLoader(false);
                notifyError("Request failed please login again");
			}
		}
    }
	async function sendOTP(){
		setBlockOtp(false);
        let result = await sendOtp({'username':data.username});
        if(result.message && result.status){
            notifySuccess(result.message);
			var OtpInterval = setInterval(function () {
                setCounter((prev)=>prev-1);
                globalCount-=1;
                if(globalCount<=0){
                    setCounter(60);
                    globalCount=60;
                    setShowTimmer(false);
					setBlockOtp(true);
                    clearInterval(OtpInterval);
                }
            }, 1000);
            setShowTimmer(true);
        }
        if(result.message && result.status==false){
            notifyError(result.message);
			setBlockOtp(true);
        }
        if(result.errors){
            let err = Object.values(result.errors);
			notifyError(err[0][0]);
			setBlockOtp(true);
        }
    }
	function changeShowForgetPassword(){
		setShowForgetPassword(!showForgetPassword);
	}
  	return (
		<>
	<div className="login">
		
		<div className="container">
		<div className='login_logo'>
			<a href="/" className="auth-logo mb-4">
				<img src="/assets/img/dark-logo.png" alt="" />
			</a>
			<h5 className="mb-0">Admin Login</h5>
		</div>
		<div className="login-content">
		
			<form onSubmit={onLogin}>
			
				
				<div className="mb-4">
					<label className="form-label">Username <span className="text-danger">*</span></label>
					<input type="text" placeholder='Username' className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.username} onChange={(e)=>setData({...data,'username':e.target.value})} disabled={blockInput}/>
					{errors.username && <div className="text-danger mt-2 fs-12">{errors.username}</div>}
				</div>
				<div className="mb-4 position-relative">
					<div className="d-flex">
						<label className="form-label">Password <span className="text-danger">*</span></label>
					</div>
					<div className="position-relative">
					<input placeholder='Password'
						type={`${showPassword ? "text" : "password"}`}
						className="form-control form-control-lg bg-inverse bg-opacity-5" value={data.password} onChange={(e)=>setData({...data,'password':e.target.value})} disabled={blockInput}/>	
						
					<span 
						className="eye_show_box"
						onClick={() => setShowPassword(!showPassword)}>
						{showPassword ===  false ? (<i className="bi bi-eye" />) : (<i className="bi bi-eye" />)}
					</span>
					</div>
					{errors.password && <div className="text-danger mt-2 fs-12">{errors.password}</div>}
				</div>
				{authType!==''&&<div className="mb-4 position-relative">
					<label className="form-label" style={{textTransform:'capitalize'}}>{authType=='mpin'?'MPIN':authType}</label>
					<input type="text" autoFocus={true} className="form-control form-control-lg bg-inverse bg-opacity-5" value={typeData} onChange={(e)=>setTypeData(e.target.value)}/>	
					{/* {errors.code && <div className="text-danger fs-12">{errors.code}</div>} */}
					
				</div>}
				{authType=='otp'&&!showTimmer&&<div className="mb-4"><span role="button" className='text-primary mb-2' style={{textTransform:'capitalize'}} onClick={()=>blockOtp?sendOTP('resend'):null}>Click To Send OTP </span></div>}
				{authType=='otp'&&showTimmer&&<div className="mb-4"><span style={{color:'var(--primary)',margin:'8px'}}>Resend request after {counter} sec</span></div>}
				<div className="text-center pt-3 mb-4">
					<button type="submit" className="light_gradient_btn w_100">{logType=='pre'?'Verify':'Sign In'}
					{loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
						<span className="visually-hidden">Loading...</span>
					</div>}
					</button>
				</div>
				<p className='mt-2 mb-0 text-center'>
				<Link onClick={changeShowForgetPassword} className="text-decoration-underline">Forgot password?</Link>
				</p>
			</form>
		</div>
		</div>

		<div className="spinner-wrap">
			<div className="spinner-item"></div>
			<div className="spinner-item spinner-item--2"></div>
			<div className="spinner-item spinner-item--3"></div>
			<div className="spinner-item spinner-item--4"></div>
		</div>
		
	</div>


		{showForgetPassword&&<ForgetPassword props={{changeShowForgetPassword}}/>}
		</>
    )
}
export default Login;