import React,{useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import loginImg from "../images/login.png";
import logofullImg from "../images/logo-full.png";
import { register } from "../requests/request";
import { notifyError } from "../parts/Toast";

function Register() {
    const [data, setData] = useState({
        name: "",
        username: "",
        email: "",
        password: ""
    });
    let errorsObj = { 
        name: "",
        username: "",
        email: "",
        password: ""
    };
    const [errors, setErrors] = useState(errorsObj);
	const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    async function RegisterBtn(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (data.email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (data.password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        if (data.name === '') {
            errorObj.name = 'Name is Required';
            error = true;
        }
        if (data.username === '') {
            errorObj.username = 'Username is Required';
            error = true;
        }
        setErrors(errorObj);
        if(error===false){
			let result = await register(data);
			if(result.message&&result.status===false){
				notifyError(result.message);
			}
			if(result.errors){
				let err = Object.values(result.errors);
				//err.map((data)=>notifyError(data[0]));
				notifyError(err[0][0]);
			}
			if(result.status===true){
				sessionStorage.setItem('username',data.username);
				navigate('/verifyEmail');
			}
		}
    }
	return (
		<>
			<div className="page-wraper">
				<div className="authincation">
					<div className="container-fluid">
						<div className="row h-100">
							<div className="col-lg-6 col-md-12 col-sm-12 mx-auto align-self-center">
								<div className="login-form">
									<div className="text-center">
										<h3 className="title">Sign up your account</h3>
										<p>Sign in to your account to start using MLM</p>
									</div>				
									<form  onSubmit={RegisterBtn}>
                                        <div className="mb-4">
											<label className="mb-1 text-dark">Name</label>
											<input name="dzName2" value={data.name} required="" onChange={(e)=>setData({...data,'name':e.target.value})} className="form-control" placeholder="Name" type="text" />
                                            {errors.name && <div className="text-danger fs-12">{errors.name}</div>}
										</div>
										<div className="mb-4">
											<label className="mb-1 text-dark">Username</label>
											<input name="dzName2" value={data.username} required="" onChange={(e)=>setData({...data,'username':e.target.value})} className="form-control" placeholder="User Name" type="text" />
                                            {errors.username && <div className="text-danger fs-12">{errors.username}</div>}
										</div>
                                        <div className="mb-4">
											<label className="mb-1 text-dark">Email</label>
											<input name="dzName2" value={data.email} required="" onChange={(e)=>setData({...data,'email':e.target.value})} className="form-control" placeholder="Email" type="email" />
                                            {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
										</div>
										<div className="mb-4 position-relative">
											<label className="mb-1 text-dark">Password</label>
											<input type={`${showPassword ? "text" : "password"}`} onChange={(e)=>setData({...data,'password':e.target.value})} value={data.password} className="form-control" placeholder="passowrd"/>
											<span 
												className="show-pass eye" 
												onClick={() => setShowPassword(!showPassword)}>
												{showPassword ===  false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
											</span>
											{errors.password && <div className="text-danger fs-12">{errors.password}</div>}
										</div>
										<div className="form-row d-flex justify-content-between mt-4 mb-2">
											<div className="mb-4">
												<div className="form-check custom-checkbox mb-3">
													<input type="checkbox" className="form-check-input" id="customCheckBox1" />
													<label className="form-check-label mt-1" htmlFor="customCheckBox1">Remember my preference</label>
												</div>
											</div>
											<div className="mb-4">
												<Link to="/login" className="btn-link text-primary">Sign in</Link>
											</div>
										</div>
										<div className="text-center mb-4">
											<button type="submit" className="btn btn-primary btn-block">Sign Up</button>
										</div>
									</form>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6">
								<div className="pages-left h-100">
									<div className="login-content">
										<Link to={"#"}><img src={logofullImg} className="mb-3" alt="" /></Link>		
										<p>Your true value is determined by how much more you give in value than you take in payment. ...</p>
									</div>
									<div className="login-media text-center">
										<img src={loginImg} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Register;

