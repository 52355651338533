import React, { useState } from 'react';
import { getReq, postReq } from '../requests/request';
import { notifyError, notifySuccess } from './Toast';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { IMAGES } from '../constant/theme';

export default function DocTypeAdd({props}) {
    const {setShowModel,setReload} = props;
    const [loader,setLoader] = useState(false);
    const [docText,setDocText] = useState(['']);
    const [countryCode,setCountryCode] = useState();
    const [data,setData] = useState({
        "country_id": '',
        "document_type": '',
        "data": [],
        "status": 1
    });
    async function uploadDocType(){
        let temp = data;
        temp.data = docText;
        setLoader(true);
        let result = await postReq('add/doc-type',temp,sessionStorage.getItem('token'));
        if(result.msg && result.status){
            setLoader(false);
            notifySuccess(result.msg);
            setShowModel(false);
            setReload(true);
            setData({"country_id": '',"document_type": '',"data": [],"status": 1});
        }
        if(result.msg && result.status===false){
            setLoader(false);
            notifyError(result.message);
        }
        if(result.errors){
            if(typeof result.errors === 'string'){
                setLoader(false);
                notifyError(result.errors)
            }
            else{
                setLoader(false);
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
    }
    function addText(id,txt){
        let temp = [...docText];
        temp[id]=txt;
        setDocText(temp);
    }
    function addTextField(){
        let temp = [...docText];
        temp.push('');
        setDocText(temp);
    }
    useState(()=>{
        (async()=>{
            try{
                let r = await getReq('country',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setCountryCode(r.data);
                }
            }
            catch(err){
                notifyError("Request failed ERR_BAD_REQUEST1");
            }
        })()
    },[])
    return (
        <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
            <Modal.Dialog>
                <Modal.Header closeButton onClick={()=>setShowModel(false)}>
                    <Modal.Title>Add Doc Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div>
                    <div className="mb-2">
                        <label className="mb-1">Document Type</label>
                        <div className="form-group">
                            <select name="select_box" className="form-select form-control"  onChange={(e)=>setData({...data,'document_type':e.target.value})} required>
                                <option value="">Select Document Type</option>
                                <option value="identity">Identity</option>
                                <option value="address">Address</option>
                            </select>
                        </div>
                    </div>
                    <div className="mb-2">
                        <label className="mb-1">Country <span className="required">*</span></label>
                        <div className="form-group">
                            <select name="select_box" className="form-select form-control" onChange={(e)=>setData({...data,'country_id':e.target.value})}>
                                <option value="">Select Country</option>
                                {
                                    countryCode && countryCode.map((data,idx)=>
                                        <option value={data.id} key={idx}>{data.name}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className="mb-2">
                        <label className="mb-1">Document Name</label>
                        {
                            docText.map((d,idx)=>
                                <div key={idx} className='mb-2' style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'1rem',cursor:'pointer'}}>
                                    <input type="text" className="form-control" value={d} onChange={(e)=>addText(idx,e.target.value)}/>
                                    {idx===(docText.length-1)&&<img className="add_img_icon" width={20} height={20} src={IMAGES.addButton} onClick={addTextField} alt=""/>}
                                </div>
                            )
                        }
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setShowModel(false)}>Close</Button>
                    <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={uploadDocType}>
                        Submit
                        {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>
    )
}
