import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {Button} from "react-bootstrap";
import { notifyError } from './Toast';
import { getTable } from '../requests/request';

export default function FilterOr({props}) {
    const {setFilterShow,url,list,filterFunData,showDate} = props;
    const [mainData,setMainData] = useState();
    const [loader,setLoader] = useState(false);
    const [dateFrom,setDateFrom] = useState('');
    const [dateTo,setDateTo] = useState('');
    useEffect(()=>{
        let temp = {};
        list.map((data)=>
            temp[data.data] = ""
        )
        setMainData(temp);
    },[]);
    function updateData(label,value){
        let temp = {...mainData};
        temp[label] = value;
        setMainData(temp);
    }
    async function searchFun(){
        setLoader(true);
        let u = "&";
        let temp = {...mainData};
        let key = Object.keys(temp);
        key.map((d)=>u+=`${d}=${temp[d]}&`);
        u+=`from_date=${dateFrom}&to_date=${dateTo}`;
        try{
            console.log(url+u);
            let r = await getTable(url+u,sessionStorage.getItem('token'));
            if(r.status==="success" || r.status===true){
                filterFunData(r);
                setLoader(false);
            }
            if(r.errors){
                setLoader(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setLoader(false);
            notifyError("Request failed please login again");
        }
        setLoader(false);
        setFilterShow(false);
    }
    return (
        // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
            <Modal className="fade" show={true} style={{background:'rgba(255,255,255,0.15)',backdropFilter:'blur(1px)'}}>
                <Modal.Header closeButton onClick={()=>setFilterShow(false)}>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {mainData&&<div className="row">
                            {showDate!==false&&<div className="col-sm-6 mb-3">
                                <label style={{marginBottom:'0px'}}>From:</label>
                                <div id="date-picker-example" className="md-form md-outline input-with-post-icon datepicker">
                                    <input type="date" placeholder="From" id="example" className="form-control" value={dateFrom} onChange={(e)=>setDateFrom(e.target.value)}/>
                                </div>
                            </div>}
                            {showDate!==false&&<div className="col-sm-6 mb-3">
                                <label style={{marginBottom:'0px'}}>To:</label>
                                <div id="date-picker-example" className="md-form md-outline input-with-post-icon datepicker">
                                    <input type="date" placeholder="To" id="example" className="form-control" value={dateTo} onChange={(e)=>setDateTo(e.target.value)}/>
                                </div>
                            </div>}
                            {
                                list.map((data,idx)=>
                                    data.type==='text'?<div key={idx} className="col-sm-6 mb-3">
                                        <label style={{marginBottom:'0px'}}>{data.label}:</label>
                                        <input type="text" value={mainData[data.data]} onChange={(e)=>updateData(data.data,e.target.value)} className="form-control" placeholder={`${data.label}`}/>
                                    </div>
                                    :data.type==='number'?<div key={idx} className="col-sm-6 mb-3">
                                        <label style={{marginBottom:'0px'}}>{data.label}:</label>
                                        <input type="text" value={mainData[data.data]} onChange={(e)=>updateData(data.data,e.target.value.replace(/[^0-9]/ig, ''))} className="form-control" placeholder={`${data.label}`}/>
                                    </div>
                                    :<div key={idx} className="col-sm-6 mb-3">
                                        <label style={{marginBottom:'0px'}}>{data.label}</label>
                                        <select className="form-control" value={mainData[data.data]} onChange={(e)=>updateData(data.data,e.target.value)} required>
                                            <option value={''}>{`Select ${data.label}`}</option>
                                            {data.value.map((val,idx)=>
                                                <option value={val.value} key={idx}>{val.key}</option>
                                            )}
                                        </select>
                                    </div>
                                )
                            }
                        </div>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>setFilterShow(false)}>Close</Button>
                    <Button onClick={searchFun} className='d-flex justify-content-center align-items-center gap-2' variant="primary" disabled={loader}>
                        Filter
                        {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </Button>
                </Modal.Footer>
            </Modal>
        // </div>
    )
}
