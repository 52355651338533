import { Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import ProtectedRoute from '../Middleware/auth';
import PageNotFound from '../pages/PageNotFound';
import { ThemeContext } from '../context/ThemeContext';
import { createContext, useContext, useEffect, useState } from 'react';
import Nav from "../parts/nav";
import Footer from "../parts/Footer";
//import Profile from '../pages/Profile';
import ChangePassword from '../pages/ChangePassword';
import UserList from '../pages/UserList';
import KycList from '../pages/Kyc/KycList';
import KycStatus from '../pages/Kyc/KycStatus';
import KycDocList from '../pages/Kyc/KycDocList';
import ManualDepositeList from '../pages/ManualDeposit/ManualDepositList';
import ManualWithdrawList from '../pages/ManualWithdraw/ManualWithdrawList';
import UserBankList from '../pages/ManualWithdraw/UserBankList';
import WithdrawHistory from '../pages/ManualWithdraw/WithdrawHistory';
import UserBankHistory from '../pages/ManualWithdraw/UserBankHistory';
import BalanceReportList from '../pages/BalanceReport/BalanceReportList';
//import TransferList from '../pages/Transfer/TransferList';
//import TransferHistory from '../pages/Transfer/TransferHistory';
import CreatePackage from '../pages/Package/CreatePackage';
import PackageList from '../pages/Package/PackageList';
import {motion} from 'framer-motion';
import Roi from '../pages/ROI/Roi';
// import UnilevelPercentage from '../pages/Unilevel/UnilevelPercentage';
import EditUserProfile from '../pages/UserProfile/EditUserProfile';
// import Promotion from '../pages/Promotion/Promotion';
import Transactions from '../pages/Finance/Transactions';
import Payouts from '../pages/Finance/Payouts';
import Unilevel from '../pages/Unilevel/Unilevel';
import Binary from '../pages/Binary/Binary';
import ProfileSetting from '../pages/ProfileSetting/ProfileSetting';
import Setting from '../pages/Setting/Setting';
import { getReq } from '../requests/request';
//import PoolSetting from '../pages/PoolSetting/PoolSetting';
import SubscriptionList from '../pages/Member Subscription/SubscriptionList';
import Pakage from '../pages/Member Subscription/Package';
import RankSetting from '../pages/RankSetting/RankSetting';
import SuperRevenueBonus from '../pages/SuperRevenueBonus/SuperRevenueBonus';
import ManageSubscription from '../pages/Member Subscription/ManageSubscription';
import RankList from '../pages/RankUser/Rank';
import AllTransaction from '../pages/Finance/AllTransacation';
import Logs from '../pages/Logs/Logs';
import News from '../pages/News/News';
import ManualDeposit from '../pages/ManualDeposit/ManualDeposit';
//import GlobalPoolBonus from '../pages/GlobalPoolBonus/GlobalPoolBonus';
//import GlobalLinePoolBonus from '../pages/GlobalLinePoolBonus/GlobalLinePoolBonus';
//import LeadershipPoolRanksAndBusinessFund from '../pages/LeadershipPoolRanks&BusinessFund/LeadershipPoolRanksAndBusinessFund';

export const SidebarClick = createContext();
export default function PageRoute(){
    const [pinSidebar,setPinSidebar] = useState(false);
    const [currentSidebarClick,setCurrentSidebarClick] = useState('');
    const [hideBinary,setHideBinary] = useState(false);
    const [collapseId,setCollapseId] = useState(null);
    const [currentSubmenu,setCurrentSubmenu] = useState(null);
    const { menuToggle } = useContext(ThemeContext);
    useEffect(()=>{
        (async ()=>{
            let br = await getReq('pre-register');
            setHideBinary(parseInt(br.binary));
            sessionStorage.setItem('binary',parseInt(br.binary));
        })()
    },[]);

    return (
        <SidebarClick.Provider value={{currentSidebarClick,setCurrentSidebarClick,collapseId,setCollapseId,hideBinary,setHideBinary, currentSubmenu,setCurrentSubmenu,pinSidebar,setPinSidebar}}>
            <Routes>
                <Route path="/dashboard" element={<ProtectedRoute Children={Dashboard} />}/>
                <Route path='/changePassword' element={<ProtectedRoute Children={ChangePassword}/>} />
                <Route path='/userList' element={<ProtectedRoute Children={UserList}/>} />
                {/* <Route path="/profile" element={<ProtectedRoute Children={Profile} />}/> */}
                <Route path='/kycList' element={<ProtectedRoute Children={KycList}/>}/>
                <Route path='/kycStatus' element={<ProtectedRoute Children={KycStatus}/>}/>
                <Route path='/kycDocList' element={<ProtectedRoute Children={KycDocList}/>}/>
                <Route path='/manualDepositList' element={<ProtectedRoute Children={ManualDepositeList}/>}/>
                <Route path='/manualDeposit' element={<ProtectedRoute Children={ManualDeposit}/>}/>
                <Route path='/manualWithdrawList' element={<ProtectedRoute Children={ManualWithdrawList}/>}/>
                <Route path='/manualWithdrawHistory' element={<ProtectedRoute Children={WithdrawHistory}/>}/>
                {/* <Route path='/userBankList' element={<ProtectedRoute Children={UserBankList}/>}/> */}
                {/* <Route path='/userBankHistory' element={<ProtectedRoute Children={UserBankHistory}/>}/> */}
                <Route path='/balanceReportList' element={<ProtectedRoute Children={BalanceReportList}/>}/>
                {/* <Route path='/transferList' element={<ProtectedRoute Children={TransferList}/>}/> */}
                {/* <Route path='/transferHistory' element={<ProtectedRoute Children={TransferHistory}/>}/> */}
                <Route path='/packageCreate' element={<ProtectedRoute Children={CreatePackage}/>}/>
                <Route path='/packageList' element={<ProtectedRoute Children={PackageList}/>}/>
                {/* <Route path='/roi' element={<ProtectedRoute Children={Roi}/>}/> */}
                {/* <Route path='/unilevel' element={<ProtectedRoute Children={Unilevel}/>}/> */}
                {/* <Route path='/binary' element={<ProtectedRoute Children={Binary}/>}/> */}
                {/* <Route path='/superRevenueBonus' element={<ProtectedRoute Children={SuperRevenueBonus}/>}/> */}
                <Route path='/directReferralBonus' element={<ProtectedRoute Children={SuperRevenueBonus}/>}/>
                {/* <Route path='/globalPoolBonus' element={<ProtectedRoute Children={GlobalPoolBonus}/>}/> */}
                {/* <Route path='/globalLinePoolBonus' element={<ProtectedRoute Children={GlobalLinePoolBonus}/>}/> */}
                {/* <Route path='/leadershipPoolRanksAndBusinessFund' element={<ProtectedRoute Children={LeadershipPoolRanksAndBusinessFund}/>}/> */}
                {/* <Route path='/unilevelPercentage' element={<ProtectedRoute Children={UnilevelPercentage}/>}/> */}
                <Route path='/editUserProfile' element={<ProtectedRoute Children={EditUserProfile}/>}/>
                <Route path='/profileSetting' element={<ProtectedRoute Children={ProfileSetting}/>}/>
                {/* <Route path='/promotion' element={<ProtectedRoute Children={Promotion}/>}/> */}
                {/* <Route path='/transactions' element={<ProtectedRoute Children={Transactions}/>}/> */}
                <Route path='/payouts' element={<ProtectedRoute Children={Payouts}/>}/>
                <Route path='/allTransaction' element={<ProtectedRoute Children={AllTransaction}/>}/>
                {/* <Route path='/poolSetting' element={<ProtectedRoute Children={PoolSetting}/>}/> */}
                <Route path='/rankSetting' element={<ProtectedRoute Children={RankSetting}/>}/>
                <Route path='/rankList' element={<ProtectedRoute Children={RankList}/>}/>
                <Route path='/subscriptionList' element={<ProtectedRoute Children={SubscriptionList}/>}/>
                {/* <Route path='/manageSubscription' element={<ProtectedRoute Children={ManageSubscription}/>}/> */}
                <Route path='/package' element={<ProtectedRoute Children={Pakage}/>}/>
                <Route path='/setting' element={<ProtectedRoute Children={Setting}/>}/>
                <Route path='/logs' element={<ProtectedRoute Children={Logs}/>}/>
                {/* <Route path='/news' element={<ProtectedRoute Children={News}/>}/> */}
                <Route path='/*' element={<ProtectedRoute Children={PageNotFound} />}/>
            </Routes>
        </SidebarClick.Provider>
    )
}