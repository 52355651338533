export default function CheckFormError(obj){
    let errList = {};
    let countError = 0;
    let objVal = Object.values(obj);
    let objKey = Object.keys(obj);
    objVal.forEach((data,index)=>{
        if(data!==""&&data!==null){
            errList[objKey[index]] = false;
        }
        else{
            errList[objKey[index]] = true;
            countError+=1;
        }
    })
    return {errList,countError}
}