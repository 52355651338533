import React from 'react';
import { Button } from 'react-bootstrap';
import { DownloadTableExcel,useDownloadExcel } from 'react-export-table-to-excel';
import * as XLSX from 'xlsx';
import { notifyError } from './Toast';

export default function DownloadExcel({props}) {
    async function downloadExcel() {
        //let r = await getTable(`${process.env.REACT_APP_BASE_URL}/payouts?export=true`,sessionStorage.getItem('token'))
        if(props.exportData){
            const worksheet = XLSX.utils.json_to_sheet(props.exportData);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
            //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
            XLSX.writeFile(workbook, props.name);
        }
        else{
            notifyError('Something went wrong, please try again');
        }
    };
    // const { onDownload } = useDownloadExcel({
    //     currentTableRef: props.tableRef.current,
    //     filename: props.name,
    //     sheet: props.name
    // })
    return (
        <div style={{marginLeft:'1px'}}>
            {/* <DownloadTableExcel filename="users list" sheet="userlist" currentTableRef={props.tableRef.current}> */}
            <Button className='btn rounded-5 btn-sm btn-outline-theme' variant="outline-info" onClick={downloadExcel}><i className="bi bi-download"></i> Download table as excel</Button>{' '}
            {/* </DownloadTableExcel> */}
        </div>
    )
}
