import React, { useContext, useEffect, useRef, useState } from 'react';
import {Col,Card,Table, Spinner,Dropdown} from "react-bootstrap";
import { getReq,getTable, postReq } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import { IMAGES, SVGICON } from "../../constant/theme";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import openImg from '../../parts/ImageNewTab';
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import DownloadExcel from '../../parts/DownloadExcel';
import MoreDetails from '../../parts/MoreDetails';
import moment from 'moment/moment';
import Frame from "../../components/frame";
export default function ManualdDepositList() {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [depositData,setDepositData] = useState();
    const [refrashPage,setRefrashPage] = useState(false);
    const [searchBy,setSearchBy] = useState('');
    const [searchByText,setSearchByText] = useState('');
    const [selectUsername,setSelectUsername] = useState(null);
    const [selectAmount,setSelectAmount] = useState(0);
    const [loader,setLoader] = useState(false);
    const [showApproveModel,setShowApproveModel] = useState(false);
    const [showRejectModel,setShowRejectModel] = useState(false);
    const [modelApproveData,setModelApproveData] = useState({'id':'','received_amount':'','comment':''});
    const [modelRejectData,setModelRejectData] = useState({'id':'','reason':''});
    const [filterShow,setFilterShow] = useState(false);
    const [moreDetails,setMoreDetails] = useState('');
    let countTable = 0;
    let durationanim = 0.1;
    let AmountSymbol = '$';
    const list=[
        {'label':'Username','data':'username','type':'text'},
        {'label':'Transaction Id','data':'transaction_id','type':'text'},
        {'label':'Status','data':'status','type':'option','value':[{'key':'Approved','value':'1'},{'key':'Rejected','value':'2'},{'key':'Pending','value':'0'}]}
    ]
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/manualDepositList');
        setCollapseId(3);
        (async()=>{
            try{
                //setSpinner(true);
                let r = await getReq('deposit/list',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setDepositData(r.data);
                    setTable(r.data.data);
                    setMoreDetails(r.more_details);
                    //setSpinner(false);
                }
                if(r.errors){
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                if(err.message){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                }
                //setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    },[refrashPage]);
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    );
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/deposit/list?page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setDepositData(r.data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setDepositData(r.data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    function depositApprove(id,username,amount){
        window.scrollTo(0, 0);
        setSelectUsername(username);
        let temp = {...modelApproveData};
        temp.id = id;
        temp.received_amount = amount;
        setModelApproveData(temp);
        setShowApproveModel(true);
    }
    function depositReject(id){
        window.scrollTo(0, 0);
        let temp = {...modelRejectData};
        temp.id = id;
        setModelRejectData(temp);
        setShowRejectModel(true);
    }
    // async function funApprove(){
    //     window.scrollTo(0, 0);
    //     try{
    //         setLoader(true);
    //         let r = await postReq('approval/deposit/approve',modelApproveData,sessionStorage.getItem('token'))
    //         if(r.status==="success" || r.status===true){
    //             notifySuccess(r.message);
    //             setLoader(false);
    //             setRefrashPage(true);
    //             setShowApproveModel(false);
    //         }
    //         if(r.msg || r.status===false){
    //             notifySuccess(r.msg);
    //             setLoader(false);
    //             setRefrashPage(true);
    //         }
    //         if(r.errors){
    //             setLoader(false);
    //             let err = Object.values(r.errors);
    //             //err.map((data)=>notifyError(data[0]));
    //             notifyError(err[0][0]);
    //         }
    //         setShowApproveModel(false);
    //     }
    //     catch(err){
    //         setLoader(false);
    //         notifyError("Request failed please login again");
    //     }
    // }
    // async function funReject(){
    //     window.scrollTo(0, 0);
    //     try{
    //         setLoader(true);
    //         let r = await postReq('approval/deposit/reject',modelRejectData,sessionStorage.getItem('token'))
    //         if(r.status==="success" || r.status===true){
    //             notifySuccess(r.message);
    //             setLoader(false);
    //             setRefrashPage(true);
    //             setShowRejectModel(false);
    //         }
    //         if(r.msg || r.status===false){
    //             notifySuccess(r.msg);
    //             setLoader(false);
    //             setRefrashPage(true);
    //         }
    //         if(r.errors){
    //             setLoader(false);
    //             let err = Object.values(r.errors);
    //             //err.map((data)=>notifyError(data[0]));
    //             notifyError(err[0][0]);
    //         }
    //         setShowRejectModel(false);
    //     }
    //     catch(err){
    //         setLoader(false);
    //         notifyError("Request failed please login again");
    //     }
    // }
    function filterFunData(r){
        setDepositData(r.data);
        setTable(r.data.data);
        setMoreDetails(r.more_details);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
             <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                        Deposit List
                    </h1>
                </div>
                <div className="ms-auto">
                    {table&&<DownloadExcel props={{tableRef:tableRef,name:'ManualDepositlist'}}/>}
                </div>
            </div>
            {moreDetails&&<MoreDetails props={moreDetails}/>}
            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}
            {table&&<div className="card">
                <div className="card-header align-items-center fw-bold small d-flex">
                    <span className="flex-grow-1">Deposit List</span>
                    <span className="btn btn-outline-light btn-sm" onClick={() => setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div>
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                    <Table responsive style={{textAlign:'center'}} ref={tableRef}>
                        <thead>
                            <tr>
                                {/* <th>
                                    <strong>Action</strong>
                                </th> */}
                                <th>
                                    <strong>username</strong>
                                </th>
                                <th>
                                    <strong>Amount</strong>
                                </th>
                                <th>
                                    <strong>Address</strong>
                                </th>
                                <th>
                                    <strong>Transaction ID</strong>
                                </th>
                                <th>
                                    <strong>Requested At</strong>
                                </th>
                                <th>
                                    <strong>Process At</strong>
                                </th>
                                {/* <th>
                                    <strong>Image</strong>
                                </th> */}
                                {/* <th>
                                    <strong>Fees</strong>
                                </th> */}
                                {/* <th>
                                    <strong>Account Details</strong>
                                </th> */}
                                {/* <th>
                                    <strong>Comment</strong>
                                </th>
                                <th>
                                    <strong>Remark</strong>
                                </th> */}
                                {/* <th>
                                    <strong>Method</strong>
                                </th> */}
                                <th>
                                    <strong>Status</strong>
                                </th>
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>{
                                countTable += 1;
                                return(<motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                                    {/* <td>
                                        {data.status===0?<Dropdown>
                                            <Dropdown.Toggle variant="success" className="light sharp i-false">
                                                {svg1}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>{depositApprove(data.id,data.username,data.amount)}}>Approve</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>{depositReject(data.id)}}>Reject</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        :<Dropdown>
                                            <Dropdown.Toggle style={{cursor:'not-allowed'}} variant="success" className="light sharp i-false">
                                                {svg1}
                                            </Dropdown.Toggle>
                                        </Dropdown>
                                        }
                                    </td> */}
                                    <td>{data.username}</td>
                                    <td>{AmountSymbol} {parseFloat(data.amount)}</td>
                                    <td>
                                        <div className='expendHash'>
                                            {data.address}
                                        </div>
                                    </td>
                                    <td>
                                        <div className='expendHash'>
                                            {data.hash}
                                        </div>
                                    </td>
                                    <td>{moment(data.created_at).format('DD-MM-YYYY HH:mm')}</td>
                                    <td>{data.process_at!=null?moment(data.process_at).format('DD-MM-YYYY HH:mm'):'-'}</td>
                                    {/* <td style={{textAlign: "-webkit-center"}}>
                                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}> */}
                                            {/* <div style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",width:"12rem"}}>
                                                {data.image}
                                            </div> */}
                                            {/* <div className="menu-icon" style={{margin:'2px',cursor:'pointer'}} onClick={()=>openImg(`${process.env.REACT_APP_IMG_URL}/${data.image}`)}>View {SVGICON.newTab}</div>
                                        </div>
                                    </td> */}
                                    {/* <td>{AmountSymbol} {data.fees}</td> */}
                                    {/* <td>
                                        {data.type=='bank'&&<div style={{display:'flex',flexDirection:'column'}}>
                                            <div>{'Bank Name - '}{data.bank_name}</div>
                                            <div>{'Account No. - '}{data.account_number}</div>
                                            <div>{'Account Holder Name - '}{data.account_holder_name}</div>
                                            <div>{'IFSC - '}{data.ifsc}</div>
                                        </div>}
                                        {data.type=='crypto'&&<div style={{display:'flex',flexDirection:'column'}}>
                                            <div><div>{'Network - '}{data.network}</div></div>
                                            <div><div>{'Receiving Account - '}{data.receiving_account}</div></div>
                                        </div>}
                                    </td> */}
                                    {/* <td>{data.comment}</td>
                                    <td>{data.remarks||'-'}</td> */}
                                    {/* <td>{data.method}</td> */}
                                    <td>
                                        {data.status===0 && <span className="badge light btn btn-warning">Pending</span>}
                                        {data.status===1 && <span className="badge light btn btn-success">Approved</span>}
                                        {data.status===2 && <span className="badge light btn btn-danger">Rejected</span>}
                                    </td>
                                </motion.tr>)
                                })
                            }
                        </tbody>}
                        {
                            countTable===0&& <div style={{display:'table-caption',marginTop:'1rem',textAlign:'center'}}>No Data For Action!!!</div>
                        }
                    </Table>
                    {table&&
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className="h6">
                            Showing <span className="font-medium">{depositData.from||'-'}</span> to <span className="font-medium">{depositData.to||'-'}</span> of{' '}
                            <span className="font-medium">{depositData.total}</span> results
                        </p>
                        <div className="d-flex">
                           
                            <nav aria-label="Page navigation">
                                <ul className="pagination p-0">
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(depositData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link cursor-pointer">&laquo;</span>
                                        </a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}}>
                                        <a className="page-link">{depositData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(depositData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
                </div>
                
            </div>}
        {filterShow&&<Filter props={{setFilterShow,url:`${process.env.REACT_APP_BASE_URL}/deposit/list`,list,filterFunData}}/>}
       
        {/* {showApproveModel&&
            // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{background:'rgba(255,255,255,0.15)',backdropFilter:'blur(1px)'}}>
                    <Modal.Header closeButton onClick={()=>{setShowApproveModel(false);setModelApproveData({'id':'','received_amount':'','comment':''})}}>
                        <Modal.Title>Approve Deposit For <strong>{selectUsername}</strong></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div>
                        <div className="mb-2">
                            <label className="mb-1">Received Amount</label>
                            <input type="number" className="form-control" value={modelApproveData.received_amount} onChange={(e)=>setModelApproveData({...modelApproveData,'received_amount':e.target.value})}/>
                        </div>
                        <div className="mb-2">
                            <label className="mb-1">Comment</label>
                            <input type="text" className="form-control" value={modelApproveData.comment} onChange={(e)=>setModelApproveData({...modelApproveData,'comment':e.target.value})}/>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{setShowApproveModel(false);setModelApproveData({'id':'','received_amount':'','comment':''})}}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={funApprove}>
                            Submit
                            {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            // </div>
        } */}
        {/* {showRejectModel&&
            // <div className="modal show" style={{ display: 'block',position:'absolute',background:'rgba(255,255,255,0.2)',backdropFilter:'blur(1px)'}}>
                <Modal className="fade" show={true} style={{background:'rgba(255,255,255,0.15)',backdropFilter:'blur(1px)'}}>
                    <Modal.Header closeButton onClick={()=>{setShowRejectModel(false);setModelRejectData({'id':'','reason':''})}}>
                        <Modal.Title>Reject Deposit</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div>
                        <div className="mb-2">
                            <label className="mb-1">Reason</label>
                            <input type="text" className="form-control" value={modelRejectData.reason} onChange={(e)=>setModelRejectData({...modelRejectData,'reason':e.target.value})}/>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>{setShowRejectModel(false);setModelRejectData({'id':'','reason':''})}}>Close</Button>
                        <Button className='d-flex justify-content-center align-items-center gap-2' variant="primary" onClick={funReject}>
                            Submit
                            {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>}
                        </Button>
                    </Modal.Footer>
                </Modal>
            // </div>
        } */}
    </Frame>
    )
}