import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Nav, Tab, Spinner } from "react-bootstrap";
import { SidebarClick } from '../../routes/route';
import { getReq, postReq } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { useNavigate } from 'react-router-dom';
import CheckFormError from '../../parts/FormError';
import Frame from "../../components/frame";
export default function Setting() {
    const navigate = useNavigate();
    const { setCurrentSidebarClick, setCollapseId, setHideBinary } = useContext(SidebarClick);
    const [settingData, setSettingData] = useState([]);
    const [refrashPage, setRefrashPage] = useState(false);
    const [sendSettingData, setSendSettingData] = useState({});
    const [settingError, setSettingError] = useState({});
    const [spinner, setSpinner] = useState(true);
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        setCollapseId(14);
        (async () => {
            let br = await getReq('pre-register');
            sessionStorage.setItem('binary',parseInt(br.binary));
            setHideBinary(parseInt(br.binary));
        })()
    }, []);
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/setting');
        setCollapseId(14);
        (async () => {
            try {
                setSpinner(true);
                let r = await getReq('settings', sessionStorage.getItem('token'));
                if (r.status === true) {
                    let temp = {};
                    r.data.forEach((val) => {
                        temp[val.title] = val.value;
                    })
                    setSendSettingData(temp);
                    setSettingData(r.data);
                    setSpinner(false);
                }
                if (r.errors) {
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0][0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                if (err.message) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    }, [refrashPage]);
    async function updateSetting(e) {
        e.preventDefault();
        let { errList, countError } = CheckFormError({ ...sendSettingData });
        setSettingError(errList);
        if (countError <= 0) {
            setLoader(true);
            let r = await postReq('settings/update', sendSettingData, sessionStorage.getItem('token'))
            if (r.status === "success" || r.status === true) {
                notifySuccess(r.message || r.msg);
                setLoader(false);
                setRefrashPage(true);
            }
            if ((r.msg || r.message) && r.status === false) {
                notifyError(r.msg || r.message);
                setLoader(false);
            }
            if (r.errors) {
                setLoader(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            setRefrashPage(true);
        }
    }
    function funUpdateData(title, value) {
        let temp = { ...sendSettingData };
        temp[title] = value;
        setSendSettingData(temp);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                        Administration Setting
                    </h1>
                </div>
            </div>
            {!settingData&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}
            {settingData&&<div className="card">
                <div className="card-header fw-bold small">Setting</div>
                <div className="card-body">
                    <form>
                        <div className="row">
                            {settingData.map((val, idx) => {
                                if (val.type == 'dropdown') {
                                    return (<div className="col-lg-6 mb-2" key={idx}>
                                        <div className="mb-3">
                                            <label className="mb-1" style={{textTransform:"capitalize"}}>{val.title.replaceAll("_", " ")} <span className="required">*</span></label>
                                            <select className="form-control" value={sendSettingData[val.title]} onChange={(e) => funUpdateData(val.title, e.target.value)}>
                                                {val.dropdown_values.map((v, idx) => {
                                                    return <option key={idx} value={val.package == 'status' ? v == 'active' ? 1 : 0 : v}>{v}</option>
                                                })}
                                            </select>
                                        </div>
                                        {(sendSettingData[val.title] == '' && settingError[val.title]) && <span className="required">This Field Is Required.</span>}
                                    </div>)
                                }
                                else if (val.type == 'number') {
                                    return (<div className="col-lg-6 mb-2" key={idx}>
                                        <div className="mb-3">
                                            <label className="mb-1" style={{textTransform:"capitalize"}}>{val.title.replaceAll("_", " ")} <span className="required">*</span></label>
                                            <input type="text" className="form-control" value={sendSettingData[val.title]} onChange={(e) => funUpdateData(val.title, e.target.value.replace(/[^0-9]/ig, ''))} required />
                                        </div>
                                        {(sendSettingData[val.title] == "" && settingError[val.title]) && <span className="required">This Field Is Required.</span>}
                                    </div>)
                                }
                                else {
                                    return (<div className="col-lg-6 mb-2" key={idx}>
                                        <div className="mb-3">
                                            <label className="mb-1" style={{textTransform:"capitalize"}}>{val.title.replaceAll("_", " ")} <span className="required">*</span></label>
                                            <input type="text" className="form-control" value={sendSettingData[val.title]} onChange={(e) => funUpdateData(val.title, e.target.value)} required />
                                        </div>
                                        {(sendSettingData[val.title] == "" && settingError[val.title]) && <span className="required">This Field Is Required.</span>}
                                    </div>)
                                }
                            }
                            )}
                        </div>
                        {settingData.length>0&&<div className="text-end mb-3">
                            <button className="btn btn-primary sw-btn-next" onClick={(e) => updateSetting(e)} disabled={loader}>
                                    Update Settings
                                {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem', marginRight: '5px', marginLeft: '5px' }} role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>}
                            </button>
                        </div>}
                    </form>
                </div>
            </div>}


        </Frame>
    )
}
