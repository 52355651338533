import React, { useContext, useEffect, useState } from 'react'
import { postReq } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import Frame from "../../components/frame";
export default function CreatePackage() {
    const [price, setPrice] = useState("");
    const [is_roi, setIs_roi] = useState(false);
    const [is_unilevel, setIs_unilevel] = useState(false);
    const [is_binary, setIs_binary] = useState(false);
    const [is_referral, setIs_referral] = useState(false);
    const [cal_binary_field, setCal_binary_field] = useState(null);
    const [loader, setLoader] = useState(false);
    const { setCollapseId, hideBinary } = useContext(SidebarClick);
    let AmountSymbol = '$';
    const [data, setData] = useState({
        "name": "",
        "type": "",
        "price": 0,
        "minimum_price": 0,
        "maximum_price": 0,
        "duration": 0,
        "description": "",
        "non_working_percentage":0,
        "is_roi": 0,
        "is_unilevel": 0,
        "is_binary": 0,
        "is_referral": 0,
        "calculate_binary_field": "",
        "binary_volume": 0,
        "roi_percentage": 0,
        "roi_days": 0,
        "is_roi_unilevel": 0,
        "is_binary_unilevel": 0,
        "is_referral_unilevel": 0,
        "roi_level": 0,
        "referral_level": 0,
        //"unilevel_level": 0,
        "binary_level": 0,
        "binary_type": "",
        "binary_percentage": 0,
        "status": 0,
        "is_cancellation":0,
        // "non_working_percentage":0,
        // "total_income_percentage":0,
    });
    useEffect(() => {
        setCollapseId(9);
    }, [])
    function changeRoi(state) {
        let temp = { ...data };
        if (state === false) {
            temp.is_roi = 0;
            temp.roi_percentage = 0;
            temp.roi_days = 0;
            temp.roi_level = 0;
        }
        else {
            temp.is_roi = 1;
        }
        setData(temp);
    }
    function changeUnilevel(state) {
        let temp = { ...data };
        if (state === false) {
            temp.is_unilevel = 0;
            //temp.unilevel_level = 0;
            temp.is_roi_unilevel = 0
            temp.is_binary_unilevel = 0;
            temp.is_referral_unilevel = 0;

        }
        else {
            temp.is_unilevel = 1;
        }
        setData(temp);
    }
    function changeBinary(state) {
        let temp = { ...data };
        if (state === false) {
            temp.is_binary = 0;
            temp.binary_type = "";
            temp.calculate_binary_field = "";
            temp.binary_volume = "";
            temp.binary_level = "";
            temp.binary_percentage = "";
        }
        else {
            temp.is_binary = 1;
        }
        setData(temp);
    }
    function changeReferral(state) {
        let temp = { ...data };
        if (state === false) {
            temp.referral_level = 0;
        }
        setData(temp);
    }
    async function sendData(e) {
        e.preventDefault();
        setLoader(true);
        let r = await postReq('package/create', data, sessionStorage.getItem('token'));
        if (r.status === "success" || r.status === true) {
            setLoader(false);
            notifySuccess(r.msg || r.message);
            setData({
                "name": "",
                "type": "",
                "price": 0,
                "minimum_price": 0,
                "maximum_price": 0,
                "duration": 0,
                "description": "",
                "non_working_percentage":0,
                "is_roi": 0,
                "is_unilevel": 0,
                "is_binary": 0,
                "is_referral": 0,
                "calculate_binary_field": "",
                "binary_volume": 0,
                "roi_percentage": 0,
                "roi_days": 0,
                "is_roi_unilevel": 0,
                "is_binary_unilevel": 0,
                "is_referral_unilevel": 0,
                "roi_level": 0,
                "referral_level": 0,
                //"unilevel_level": 0,
                "binary_type": "",
                "binary_percentage": 0,
                "status": 0,
                "is_cancellation":0,
                // "non_working_percentage":0,
                // "total_income_percentage":0,
            });
        }
        if (r.errors) {
            setLoader(false);
            let err = Object.values(r.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        if (r.message || r.msg && r.status === false) {
            notifySuccess(r.message || r.msg);
        }
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                    Create Package
                    </h1>
                </div>
                
            </div>
            <div className="card">
                <div className="card-header fw-bold small">Create New Package</div>
                <div className="card-body">
                <form>
           
           <div className="row">
               <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Name <span className="required">*</span></label>
                       <input type="text" name="Package Name" className="form-control" value={data.name} onChange={(e) => setData({ ...data, 'name': e.target.value })} required />
                   </div>
               </div>
               <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Type <span className="required">*</span></label>
                       <div className="form-group mb-3">
                           <select className="form-control" onChange={(e) => { setPrice(e.target.value); setData({ ...data, 'type': e.target.value }) }}>
                               <option value={''}>Select Type</option>
                               <option value={'fixed'}>Fixed</option>
                               <option value={'variable'}>Variable</option>
                           </select>
                       </div>
                   </div>
               </div>
               {price === 'fixed' && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Price ({process.env.REACT_APP_Amount_Symbol||'$'})<span className="required">*</span></label>
                       <input type="number" className="form-control" id="inputGroupPrepend2" aria-describedby="inputGroupPrepend2" value={data.price} onChange={(e) => setData({ ...data, 'price': e.target.value })} required />
                   </div>
               </div>}
               {price === 'variable' && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Minimum Price ({process.env.REACT_APP_Amount_Symbol||'$'})<span className="required">*</span></label>
                       <input type="number" name="phoneNumber" className="form-control" value={data.minimum_price} onChange={(e) => setData({ ...data, 'minimum_price': e.target.value })} required />
                   </div>
               </div>}
               {price === 'variable' && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Maximum Price ({process.env.REACT_APP_Amount_Symbol||'$'})<span className="required">*</span></label>
                       <input type="number" name="phoneNumber" className="form-control" value={data.maximum_price} onChange={(e) => setData({ ...data, 'maximum_price': e.target.value })} required />
                   </div>
               </div>}
               <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Duration (Month) <span className="required">*</span></label>
                       <input type="number" name="duration" className="form-control" value={data.duration} onChange={(e) => setData({ ...data, 'duration': e.target.value })} required />
                   </div>
               </div>
               <div className="col-lg-12 mb-3">
                   <div className="form-group">
                       <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                           <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked2">Unilevel :</label>
                           <div className="form-check form-switch">
                               <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked2" onChange={(e) => { setIs_unilevel(e.target.checked); changeUnilevel(e.target.checked); setData({ ...data, 'is_unilevel': e.target.checked ? 1 : 0 }) }} />
                           </div>
                       </div>
                   </div>
               </div>
               {is_unilevel && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Roi Unilevel <span className="required">*</span></label>
                       <div className="form-group mb-3">
                           <select className="form-control" value={data.is_roi_unilevel} onChange={(e) => setData({ ...data, 'is_roi_unilevel': e.target.value })}>
                               <option value={''}>Select Type</option>
                               <option value={1}>Active</option>
                               <option value={0}>Inactive</option>
                           </select>
                       </div>
                   </div>
               </div>}
               {(is_unilevel && hideBinary == 1) && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Binary Unilevel <span className="required">*</span></label>
                       <div className="form-group mb-3">
                           <select className="form-control" value={data.is_binary_unilevel} onChange={(e) => setData({ ...data, 'is_binary_unilevel': e.target.value })}>
                               <option value={''}>Select Type</option>
                               <option value={1}>Active</option>
                               <option value={0}>Inactive</option>
                           </select>
                       </div>
                   </div>
               </div>}
               {is_unilevel && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Referral Unilevel <span className="required">*</span></label>
                       <div className="form-group mb-3">
                           <select className="form-control" value={data.is_referral_unilevel} onChange={(e) => setData({ ...data, 'is_referral_unilevel': e.target.value })}>
                               <option value={''}>Select Type</option>
                               <option value={1}>Active</option>
                               <option value={0}>Inactive</option>
                           </select>
                       </div>
                   </div>
               </div>}
               <div className="col-lg-12 mb-3">
                   <div className="form-group">
                       <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                           <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked1">Roi :</label>
                           <div className="form-check form-switch">
                               <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked1" defaultChecked={data.is_roi ? true : false} onChange={(e) => { setIs_roi(e.target.checked); changeRoi(e.target.checked) }} />
                           </div>
                       </div>
                   </div>
               </div>
               {is_roi && data.is_roi_unilevel != 0 && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Roi Level <span className="required">*</span></label>
                       <input type="number" name="RoiLevel" className="form-control" value={data.roi_level} onChange={(e) => setData({ ...data, 'roi_level': e.target.value })} required />
                   </div>
               </div>}
               {is_roi && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Roi Percentage <span className="required">*</span></label>
                       <input type="number" name="RoiPercentage" className="form-control" value={data.roi_percentage} onChange={(e) => setData({ ...data, 'roi_percentage': e.target.value })} required />
                   </div>
               </div>}
               {is_roi && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Roi Month <span className="required">*</span></label>
                       <input type="number" name="RoiDays" className="form-control" value={data.roi_days} onChange={(e) => setData({ ...data, 'roi_days': e.target.value })} required />
                   </div>
               </div>}
               {(hideBinary == 1) && <div className="col-lg-12 mb-3">
                   <div className="form-group">
                       <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                           <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked3">Binary :</label>
                           <div className="form-check form-switch">
                               <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked3" onChange={(e) => { setIs_binary(e.target.checked); changeBinary(e.target.checked); setData({ ...data, 'is_binary': e.target.checked ? 1 : 0 }) }} />
                           </div>
                       </div>
                   </div>
               </div>}
               {is_binary && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Calculate Binary Field <span className="required">*</span></label>
                       <select className="form-control" value={data.calculate_binary_field} onChange={(e) => { setData({ ...data, 'calculate_binary_field': e.target.value }) }} required>
                           <option value={''}>Select Matching Type</option>
                           <option value={'price'}>Price</option>
                           <option value={'binary_volume'}>Volume</option>
                       </select>
                   </div>
               </div>}
               {data.calculate_binary_field === "binary_volume" && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Binary Volume <span className="required">*</span></label>
                       <input type="text" name="Package Name" className="form-control" value={data.binary_volume} onChange={(e) => setData({ ...data, 'binary_volume': e.target.value })} required />
                   </div>
               </div>}
               {is_binary && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Matching Type <span className="required">*</span></label>
                       <div className="form-group mb-3">
                           <select className="form-control" value={data.binary_type} onChange={(e) => setData({ ...data, 'binary_type': e.target.value })}>
                               <option value={''}>Select Type</option>
                               <option value={'fixed_percentage'}>Fixed Percentage</option>
                               <option value={'hybrid_percentage'}>Hybrid Percentage</option>
                           </select>
                       </div>
                   </div>
               </div>}
               {is_binary && data.is_binary_unilevel != 0 && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Binary Level <span className="required">*</span></label>
                       <input type="number" name="binary_level" className="form-control" value={data.binary_level} onChange={(e) => setData({ ...data, 'binary_level': e.target.value })} required />
                   </div>
               </div>}
               {is_binary && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Matching Percentage <span className="required">*</span></label>
                       <input type="number" name="binary_percentage" className="form-control" value={data.binary_percentage} onChange={(e) => setData({ ...data, 'binary_percentage': e.target.value })} required />
                   </div>
               </div>}
               <div className="col-lg-12 mb-3">
                   <div className="form-group">
                       <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                           <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked4">Referral :</label>
                           <div className="form-check form-switch">
                               <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked4" onChange={(e) => { setIs_referral(e.target.checked); changeReferral(e.target.checked); setData({ ...data, 'is_referral': e.target.checked ? 1 : 0 }) }} />
                           </div>
                       </div>
                   </div>
               </div>
               {is_referral && data.is_referral_unilevel != 0 && <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Referral level <span className="required">*</span></label>
                       <input type="number" name="phoneNumber" className="form-control" value={data.referral_level} onChange={(e) => setData({ ...data, 'referral_level': e.target.value })} required />
                   </div>
               </div>}
               {/* <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Non Working Percentage <span className="required">*</span></label>
                       <input type="number" name="non_working_percentage" className="form-control" value={data.non_working_percentage} onChange={(e) => setData({ ...data, 'non_working_percentage': e.target.value })} required />
                   </div>
               </div>
               <div className="col-lg-6 mb-2">
                   <div className="form-group mb-3">
                       <label className="text-label">Total Income Percentage <span className="required">*</span></label>
                       <input type="number" name="total_income_percentage" className="form-control" value={data.total_income_percentage} onChange={(e) => setData({ ...data, 'total_income_percentage': e.target.value })} required />
                   </div>
               </div> */}
               <div className="col-lg-12">
                   <div className="form-group mb-3">
                       <label className="text-label">Status <span className="required">*</span></label>
                       <div className="form-group mb-3">
                           <select className="form-control" value={data.status} onChange={(e) => setData({ ...data, 'status': e.target.value })}>
                               <option value={''}>Select Status</option>
                               <option value={1}>Active</option>
                               <option value={0}>Inactive</option>
                           </select>
                       </div>
                   </div>
               </div>
               <div className="col-lg-12 mb-1">
                    <div className="form-group">
                        <div className="col-sm-6" style={{ display: "flex", alignItems: "center", gap: "1rem", flexDirection: "row" }}>
                            <label className="text-label" style={{ margin: "0", fontSize: "1.1rem" }} htmlFor="flexSwitchCheckChecked4">Cancelable :</label>
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked4" checked={data.is_cancellation ? true : false} onChange={(e) => {setData({ ...data, 'is_cancellation': e.target.checked ? 1 : 0 }) }} />
                            </div>
                        </div>
                    </div>
                </div>
               <div className="col-lg-12 mb-3">
                   <div className="form-group mb-3">
                       <label className="text-label">Description</label>
                       <input type="text" name="place" className="form-control" value={data.description} onChange={(e) => setData({ ...data, 'description': e.target.value })} required />
                   </div>
               </div>
               <div className="col-lg-12 mb-3">
                   <div className="form-group mb-3">
                       <label className="text-label">Non Working Percentage</label>
                       <input type="text" name="non_working_percentage" className="form-control" value={data.non_working_percentage} onChange={(e) => setData({ ...data, 'non_working_percentage': e.target.value })} required />
                   </div>
               </div>
           </div>
           <div className="text-end mb-3">
               <button className="btn btn-primary sw-btn-next" onClick={sendData}>
                   Create Package
                   {loader && <div className="spinner-border" style={{ width: '1rem', height: '1rem', marginLeft: '10px' }} role="status">
                       <span className="visually-hidden">Loading...</span>
                   </div>}
               </button>
           </div>
       </form>
                </div>
                
            </div>
       
        </Frame>
    )
}
