import {  Route, Routes,Navigate } from 'react-router-dom';
//import "./other/bootstrap-select/dist/css/bootstrap-select.min.css";
//import "./other/swiper/css/swiper-bundle.min.css";
// import "./css/style.css";
import Login from './pages/Login';
import VerifyEmail from './pages/VerifyEmail';
import Register from './pages/Register';
// import Error404 from './jsx/pages/Error404';
import PageRoute from './routes/route';
import ProtectedRoute from './Middleware/auth';
import PageNotFound from './pages/PageNotFound';
import { ToastContainer } from "react-toastify";
import VerifyOtp from './pages/VerifyOtp';

function App() {
  return (
    <>
      <ToastContainer/>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />}/>
        <Route path='/login' element={<Login />}/>
        {/* <Route path='/register' element={<Register/>}/> */}
        <Route path='/verifyEmail' element={<VerifyEmail/>}/>
        <Route path='/verifyOtp' element={<VerifyOtp/>}/>
        <Route path="/admin/*" element={<ProtectedRoute Children={PageRoute}/>}/>
        <Route path="/*" element={<PageNotFound/>}/>
      </Routes>
    </>
  );
}
export default App; 

