import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { sendOtp, verifyOtp } from '../requests/request';
import { useNavigate } from 'react-router-dom';
import { notifyError, notifySuccess } from '../parts/Toast';

export default function VerifyOtp() {
    const navigate = useNavigate();
    const [loader,setLoader] = useState(false);
    const [data,setData] = useState({'username':'','otp':''});
    const [otp1,setOtp1] = useState();
    const [otp2,setOtp2] = useState();
    const [otp3,setOtp3] = useState();
    const [otp4,setOtp4] = useState();
    useState(()=>{
        (
            async ()=>{
                let data = {"username":sessionStorage.getItem('username')}
                let result = await sendOtp(data);
                if(result.message && result.status){
                    notifySuccess(result.message);
                }
                else{
                    notifyError(result.message);
                }
            }
        )()
    },[]);
    async function verifyOTP(){
        setLoader(true);
        let temp = {...data};
        temp.otp = `${otp1}${otp2}${otp3}${otp4}`;
        temp.username = sessionStorage.getItem('username');
        let result = await verifyOtp(temp);
        if(result.status===true && result.token){
            window.history.replaceState(null, null, "/");
            sessionStorage.setItem('status',result.status);
            sessionStorage.setItem('token',result.token);
            notifySuccess(result.message||result.msg);
            navigate('/admin/',{ replace: true });
        }
        if(result.message && result.status){
            notifySuccess(result.message);
        }
        if(result.message && result.status===false){
            notifyError(result.message);
        }
        if(result.errors && !result.status){
            let err = Object.values(result.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        setLoader(false);
    }
    async function resendOtp(){
        let data = {"username":sessionStorage.getItem('username')}
        let result = await sendOtp(data);
        if(result.message && result.status){
            notifySuccess(result.message);
        }
        else{
            notifyError(result.message);
        }
    }
    function otpChange(idx,d){
        if(!isNaN(+d)){
            if(idx===1){
                setOtp1(d);
                document.getElementById("inpOtp2").focus();
            }
            if(idx===2){
                setOtp2(d);
                document.getElementById("inpOtp3").focus();
            }
            if(idx===3){
                setOtp3(d);
                document.getElementById("inpOtp4").focus();
            }
            if(idx===4){
                setOtp4(d);
            }
        }
        else{
            if(idx===1){
                setOtp1("");
            }
            if(idx===2){
                setOtp2("");
            }
            if(idx===3){
                setOtp3("");
            }
            if(idx===4){
                setOtp4("");
            }
        }
    }
    return (
        <>
        <div className="d-flex flex-column justify-content-center align-items-center verify-container">
            <h2>Verify for login</h2>
            <div className="verify-card py-5 px-3">
                <h5 className="m-0">Verification For Login</h5>
                <span className="verify-mobile-text">Enter the code we just send on your email</span>
                <div className="d-flex flex-row mt-5 d-flex justify-content-center">
                    <input type="text" id='inpOtp1' value={otp1} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(1,e.target.value)}} className="verify-form-control" autoFocus/>
                    <input type="text" id='inpOtp2' value={otp2} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(2,e.target.value)}} className="verify-form-control"/>
                    <input type="text" id='inpOtp3' value={otp3} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(3,e.target.value)}} className="verify-form-control"/>
                    <input type="text" id='inpOtp4' value={otp4} inputMode="numeric" maxLength={1} onChange={(e)=>{otpChange(4,e.target.value)}} className="verify-form-control"/>
                </div>
                <div className="text-center mt-5">
                    <span className="d-block verify-mobile-text">Don't receive the code?</span>
                    <span onClick={resendOtp} className="font-weight-bold text-danger verify-cursor">Resend</span>
                </div>
                <div className='d-flex justify-content-center mt-2'>
                    <Button variant="primary d-flex justify-content-center gap-2" onClick={verifyOTP}>
                        Submit
                        {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                    </Button>
                </div>
            </div>
        </div>
        </>
    )
}
