import React, { useState } from 'react';
import { postForm } from '../requests/request';
import { notifyError, notifySuccess } from '../parts/Toast';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
    const [password,setPassword] = useState({'currentpassword':'','newpassword':'','confirmpassword':''});
    const [loader,setLoader] = useState(false);
    const navigate = useNavigate();
    async function changePassword(){
        setLoader(true);
        let temp = password;
        delete temp.confirmpassword;
        let result = await postForm('change-password',temp,sessionStorage.getItem('token'))
        if(result.errors){
            let err = Object.values(result.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        if(result.message && result.msg && result.status){
            notifySuccess(result.message||result.msg);
            setPassword({'currentpassword':'','newpassword':'','confirmpassword':''});
            sessionStorage.clear();
            window.history.replaceState(null, null, "/");
            navigate('/',{ replace: true });
        }
        if(result.message && result.msg && result.status===false){
            notifyError(result.message||result.msg);
        }
        setLoader(false);
    }
    return (
        <div className="clearfix">
            <div className="card card-bx profile-card author-profile m-b30">
                <div className="card-header">
                    <h6 className="card-title">Change Password</h6>
                </div>
                <div className="card-body" style={{display:"flex",flexDirection:"column",gap:"1rem",textAlign:"left",margin:"1.5rem"}}>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label">Current Password</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" placeholder="Enter Current password" value={password.currentpassword} onChange={(e)=>setPassword({...password,'currentpassword':e.target.value})}/>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label">New Password</label>
                        <div className="col-sm-10">
                            <input type="password" className="form-control" placeholder="Enter new password" value={password.newpassword} onChange={(e)=>setPassword({...password,'newpassword':e.target.value})}/>
                        </div>
                    </div>
                    <div className="mb-3 row">
                        <label className="col-sm-2 col-form-label">Confirm Password</label>
                        <div className="col-sm-10">
                            <input type="text" className="form-control" placeholder="Enter confirm password" value={password.confirmpassword} onChange={(e)=>setPassword({...password,'confirmpassword':e.target.value})}/>
                            {password.newpassword!==password.confirmpassword&&<div className="text-danger fs-12">Password not matching with new password</div>}
                        </div>
                    </div>
                    <div className="d-flex">
                        <button className="btn btn-primary d-flex gap-2 align-items-center" onClick={changePassword}>UPDATE PASSWORD
                        {loader&&<div className="spinner-border" style={{width:'1rem',height:'1rem'}} role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
