import React, { useState, useEffect, useContext } from 'react';
import { getReq, postForm, postReq } from '../../requests/request';
import { IMAGES } from '../../constant/theme';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Col, Tab, Nav, Card } from "react-bootstrap";
import CheckFormError from '../../parts/FormError';
import Frame from "../../components/frame";
function EditUserProfile() {
    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();
    const [user_username, setUser_username] = useState();
    const [user, setUser] = useState('');
    const [countryCode, setCountryCode] = useState();
    const [twofa, setTwofa] = useState({ 'code': '' });
    const [twofaError, setTwofaError] = useState({});
    const [tempPhoto, setTempPhoto] = useState();
    const [photo, setPhoto] = useState();
    const [profile, setProfile] = useState();
    const [profileError, setProfileError] = useState({});
    const [authData, setAuthData] = useState({ 'type': '' });
    const [verify, setVerify] = useState({ "mail": "", "withdrawal": "" });
    const [password, setPassword] = useState({ 'password': '', 'password_confirmation': '' });
    const [passwordError, setPasswordError] = useState({});
    const [sPassword, setSpassword] = useState({ 'spassword': '', 'spassword_confirmation': '' });
    const [sPasswordError, setSPasswordError] = useState({});
    const { setCurrentSidebarClick, setCollapseId } = useContext(SidebarClick);
    const [spinner, setSpinner] = useState(false);
    const [refrashPage, setRefrashPage] = useState(false);
    const [status_2fa, setStatus_2fa] = useState();
    const [profileLoader, setProfileLoader] = useState(false);
    const [passwordLoader, setPasswordLoader] = useState(false);
    const [varifyLoader, setVarifyLoader] = useState(false);
    const [faImage, setfaImage] = useState();
    const [twofaLoader, setTwofaLoader] = useState(false);
    const [authLoader, setAuthLoader] = useState(false);
    const [activeTab, setActiveTab] = useState("Profile");
    const [authMore, setAuthMore] = useState({});
    const [authType, setAuthType] = useState('otp');
    const [credit_percentage, setCreditPercentage] = useState('');
    const [creditLoader, setCreditLoader] = useState(false);
    useEffect(() => {
        setCurrentSidebarClick('/admin/userList');
        setRefrashPage(false);
        setCollapseId(6);
        (async () => {
            try {
                let r = await getReq('country', sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setCountryCode(r.data);
                }
            }
            catch (err) {
                if (err.response.status && err.response.status === 401 || err.response.status === 404) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                notifyError("Request failed please login again");
            }
        })()
    }, [refrashPage])
    useEffect(() => {
        setCurrentSidebarClick('/admin/userList');
        setRefrashPage(false);
        setCollapseId(6);
        (async () => {
            try {
                let r = await getReq(`profile/verification?id=${params.get('username')}`, sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    let temp = r.data[0];
                    delete temp.username;
                    delete temp.id;
                    delete temp.user_id;
                    delete temp.roi;
                    delete temp.epin;
                    delete temp.transfer;
                    delete temp.created_at;
                    delete temp.updated_at;
                    setVerify(r.data[0]);
                }
            }
            catch (err) {
                //notifyError("Request failed please login again");
            }
        })()
    }, [refrashPage])
    useEffect(() => {
        setCurrentSidebarClick('/admin/userList');
        setRefrashPage(false);
        setCollapseId(6);
        (async () => {
            try {
                setSpinner(true);
                let r = await getReq(`user-list?id=${params.get('username')}`, sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    setUser(r.data.data[0]);
                    setStatus_2fa(r.data.data[0].status_2fa);
                    let temp = r.data.data[0];
                    setUser_username(temp.username);
                    setPhoto(temp.avatar);
                    setfaImage(temp.image_2fa);
                    delete temp.id;
                    // delete temp.username;
                    delete temp.email_verified_at;
                    delete temp.otp;
                    delete temp.otp_creation_time;
                    delete temp.kyc_updated_at;
                    delete temp.kyc_approved_by;
                    delete temp.image_2fa;
                    delete temp.status;
                    delete temp.last_login_at;
                    delete temp.phone_verified_at;
                    delete temp.secret_key_2fa;
                    delete temp.created_at;
                    delete temp.updated_at;
                    delete temp.status_2fa;
                    delete temp.avatar;
                    delete temp.package;
                    delete temp.paid;
                    delete temp.sponsor;
                    delete temp.status_kyc;
                    setProfile(temp);
                    // get percentage
                    if(temp?.username){
                        let percentage = await getReq('credit-percentage/'+temp?.username, sessionStorage.getItem('token')); 
                        console.log('percentage',percentage)
                        if (percentage.status === "success" || percentage.status === true) {
                            setCreditPercentage(percentage?.data);
                        }
                    }
                    setSpinner(false);
                }
                if (r.errors) {
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                    //err.map((data)=>notifyError(data[0]));
                    setSpinner(false);
                }
                setSpinner(false);
            }
            catch (err) {
                setSpinner(false);
                //notifyError("Request failed please login again");
            }
        })()
    }, [refrashPage]);
    useEffect(() => {
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/userList');
        setAuthType(sessionStorage.getItem('auth_type'));
        (async () => {
            try {
                let r = await getReq(`profile/${params.get('usernameparams')}/get-authentication`, sessionStorage.getItem('token'))
                if (r.status === "success" || r.status === true) {
                    //setData(r.data)
                    setAuthData({ 'type': r.data });
                    setAuthMore(r.more_details);
                }
                if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    //notifyError('Authentication Failed. Please Login Again....');
                }
                if (r.errors) {
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch (err) {
                if (err.response.status && err.response.status === 401 || err.response.status === 404) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                }
                notifyError("Request failed login again");
            }
        })()
    }, [refrashPage])
    async function updateProfile(e) {
        e.preventDefault();
        let temp = { ...profile }
        delete temp.address_line2;
        delete temp.tax_id;
        delete temp.landmark;
        let { errList, countError } = CheckFormError({ ...temp });
        setProfileError(errList);
        if (countError <= 0) {
            setProfileLoader(true);
            let result = await postForm(`profile/${user_username}/change-personal-information`, profile, sessionStorage.getItem('token'))
            if (result.errors) {
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0])
            }
            if (result.message || result.msg && result.status) {
                notifySuccess(result.message || result.msg);
                setRefrashPage(true);
            }
        }
        setProfileLoader(false);
    }
    async function ed2fa(e) {
        e.preventDefault();
        let temp2fa = status_2fa;
        let { errList, countError } = CheckFormError({ ...twofa });
        setTwofaError(errList);
        if (countError <= 0) {
            setTwofaLoader(true);
            let result = await postForm(`profile/${user_username}/change-2fa`, twofa, sessionStorage.getItem('token'))
            if (result.errors) {
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
                setStatus_2fa(temp2fa);
            }
            if (result.message || result.msg && result.status) {
                notifySuccess(result.message || result.msg);
                setStatus_2fa(!temp2fa);
                setTwofa({ 'code': '' });
                setRefrashPage(true);
            }
            if (result.message || result.msg && result.status === false) {
                notifyError(result.message || result.msg);
                setStatus_2fa(temp2fa);
            }
            setTwofaLoader(false);
        }
    }
    async function updateVerify() {
        setVarifyLoader(true);
        let temp = verify;
        let result = await postForm(`profile/${user_username}/verification`, temp, sessionStorage.getItem('token'))
        if (result.errors) {
            let err = Object.values(result.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        if (result.message || result.msg && result.status) {
            notifySuccess(result.message || result.msg);
            setRefrashPage(true);
            setPassword({ "mail": "", "withdrawal": "" });
        }
        if (result.message && result.status === false) {
            notifyError(result.message);
        }
        setVarifyLoader(false);
    }
    async function changePassword(e) {
        e.preventDefault();
        let { errList, countError } = CheckFormError({ ...password });
        setPasswordError(errList);
        if (countError <= 0) {
            setPasswordLoader(true);
            let result = await postForm(`profile/${user_username}/change-password`, password, sessionStorage.getItem('token'))
            if (result.errors) {
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            if (result.message || result.msg && result.status) {
                notifySuccess(result.message || result.msg);
                setRefrashPage(true);
                setPassword({ 'password': '', 'password_confirmation': '' });
            }
            if (result.message && result.status === false) {
                notifyError(result.message);
            }
        }
        setPasswordLoader(false);
    }
    async function changeSPassword(e) {
        e.preventDefault();
        let { errList, countError } = CheckFormError({ ...sPassword });
        setSPasswordError(errList);
        if (countError <= 0) {
            setPasswordLoader(true);
            let result = await postForm(`profile/${user_username}/change-secondary-password`, sPassword, sessionStorage.getItem('token'))
            if (result.errors) {
                let err = Object.values(result.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
            if (result.message || result.msg && result.status) {
                notifySuccess(result.message || result.msg);
                setRefrashPage(true);
                setPassword({ 'spassword': '', 'spassword_confirmation': '' });
            }
            if (result.message && result.status === false) {
                notifyError(result.message);
            }
        }
        setPasswordLoader(false);
    }
    async function updateImage(img) {
        setPhoto(img)
        let temp = { 'profile_pic': img }
        let result = await postForm(`profile/${user_username}/change-picture`, temp, sessionStorage.getItem('token'));
        if (result.errors) {
            let err = Object.values(result.errors);
            //err.map((data)=>notifyError(data[0]));
            notifyError(err[0][0]);
        }
        if (result.message || result.msg && result.status) {
            notifySuccess(result.message || result.msg);
            setRefrashPage(true);
        }
        if (result.message || result.msg && result.status === false) {
            notifyError(result.message || result.msg);
        }
    }
    async function updateAuth() {
        if (authData.type != "") {
            setAuthLoader(true);
            if (authData.type == 'authenticator' && authMore.authenticator) {
                let r = await postReq(`profile/${user_username}/change-authentication`, authData, sessionStorage.getItem('token'))
                if (r.message || r.msg && r.status === true) {
                    notifySuccess(r.message || r.msg);
                    sessionStorage.setItem('auth_type', authData.type);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                else if (r.msg || r.message && r.status === false) {
                    notifyError(r.msg || r.message);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if (r.errors) {
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                    setAuthLoader(false);
                }
            }
            else if (authData.type == 'mpin' && authMore.mpin) {
                let r = await postReq(`profile/${user_username}/change-authentication`, authData, sessionStorage.getItem('token'))
                if (r.message || r.msg && r.status === true) {
                    notifySuccess(r.message || r.msg);
                    sessionStorage.setItem('auth_type', authData.type);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                else if (r.msg || r.message && r.status === false) {
                    notifyError(r.msg || r.message);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if (r.errors) {
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                    setAuthLoader(false);
                }
            }
            else if (authData.type == 'otp') {
                let r = await postReq(`profile/${user_username}/change-authentication`, authData, sessionStorage.getItem('token'))
                if (r.message || r.msg && r.status === true) {
                    notifySuccess(r.message || r.msg);
                    sessionStorage.setItem('auth_type', authData.type);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if (r.status == false && r.msg == 401) {
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/', { replace: true });
                    notifyError('Authentication Failed. Please Login Again....');
                }
                else if (r.msg || r.message && r.status === false) {
                    notifyError(r.msg || r.message);
                    setRefrashPage(true);
                    setAuthLoader(false);
                }
                else if (r.errors) {
                    let err = Object.values(r.errors);
                    notifyError(err[0][0]);
                    setAuthLoader(false);
                }
            }
            else {
                notifyError(`Please Setup Your ${authData.type == 'mpin' ? 'MPIN' : '2FA Authenticator'}`);
                setAuthLoader(false);
                //setRefrashPage(true);
                if (authData.type == 'mpin') {
                    //setDefaultPage('Sec Change Password');
                    setActiveTab('Sec Change Password'.toLowerCase());
                }
                if (authData.type == 'authenticator') {
                    //setDefaultPage('2fa');
                    setActiveTab('2fa'.toLowerCase());
                }
                //setRefrashPage(true);
            }
        }
        else {
            notifyError(`Please Select Correct Option.`);
            setAuthLoader(false);
        }
    }
    async function changeCreditSettings(e) {
        e.preventDefault();
        if(!credit_percentage){
            notifyError('Please enter percentage value');
            return;
        }
        setCreditLoader(true);
        let data = {
            'credit_wallet_percentage_from_income':credit_percentage
        }
        let result = await postReq('credit-percentage-set/'+profile?.username, data, sessionStorage.getItem('token'));
        if (result.errors) {
            let err = Object.values(result.errors);
            err.map((data) => notifyError(data[0]));
        }
        if (result.msg || result.message && result.status) {
            notifySuccess(result.message || result.msg);
            setRefrashPage(true);            
        }
        if (result.message || result.msg && result.status === false) {
            notifyError(result.message);
        }
        setCreditLoader(false);
    }
    function handleProfileString(e, key) {
        let value = e.target.value
        value = value.replace(/[^A-Za-z\s]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfileStrNum(e, key) {
        let value = e.target.value
        value = value.replace(/[^A-Za-z0-9]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfileEmail(e, key) {
        let value = e.target.value
        value = value.replace(/[^A-Za-z0-9@.]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfileNum(e, key) {
        let value = e.target.value
        value = value.replace(/[^0-9]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfilePhone(e, key) {
        let value = e.target.value
        value = value.replace(/[^0-9+]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    };
    function handleProfileAddress(e, key) {
        let value = e.target.value
        value = value.replace(/[^A-Za-z0-9/\-,]/ig, '');
        let temp = { ...profile }
        temp[key] = value
        setProfile(temp);
    }
    function handleSpassNum(e, key) {
        let value = e.target.value
        value = value.replace(/[^0-9]/ig, '');
        let temp = { ...sPassword }
        temp[key] = value
        setSpassword(temp);
    };
    function handleProfilePan(e, key) {
        let value = e.target.value
        value = value.replace(/[^A-Za-z0-9]/ig, '');
        let temp = { ...profile }
        temp[key] = value.toUpperCase();
        setProfile(temp);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                        Update User Info
                    </h1>
                </div>
            </div>

            {!user && <div class="spinner-grow text-primary" role="status" style={{ display: 'block', position: 'fixed', zIndex: 1030, top: '50%', left: '50%' }}>
                <span class="sr-only"></span>
            </div>}

            {user && <div className="card card-body mb-4">

                <div className="d-flex align-items-center flex-row flex-wrap">

                    <img className="img-5x rounded-circle" src={tempPhoto || (photo && `${process.env.REACT_APP_IMG_URL}/${photo}`) || IMAGES.Avatar} alt="" />

                    <div className="ms-3 text-white">
                        <h5 className="mb-1">{user.name}</h5>
                        <h6 className="m-0 fw-light">{user.email}</h6>
                    </div>

                </div>

            </div>}

            {profile && <div className="card">
                <div className="card-body">
                    <div className="profile">
                        <div className="profile-container">

                            <div className="profile-content">
                                <Tab.Container defaultActiveKey={"Profile".toLowerCase()}>
                                    <Nav as="ul" className="nav nav-pills mb-3">
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey={"Profile".toLowerCase()}>
                                                {"Profile"}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey={"2fa".toLowerCase()}>
                                                {"2fa"}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey={"Change Password".toLowerCase()}>
                                                {"Password"}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey={"Sec Change Password".toLowerCase()}>
                                                {"MPIN"}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey={"Verification".toLowerCase()}>
                                                {"Verification"}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <Nav.Link eventKey={"Authentication".toLowerCase()}>
                                                {"Authentication"}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item as="li" onClick={()=>setActiveTab('Credit Settings'.toLowerCase())}>
                                            <Nav.Link eventKey={"Credit Settings".toLowerCase()}>
                                                {"Credit Settings"}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className="">
                                        <Tab.Pane eventKey={"Profile".toLowerCase()}>
                                            {profile && <form>

                                                <div className="profile-content-container">
                                                    <div className="card profile-card card-bx m-b30">
                                                        <div className="card-header">
                                                            <h6 className="card-title">User Profile Setup</h6>
                                                        </div>
                                                        <form className="profile-form">
                                                            <div className="card-body">
                                                                <div className="form-group mb-3">
                                                                    <input className="update-flie form-control" type="file" accept="image/png,image/jpeg" onChange={(e) => { updateImage(e.target.files[0]); setTempPhoto(URL.createObjectURL(e.target.files[0])) }} />
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Username <span className="required">*</span></label>
                                                                            <input type="text" name="address_line1" className="form-control" value={profile.username} onChange={(e) => handleProfileStrNum(e, 'username')} required={true} />
                                                                        </div>
                                                                        {((profile.username == "" || profile.username == null) && profileError.username) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Name <span className="required">*</span></label>
                                                                            <input type="text" name="address_line1" className="form-control" value={profile.name} onChange={(e) => handleProfileString(e, 'name')} required={true} />
                                                                        </div>
                                                                        {((profile.name == '' || profile.name == null) && profileError.name) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Email <span className="required">*</span></label>
                                                                            <input type="email" name="address_line1" className="form-control" value={profile.email} onChange={(e) => handleProfileEmail(e, 'email')} required={true} />
                                                                        </div>
                                                                        {((profile.email == '' || profile.email == null) && profileError.email) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <label className="mb-1">Gender <span className="required">*</span></label>
                                                                        <select className="form-control" name='gender' value={profile.gender} onChange={(e) => setProfile({ ...profile, 'gender': e.target.value })} required={true}>
                                                                            <option value={''}>Select Gender</option>
                                                                            <option value={'female'}>Female</option>
                                                                            <option value={'male'}>Male</option>
                                                                        </select>
                                                                        {((profile.gender == '' || profile.gender == null) && profileError.gender) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <label className="mb-1">Date of birth <span className="required">*</span></label>
                                                                        <input type="date" name="dob" className="form-control" value={profile.dob !== null && profile.dob?.split(' ')[0] || ""} onChange={(e) => setProfile({ ...profile, 'dob': e.target.value })} placeholder="dob" required={true} />
                                                                        {((profile.dob == "" || profile.dob == null) && profileError.dob) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Address Line 1 <span className="required">*</span></label>
                                                                            <input type="text" name="address_line1" className="form-control" value={profile.address_line1} onChange={(e) => handleProfileAddress(e, 'address_line1')} required={true} />
                                                                        </div>
                                                                        {((profile.address_line1 == "" || profile.address_line1 == null) && profileError.address_line1) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Address Line 2</label>
                                                                            <input type="text" name="address_line2" className="form-control" value={profile.address_line2} onChange={(e) => handleProfileAddress(e, 'address_line2')} required={true} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Landmark </label>
                                                                            <input type="text" name="landmark" className="form-control" value={profile.landmark} onChange={(e) => handleProfileString(e, 'landmark')} required={true} />
                                                                        </div>
                                                                        {/* {((profile.landmark == '' || profile.landmark == null) && profileError.landmark) && <span className="required">This Field Is Required.</span>} */}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">City <span className="required">*</span></label>
                                                                            <input type="text" name="city" className="form-control" value={profile.city} onChange={(e) => handleProfileString(e, 'city')} required={true} />
                                                                        </div>
                                                                        {((profile.city == '' || profile.city == null) && profileError.city) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">State <span className="required">*</span></label>
                                                                            <input type="text" name="state" className="form-control" value={profile.state} onChange={(e) => handleProfileString(e, 'state')} required={true} />
                                                                        </div>
                                                                        {((profile.state == '' || profile.state == null) && profileError.state) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Country <span className="required">*</span></label>
                                                                            <div className="form-group">
                                                                                <select name="select_box" className="form-select form-control" value={countryCode && profile.country && countryCode[parseInt(profile.country) - 1].id} onChange={(e) => setProfile({ ...profile, 'country': e.target.value, 'phone': e.target[e.target.selectedIndex].getAttribute('data-code') })} required={true}>
                                                                                    <option value="">Select Country</option>
                                                                                    {
                                                                                        countryCode && countryCode.map((data, idx) =>
                                                                                            <option value={data.id} data-code={data.calling_code} key={idx}>{data.name}</option>
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        {((profile.country == '' || profile.country == null) && profileError.country) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Phone <span className="required">*</span></label>
                                                                            <input type="phone" name="phone" className="form-control" value={profile.phone} onChange={(e) => { handleProfilePhone(e, 'phone') }} required={true} />
                                                                        </div>
                                                                        {((profile.phone == '' || profile.phone == null) && profileError.phone) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    {/* <div className="col-sm-6 mb-3">
                                                                    <div className="form-group"><label className="mb-1">PAN Number <span className="required">*</span></label>
                                                                        <input type="text" name="pan_no" className="form-control" value={profile.pan_no} onChange={(e) => { handleProfilePan(e, 'pan_no') }} required={true} />
                                                                    </div>
                                                                    {((profile.pan_no == '' || profile.pan_no == null) && profileError.pan_no) && <span className="required">This Field Is Required.</span>}
                                                                </div> */}
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Pincode <span className="required">*</span></label>
                                                                            <input type="text" name="pincode" className="form-control" value={profile.pincode} onChange={(e) => handleProfileNum(e, 'pincode')} required={true} />
                                                                        </div>
                                                                        {((profile.pincode == '' || profile.pincode == null) && profileError.pincode) && <span className="required">This Field Is Required.</span>}
                                                                    </div>
                                                                    <div className="col-sm-6 mb-3">
                                                                        <div className="form-group"><label className="mb-1">Tax Id</label>
                                                                            <input type="text" name="tax_id" className="form-control" value={profile.tax_id} onChange={(e) => handleProfileStrNum(e, 'tax_id')} required={true} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button className="btn btn-primary d-flex gap-2 align-items-center" style={{ marginLeft: '20px', marginBottom: '10px' }} onClick={updateProfile} disabled={profileLoader}>
                                                                UPDATE
                                                                {profileLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>}
                                                            </button>
                                                        </form>
                                                        <div className="card-arrow">
                                                            <div className="card-arrow-top-left"></div>
                                                            <div className="card-arrow-top-right"></div>
                                                            <div className="card-arrow-bottom-left"></div>
                                                            <div className="card-arrow-bottom-right"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>}
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <Tab.Content className="">
                                        <Tab.Pane eventKey={"2fa".toLowerCase()}>
                                            {profile &&
                                                <div className="profile-content-container">
                                                    <div className="card card-bx profile-card author-profile m-b30">
                                                        <div className="card-header">
                                                            <h6 className="card-title">Enable/Disable 2fa</h6>
                                                        </div>
                                                        <div className='d-flex justify-content-around twofa'>
                                                            <div className="card-body">
                                                                <div className="mb-4">
                                                                    <label className="mb-1">Code <span className="required">*</span></label>
                                                                    <input type="text" name="code" className="form-control" value={twofa.code} onChange={(e) => setTwofa({ ...twofa, 'code': e.target.value })} placeholder="First Enter Your Code" required />
                                                                    {(twofa.code == '' && twofaError.code) && <span className="required">This Field Is Required.</span>}
                                                                </div>
                                                                <div className="mt-4">
                                                                    <button className="btn btn-primary d-flex gap-2 align-items-center" onClick={ed2fa} disabled={twofaLoader}>
                                                                        {status_2fa ? "Disable" : "Enable"}
                                                                        {twofaLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div>}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            {/* <img style={{ marginRight: '3rem', marginTop: '1rem', marginBottom: '1rem' }} src={process.env.REACT_APP_2FA + faImage} alt='' /> */}
                                                            <img src={process.env.REACT_APP_2FA + user.image_2fa} alt='' />
                                                        </div>
                                                        <div className="card-arrow">
                                                            <div className="card-arrow-top-left"></div>
                                                            <div className="card-arrow-top-right"></div>
                                                            <div className="card-arrow-bottom-left"></div>
                                                            <div className="card-arrow-bottom-right"></div>
                                                        </div>
                                                    </div>
                                                </div>}
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <Tab.Content className="">
                                        <Tab.Pane eventKey={"Change Password".toLowerCase()}>
                                            {profile && <div className="profile-content-container">
                                                <div className="card card-bx profile-card author-profile m-b30">
                                                    <div className="card-header">
                                                        <h6 className="card-title">Change Password</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="mb-3 row">
                                                            <label className="col-lg-12 mb-1">New Password</label>
                                                            <div className="col-lg-12">
                                                                <input type="password" className="form-control" placeholder="Enter new password" value={password.password} onChange={(e) => setPassword({ ...password, 'password': e.target.value })} />
                                                                {(password.password == '' && passwordError.password) && <span className="required">This Field Is Required.</span>}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row">
                                                            <label className="col-lg-12 mb-1">Confirm Password</label>
                                                            <div className="col-lg-12">
                                                                <input type="text" className="form-control" placeholder="Enter confirm password" value={password.password_confirmation} onChange={(e) => setPassword({ ...password, 'password_confirmation': e.target.value })} />
                                                                {(password.password_confirmation == '' && passwordError.password_confirmation) && <span className="required">This Field Is Required.</span>}
                                                                {password.password != password.password_confirmation && <div className="text-danger fs-12">Password not matching with new password</div>}
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <button className="btn btn-primary d-flex gap-2 align-items-center" onClick={changePassword} disabled={passwordLoader}>
                                                                UPDATE PASSWORD
                                                                {passwordLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="card-arrow">
                                                        <div className="card-arrow-top-left"></div>
                                                        <div className="card-arrow-top-right"></div>
                                                        <div className="card-arrow-bottom-left"></div>
                                                        <div className="card-arrow-bottom-right"></div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <Tab.Content className="">
                                        <Tab.Pane eventKey={"Sec Change Password".toLowerCase()}>
                                            {profile && <div className="profile-content-container">
                                                <div className="card card-bx profile-card author-profile m-b30">
                                                    <div className="card-header">
                                                        <h6 className="card-title">Change MPIN</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="mb-3 row">
                                                            <label className="col-lg-12 mb-1">New MPIN</label>
                                                            <div className="col-lg-12">
                                                                <input type="text" className="form-control" maxLength={4} placeholder="Enter New MPIN" value={sPassword.spassword} onChange={(e) => handleSpassNum(e, 'spassword')} />
                                                                {(sPassword.spassword == '' && sPasswordError.spassword) && <span className="required">This Field Is Required.</span>}
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row">
                                                            <label className="col-lg-12 mb-1">Confirm MPIN</label>
                                                            <div className="col-lg-12">
                                                                <input type="text" className="form-control" maxLength={4} placeholder="Enter Confirm MPIN" value={sPassword.spassword_confirmation} onChange={(e) => handleSpassNum(e, 'spassword_confirmation')} />
                                                                {sPassword.spassword != sPassword.spassword_confirmation && <div className="text-danger fs-12">MPIN not matching with new pin</div>}
                                                                {(sPassword.spassword_confirmation == '' && sPasswordError.spassword_confirmation) && <span className="required">This Field Is Required.</span>}
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <button className="btn btn-primary d-flex gap-2 align-items-center" onClick={changeSPassword} disabled={passwordLoader}>
                                                                Update MPIN
                                                                {passwordLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="card-arrow">
                                                        <div className="card-arrow-top-left"></div>
                                                        <div className="card-arrow-top-right"></div>
                                                        <div className="card-arrow-bottom-left"></div>
                                                        <div className="card-arrow-bottom-right"></div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <Tab.Content className="">
                                        <Tab.Pane eventKey={"Verification".toLowerCase()}>
                                            {profile && <div className="profile-content-container">
                                                <div className="card card-bx profile-card author-profile m-b30">
                                                    <div className="card-header">
                                                        <h6 className="card-title">Verification</h6>
                                                    </div>
                                                    <div className="" style={{ display: "flex", flexDirection: "column", gap: "1rem", textAlign: "left", margin: "1.5rem" }}>
                                                        <div className="mb-3 row">
                                                            <label className="col-lg-12 mb-1">Email Activation</label>
                                                            <div className="col-lg-12">
                                                                <select className="form-control" value={verify.mail} onChange={(e) => setVerify({ ...verify, 'mail': e.target.value })}>
                                                                    <option value={1}>Active</option>
                                                                    <option value={0}>Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3 row">
                                                            <label className="col-lg-12 mb-1">Withdrawal Activation</label>
                                                            <div className="col-lg-12">
                                                                <select className="form-control" value={verify.withdrawal} onChange={(e) => setVerify({ ...verify, 'withdrawal': e.target.value })}>
                                                                    <option value={1}>Active</option>
                                                                    <option value={0}>Inactive</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <button className="btn btn-primary d-flex gap-2 align-items-center" onClick={updateVerify} disabled={varifyLoader}>
                                                                UPDATE VERIFICATION
                                                                {varifyLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="card-arrow">
                                                        <div className="card-arrow-top-left"></div>
                                                        <div className="card-arrow-top-right"></div>
                                                        <div className="card-arrow-bottom-left"></div>
                                                        <div className="card-arrow-bottom-right"></div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <Tab.Content className="">
                                        <Tab.Pane eventKey={"Authentication".toLowerCase()}>
                                            {profile && <div className="profile-content-container">
                                                <div className="card card-bx profile-card author-profile m-b30">
                                                    <div className="card-header">
                                                        <h6 className="card-title">Change Authentication</h6>
                                                    </div>
                                                    <div className="card-body">
                                                        <select className="form-select form-select-lg " value={authData.type} aria-label="Default select" onChange={(e) => setAuthData({ type: e.target.value })}>
                                                            <option value="">Select Authentication Type</option>
                                                            <option value="authenticator">Authenticator</option>
                                                            <option value="otp">OTP</option>
                                                            <option value="mpin">MPIN</option>
                                                        </select>
                                                        <div className="mt-4">
                                                            <button className="btn btn-primary" onClick={updateAuth} disabled={authLoader}>
                                                                UPDATE{" "}
                                                                {authLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </div>}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="card-arrow">
                                                        <div className="card-arrow-top-left"></div>
                                                        <div className="card-arrow-top-right"></div>
                                                        <div className="card-arrow-bottom-left"></div>
                                                        <div className="card-arrow-bottom-right"></div>
                                                    </div>
                                                </div>
                                            </div>}
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <Tab.Content>
                                    <Tab.Pane eventKey={"Credit Settings".toLowerCase()}>
                                        {profile && <div className="profile-content-container">
                                            <div className="card card-bx profile-card author-profile m-b30">
                                                <div className="card-header">
                                                    <h6 className="card-title">Credit Settings</h6>
                                                </div>
                                                <div className="card-body">
                                                    <div className="">
                                                        <div className="form-group mb-3">
                                                            <label className="mb-1">Credit Percentage <span className="required">*</span></label>
                                                            <input type="text" name="name" className="form-control" value={credit_percentage} onChange={(e)=>setCreditPercentage(e.target.value)} placeholder="Credit Percentage" required/>                                                            
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <button className="btn btn-primary" onClick={changeCreditSettings} disabled={creditLoader}>
                                                            UPDATE Settings
                                                            {creditLoader && <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-arrow">
                                                <div className="card-arrow-top-left"></div>
                                                <div className="card-arrow-top-right"></div>
                                                <div className="card-arrow-bottom-left"></div>
                                                <div className="card-arrow-bottom-right"></div>
                                            </div>
                                            </div>
                                        </div>}
                                    </Tab.Pane>
                                </Tab.Content>
                                </Tab.Container>
                            </div>
                        </div>
                    </div>
                </div>


            </div>}
        </Frame>
    );
}

export default EditUserProfile;