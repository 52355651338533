import React, { useContext, useEffect, useState } from 'react';
import {Col,Card,Table, Spinner,Dropdown} from "react-bootstrap";
import { getReq,getTable, postReq } from '../../requests/request';
import { notifyError, notifySuccess } from '../../parts/Toast';
import { SidebarClick } from '../../routes/route';
import { useNavigate } from 'react-router-dom';
import { IMAGES, SVGICON } from "../../constant/theme";
import moment from 'moment/moment';
import openImg from '../../parts/ImageNewTab';
import Filter from '../../parts/Filter';
import { motion } from "framer-motion";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Frame from "../../components/frame";
import DownloadExcel from '../../parts/DownloadExcel';

export default function KycStatus() {
    const navigate = useNavigate();
    const {setCurrentSidebarClick,setCollapseId} = useContext(SidebarClick);
    const [spinner,setSpinner] = useState(false);
    const [table,setTable] = useState();
    const [kycData,setKycData] = useState();
    const [refrashPage,setRefrashPage] = useState(false);
    const [searchBy,setSearchBy] = useState('');
    const [searchByText,setSearchByText] = useState('');
    const [filterShow,setFilterShow] = useState(false);
    const [remark,setRemark] = useState("");
    const [showRemark,setShowRemark] = useState(false);
    const [kycRejectId,setKycRejectId] = useState(null);
    const [exportData,setExportData] = useState('');
    const [kycImgId,setKycImgId] = useState(null);
    const list=[
        {'label':'Username','data':'username','type':'text'},
        {'label':'Document Name','data':'document_name','type':'text'},
        {'label':'Document Type','data':'document_type','type':'text'},
        {'label':'Status','data':'status','type':'option','value':[{'key':'Approved','value':'1'},{'key':'Rejected','value':'2'},{'key':'Pending','value':'0'}]}
    ]
    let durationanim = 0.15;
    let count = 0;
    useEffect(()=>{
        setRefrashPage(false);
        setCurrentSidebarClick('/admin/kycStatus');
        setCollapseId(2);
        (async()=>{
            try{
                //setSpinner(true);
                let r = await getReq('kyc-list-all?export=true',sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setKycData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    //setSpinner(false);
                }
                if(r.errors){
                    //setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
            catch(err){
                if(err.message){
                    sessionStorage.clear();
                    window.history.replaceState(null, null, "/");
                    navigate('/',{ replace: true });
                }
                //setSpinner(false);
                notifyError(`Request failed ${err.message}`);
            }
        })()
    },[refrashPage]);
    const svg1 = (
        <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24"></rect>
            <circle fill="#000000" cx="5" cy="12" r="2"></circle>
            <circle fill="#000000" cx="12" cy="12" r="2"></circle>
            <circle fill="#000000" cx="19" cy="12" r="2"></circle>
          </g>
        </svg>
    );
    async function searchTable(e){
        try{
            setSpinner(true);
            let r = await getTable(`${process.env.REACT_APP_BASE_URL}/kyc-list-all?export=true&page=${e.target.value}`,sessionStorage.getItem('token'))
            if(r.status==="success" || r.status===true){
                setKycData(r.data);
                setExportData(r.export_data);
                setTable(r.data.data);
                setSpinner(false);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function nextTable(url){
        try{
            if(url!==null){
                setSpinner(true);
                let r = await getTable(url,sessionStorage.getItem('token'))
                if(r.status==="success" || r.status===true){
                    setKycData(r.data);
                    setExportData(r.export_data);
                    setTable(r.data.data);
                    setSpinner(false);
                }
                if(r.errors){
                    setSpinner(false);
                    let err = Object.values(r.errors);
                    //err.map((data)=>notifyError(data[0]));
                    notifyError(err[0][0]);
                }
            }
        }
        catch(err){
            setSpinner(false);
            notifyError("Request failed please login again");
        }
    }
    async function kycVerify(id){
        try{
            setSpinner(true);
            let r = await postReq('kyc-doc-verify',{"kyc_id" : id},sessionStorage.getItem('token'))
            if(r.status===true){
                notifySuccess(r.message);
                setRefrashPage(true);
            }
            if(r.status===false){
                setSpinner(false);
                notifyError(r.message);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            if(err.response.status===401||err.response.status===404){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
            }
            notifyError("Request failed please login again");
        }
        setSpinner(false);
    }
    async function kycPending(id){
        try{
            setSpinner(true);
            let r = await postReq('kyc-doc-pending',{"kyc_id" : id},sessionStorage.getItem('token'))
            if(r.status===true){
                notifySuccess(r.message);
                setRefrashPage(true);
            }
            if(r.status==false){
                setSpinner(false);
                notifyError(r.message);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            if(err.response.status===401||err.response.status===404){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
            }
            notifyError("Request failed please login again");
        }
        setSpinner(false);
    }
    async function kycReject(){
        try{
            setSpinner(true);
            let r = await postReq('kyc-doc-reject',{"kyc_id" : kycRejectId,"remark": remark},sessionStorage.getItem('token'))
            if(r.status===true){
                notifySuccess(r.message);
                setKycRejectId(null);
                setRemark('');
                setShowRemark(false);
                setRefrashPage(true);
            }
            if(r.status==false){
                setSpinner(false);
                notifyError(r.message);
            }
            if(r.errors){
                setSpinner(false);
                let err = Object.values(r.errors);
                //err.map((data)=>notifyError(data[0]));
                notifyError(err[0][0]);
            }
        }
        catch(err){
            if(err.response.status===401||err.response.status===404){
                sessionStorage.clear();
                window.history.replaceState(null, null, "/");
                navigate('/',{ replace: true });
            }
            notifyError("Request failed please login again");
        }
        setSpinner(false);
    }
    function filterFunData(r){
        setKycData(r.data);
        setExportData(r.export_data);
        setTable(r.data.data);
    }
    return (
        <Frame withHeader={true} withLeftpart={true} withFooter={true}>
            {filterShow && <Filter props={{ setFilterShow, url: `${process.env.REACT_APP_BASE_URL}/kyc-list-all?export=true`, list, filterFunData }} />}
            <div className="d-flex align-items-center mb-4">
                <div className="flex-fill">
                    <h1 className="page-header mb-0">
                        KYC Requests
                    </h1>
                </div>
                {table&&<div className="ms-auto">
                    {table && <DownloadExcel props={{ exportData: exportData, name: 'Kyclist.xlsx' }} />}
                </div>}
            </div>

            {!table&&<div class="spinner-grow text-primary" role="status" style={{display:'block',position:'fixed',zIndex:1030,top:'50%',left:'50%'}}>
                <span class="sr-only"></span>
            </div>}

            {table&&<div className="card">
                <div className="card-header align-items-center fw-bold small d-flex">
                    <span className="flex-grow-1">KYC Requests</span>
                    <span className="btn btn-outline-light btn-sm" onClick={() => setFilterShow(true)}>Filter <i className="bi bi-sliders"></i></span>
                </div>
                <div className="card-body">
                    <div className="mb-4">
                        <input type="number" className="form-control rounded" placeholder="Search Table By No" onChange={searchTable} aria-label="Search" aria-describedby="search-addon" />
                    </div>
                    {spinner&&<div style={{background:'rgba(255,255,255,0.3)',position:'absolute',width:'-webkit-fill-available',height:'-webkit-fill-available'}}>
                        <Spinner animation="grow" style={{color:'var(--primary)',zIndex:'50',position: "absolute",top: "50%",left: "50%"}}/>
                    </div>}
                    <Table responsive style={{textAlign:'center'}}>
                        <thead>
                            <tr>
                                <th>
                                    <strong>Username</strong>
                                </th>
                                <th>
                                    <strong>Document Name</strong>
                                </th>
                                <th>
                                    <strong>Document Number</strong>
                                </th>
                                <th>
                                    <strong>Document Type</strong>
                                </th>
                                <th>
                                    <strong>Submitted</strong>
                                </th>
                                <th>
                                    <strong>Updated At</strong>
                                </th>
                                <th>
                                    <strong>Front Document Path</strong>
                                </th>
                                <th>
                                    <strong>Back Document Path</strong>
                                </th>
                                <th>
                                    <strong>Status</strong>
                                </th>
                                <th>
                                    <strong>Action</strong>
                                </th>
                            </tr>
                        </thead>
                        {table&&<tbody>
                            {
                                table.map((data,idx)=>{
                                count += 1
                                return(<motion.tr initial={{opacity:0,x:-150}} whileInView={{opacity:1,x:0}} transition={{duration:durationanim+=0.06}} key={idx}>
                                    {/* <td>{data.id}</td> */}
                                    <td>{data.username}</td>
                                    <td style={{textTransform: 'capitalize'}}>{data.document_name&&data.document_name.replaceAll('_',' ')||'-'}</td>
                                    <td>{data.document_number||'-'}</td>
                                    <td style={{textTransform: 'capitalize'}}>{data.document_type&&data.document_type.replaceAll('_',' ')||'-'}</td>
                                    <td>{moment(data.created_at).format('DD/MM/YYYY HH:mm')}</td>
                                    <td>{moment(data.kyc_updated_at).format('DD/MM/YYYY HH:mm') || '-'}</td>
                                    <td style={{textAlign: "-webkit-center"}}>
                                        <div style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
                                            {/* <div style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",width:"10rem"}}>
                                                {data.front}
                                            </div> */}
                                            <div className="menu-icon" style={{margin:'2px',cursor:'pointer'}} onClick={()=>{setKycImgId(idx);openImg(`${process.env.REACT_APP_IMG_URL}/${data.front}`)}}>View {SVGICON.newTab}</div>
                                        </div>
                                    </td>
                                    <td style={{textAlign: "-webkit-center"}}>
                                        {data.back?<div style={{display:'flex',alignItems:'center',justifyContent:"center"}}>
                                            {/* <div style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis",width:"10rem"}}>
                                                {data.back}
                                            </div> */}
                                            <div className="menu-icon" style={{margin:'2px',cursor:'pointer'}} onClick={()=>{setKycImgId(idx);openImg(`${process.env.REACT_APP_IMG_URL}/${data.back}`)}}>View {SVGICON.newTab}</div>
                                        </div>:'-'}
                                    </td>
                                    <td>
                                        {data.status===0 && <span className="badge light btn btn-warning">Pending</span>}
                                        {data.status===1 && <span className="badge light btn btn-success">Approved</span>}
                                        {data.status===2 && <span className="badge light btn btn-danger">Rejected</span>}
                                    </td>
                                    <td>
                                        <Dropdown className='custom-dropdown'>
                                            <Dropdown.Toggle variant="warning" className="light sharp i-false light btn-sm">
                                                {svg1}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>kycVerify(data.id)}>Verify</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>kycPending(data.id)}>Pending</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>{setShowRemark(true);setKycRejectId(data.id)}}>Reject</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </motion.tr>)})
                            }
                        </tbody>}
                        {count==0&&<span style={{display:'table-caption',marginTop:'1rem',textAlign:'center'}}>No Data For Action</span>}
                    </Table>
                    {table&&
                    <div className='d-flex justify-content-between align-items-center my-2 mx-2'>
                        <p className="h6">
                            Showing <span className="font-medium">{kycData.from||'-'}</span> to <span className="font-medium">{kycData.to||'-'}</span> of{' '}
                            <span className="font-medium">{kycData.total}</span> results
                        </p>
                        <div className="d-flex">
                            <nav aria-label="Page navigation">
                                <ul className="pagination mb-0">
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(kycData.prev_page_url)}>
                                        <a className="page-link" aria-label="Previous">
                                            <span className="btn-link cursor-pointer">&laquo;</span>
                                            
                                        </a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}}>
                                        <a className="page-link">{kycData.current_page}</a>
                                    </li>
                                    <li className="page-item" style={{cursor:'pointer'}} onClick={()=>nextTable(kycData.next_page_url)}>
                                        <a className="page-link" aria-label="Next">
                                            <span className="btn-link">&raquo;</span>
                                            
                                        </a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>}
                </div>
                
            </div>}
       
        {showRemark&&<div className="modal show" style={{ display: 'block',marginTop:'5rem',background:'rgba(0,0,0,0.5)'}}>
            <Modal.Dialog>
                <Modal.Header closeButton onClick={()=>{setShowRemark(false);setRemark("")}}>
                <Modal.Title>Remark</Modal.Title>
                </Modal.Header>
                    <Modal.Body>
                        <div className="mb-2">
                            <label className="mb-1">Remark</label>
                            <input type="text" className="form-control" value={remark} onChange={(e)=>setRemark(e.target.value)} />
                        </div>
                    </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={()=>{setShowRemark(false);setRemark("");}}>Close</Button>
                <Button variant="primary" onClick={kycReject}>Reject</Button>
                </Modal.Footer>
            </Modal.Dialog>
        </div>}
        </Frame>
    )
}


