import React from 'react'

export default function PageNotFound() {
  return (
    <div class="error-container">

        <div class="container">

        <div class="row justify-content-center">
            <div class="col-sm-5">
            <img src="/assets/img/page-not-found.png" class="img-fluid mb-5" alt="" />
            <h2 class="fw-bold mb-4">
                We're sorry but it looks like the page doesn't exist anymore.
            </h2>
            <a href="/admin/dashboard" class="btn btn-primary rounded-5"><i class="bi bi-arrow-left lh-1 me-2"></i> Back to Home</a>
            </div>
        </div>
        
        </div>
  
  </div>

  )
}
